import get from "lodash/get"
import pure from "recompose/pure"
import React from "react"

const TextUrlField = ({ record = {}, source, displaySource}) => {
  const value = get(record, source, "")
  const name = get(record, displaySource, value)

  return (
    value ? <a href={value} target="_blank" rel="noopener noreferrer">{name}</a> : <div>{name}</div>
  )
}

const PureTextUrlField = pure(TextUrlField)

PureTextUrlField.defaultProps = {
  sortable: false
}

export default PureTextUrlField