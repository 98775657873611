import React from "react"
import pure from "recompose/pure"
import find from "lodash/find"
import get from "lodash/get"
import DateField from "./DateField"

const SOURCES = ["account.lastLogin", "account.createdAt"]

const LastLoginField = pure(({ record, ...props }) => {
  const source = find(SOURCES, (source) => !!get(record, source))
  return <DateField {...props} record={record} source={source} />
})

export default LastLoginField
