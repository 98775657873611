import React, { useState } from "react"
import { useGetManyReference } from "react-admin"
import UserLogins from "../../../components/Logins"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import map from "lodash/map"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core"

const Logins = (props) => {
  const { loading, error, data } = useGetManyReference(
    "users",
    "account",
    props.record.id,
  )
  const [userId, setUserId] = useState(props.record.id)

  if (loading) {
    return (
      <div className={props.classes.loader}>
        <CircularProgress />
      </div>
    )
  }
  if (error) {
    return <Typography color="error">{String(error)}</Typography>
  }

  return (
    <UserLogins
      userId={userId}
      extraFilter={
        <FormControl>
          <InputLabel id="demo-simple-select-label">User</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          >
            {map(data, (user) => (
              <MenuItem value={user.id}>{user.email}</MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  )
}

export default withStyles(() => ({
  loader: {
    display: "flex",
    padding: "50px 0",
    justifyContent: "center",
  },
}))(Logins)

