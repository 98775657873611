import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"
import moment from "moment"

const getOneQuery = gql`
  query Signup($id: Int!) {
    signup(id: $id) {
      id
      disposition
      createdAt
      updatedAt
      email
      displayName
      firstName
      lastName
      company
      country
      region
      locality
      postalCode
      streetAddress
      extendedAddress
      useCaseDescription
      plan {
        id
        slug
        price
        displayName
      }
      customer {
        id
        contract {
          id
          features {
            id
            feature
            unitPrice
            unit
            quantity
          }
        }
      }
      account {
        id
        email
        displayName
      }
      twilio {
        phoneNumber
        verified
        carrierName
        carrierType
        carrierCountryCode
        carrierNetworkCode
      }
      conversion {
        id
        createdAt
        clkid
        source
        keyword
        campaign
      }
      minfraud {
        retry
        request {
          creditCard {
            issuerIDNumber
            last4Digits
          }
        }
        response {
          riskScore
          creditCard {
            type
            brand
            issuer {
              name
            }
            country
            isPrepaid
            isVirtual
            isIssuedInBillingAddressCountry
          }
          ipAddress {
            risk
            traits {
              ipAddress
              autonomousSystemNumber
              autonomousSystemOrganization
              isp
              isAnonymous
              isPublicProxy
              isTorExitNode
              isAnonymousVPN
              isHostingProvider
            }
            location {
              latitude
              longitude
              localTime
              accuracyRadius
            }
            country {
              names {
                en
              }
              isoCode
              confidence
              geonameID
            }
            subdivisions {
              names {
                en
              }
              geonameID
              confidence
            }
            city {
              names {
                en
              }
              confidence
            }
            postal {
              code
              confidence
            }
          }
          email {
            firstSeen
            isHighRisk
            isFree
          }
          billingAddress {
            isPostalInCity
            isInIPCountry
            latitude
            longitude
            distanceToIPLocation
          }
        }
      }
    }
  }
`

const getOne = ({ id }) => ({
  query: getOneQuery,
  variables: { id },
  parseResponse: ({ data: { signup } }) => ({ data: signup }),
})

const getListQuery = gql`
  query SignupSlice(
    $limit: Int!
    $offset: Int!
    $filter: SignupSliceFilter
    $sort: SignupSliceSorter
  ) {
    signupSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      items {
        id
        createdAt
        updatedAt
        email
        disposition
        displayName
        firstName
        lastName
        company
        useCaseDescription
        plan {
          id
          slug
        }
        minfraud {
          retry
          response {
            riskScore
          }
        }
        account {
          id
        }
      }
      totalCount
    }
  }
`

const getList = ({
  pagination: { page, perPage },
  filter: { disposition, q: query, createdAfter, ip },
  sort,
}) => {
  const createdAfterFilter = {
    day: moment()
      .subtract(1, "day")
      .toDate(),
    week: moment()
      .subtract(1, "week")
      .toDate(),
    month: moment()
      .subtract(1, "month")
      .toDate(),
    year: moment()
      .subtract(1, "year")
      .toDate(),
  }

  let filter = { query, createdAfter: createdAfterFilter[createdAfter], ip }
  if (disposition !== "all") {
    filter = { ...filter, disposition }
  }

  let variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter,
  }
  if (!!sort) {
    variables = { ...variables, sort: querySortHelper(sort) }
  }
  return {
    query: getListQuery,
    variables,
    parseResponse: (response) => {
      const {
        data: {
          signupSlice: { items, totalCount },
        },
      } = response
      return {
        data: items,
        total: totalCount,
      }
    },
  }
}

export default ({ queryType, params }) => {
  switch (queryType) {
    case `GET_LIST`:
      return getList({ ...params })
    case `GET_ONE`:
      return getOne({ ...params })
    default:
      throw new Error(`not implemented: ${queryType}`)
  }
}
