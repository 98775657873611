import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import { apolloClientAuth } from "../apolloClient"
import gql from "graphql-tag"

const apollo = apolloClientAuth()

export default ({ match }) => {
  const { params: { token } } = match
  const [done, setDone] = useState(false)

  useEffect(() => {
    if (token && !done) {
      apollo.mutate(
        { mutation: MUTATION, variables: { token } },
      ).then(({ data: { auth: { me, auth, refresh } } }) => {
        localStorage.setItem("me", JSON.stringify(me))
        localStorage.setItem("auth-token", JSON.stringify(auth))
        localStorage.setItem("refresh-token", JSON.stringify(refresh))
      }).finally(() => {
        setDone(true)
      })
    }
  })

  return <>
    {done && <Redirect to="/"/>}
  </>
}

const MUTATION = gql`
  mutation ($token: String!) {
    auth: loginToken(input: {token: $token}) {
      me {
        firstName
        lastName
        email
        role
      }
      auth {
        token
        expiresAt
        notBefore
      }
      refresh {
        token
        expiresAt
        notBefore
      }

    }
  }
`
