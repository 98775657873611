import React from 'react'
import Gravatar from 'react-gravatar'
import pure from 'recompose/pure'
import get from 'lodash/get'

const FullNameField = ({ record = {}, source, size = 25 }) => {
  const email = get(record, source ? source + ".email" : "email")
  const firstName = get(record, source ? source + ".firstName" : "firstName")
  const lastName = get(record, source ? source + ".lastName" : "lastName")
  return (
    record ? <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      <Gravatar email={email} size={size}/>
      &nbsp;{firstName} {lastName}
    </div> : null
  )
}

const PureFullNameField = pure(FullNameField)

PureFullNameField.defaultProps = {
  label: 'Name',
  sortable: false,
}

export default PureFullNameField
