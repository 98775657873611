import red from "@material-ui/core/colors/red"
import get from "lodash/get"
import green from "@material-ui/core/colors/green"
import orange from "@material-ui/core/colors/orange"
import blue from "@material-ui/core/colors/blue"

export const getRowStyle = (record) => {
  if (record.account.isBlocked) {
    return {
      borderLeftColor: red[200],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  } else if (get(record.customerDetails, "subscriptionStatus") === "trial") {
    return {
      borderLeftColor: green[50],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  } else if (get(record.customerDetails, "subscriptionStatus") === "active") {
    return {
      borderLeftColor: green[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  } else if (get(record.customerDetails, "subscriptionStatus") === "suspended") {
    return {
      borderLeftColor: orange[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  } else if (get(record.customerDetails, "subscriptionStatus") === "free") {
    return {
      borderLeftColor: blue[400],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  }

  return {}
}