import React from 'react'
import gql from 'graphql-tag'
import {Table, TableBody, TableHead, TableRow, TableCell, CircularProgress} from "@material-ui/core"
import moment from 'moment'
import { graphql } from "@apollo/client/react/hoc"
import get from "lodash/get"
import { compose } from "recompose"
import { stringify } from "query-string"
import { Link } from "react-admin"

const QUERY = gql`
  query CountMany($day: Time!, $week: Time!, $month: Time!, $year: Time!, $disposition: SignupDisposition) {
      day: signupSlice(limit: 0, filter: { createdAfter: $day, disposition: $disposition }) {
          totalCount
      }
      week: signupSlice(limit: 0, filter: { createdAfter: $week, disposition: $disposition }) {
          totalCount
      }
      month: signupSlice(limit: 0, filter: { createdAfter: $month, disposition: $disposition }) {
          totalCount
      }
      year: signupSlice(limit: 0, filter: { createdAfter: $year, disposition: $disposition }) {
          totalCount
      }
  }
`

const CardSingups = ({approved, rejected, pending, all}) => {
  if ([approved, rejected, pending, all].some(x => x.loading)) {
    return <CircularProgress/>
  }

  const data = {approved, rejected, pending, all}
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <></>
          </TableCell>
          <TableCell align="right">Approved</TableCell>
          <TableCell align="right">Pending</TableCell>
          <TableCell align="right">Rejected</TableCell>
          <TableCell align="right">Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Last 24-hours</TableCell>
          {["approved", "pending", "rejected", "all"].map((name, idx) =>
            <TableCell key={idx} align="right">
              <Link to={{
                pathname: `/signups`,
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({
                    disposition: name,
                    createdAfter: "day"
                  }),
                }),
              }}>{get(data[name], 'day.totalCount')}</Link>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Last 7-days</TableCell>
          {["approved", "pending", "rejected", "all"].map((name, idx) =>
            <TableCell key={idx} align="right">
              <Link to={{
                pathname: `/signups`,
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({
                    disposition: name,
                    createdAfter: "week"
                  }),
                }),
              }}>{get(data[name], 'week.totalCount')}</Link>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Last 30-days</TableCell>
          {["approved", "pending", "rejected", "all"].map((name, idx) =>
            <TableCell key={idx} align="right">
              <Link to={{
                pathname: `/signups`,
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({
                    disposition: name,
                    createdAfter: "month"
                  }),
                }),
              }}>{get(data[name], 'month.totalCount')}</Link>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>Last 12-months</TableCell>
          {["approved", "pending", "rejected", "all"].map((name, idx) =>
            <TableCell key={idx} align="right">
              <Link to={{
                pathname: `/signups`,
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({
                    disposition: name,
                    createdAfter: "year"
                  }),
                }),
              }}>{get(data[name], 'year.totalCount')}</Link>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  )
}

const enhance = compose(
  graphql(QUERY, {
    options: {
      variables: {
        day: moment().subtract(1, 'day').toDate(),
        week: moment().subtract(1, 'week').toDate(),
        month: moment().subtract(1, 'month').toDate(),
        year: moment().subtract(1, 'year').toDate(),
        disposition: "approved",
      },
    },
    name: "approved",
  }),
  graphql(QUERY, {
    options: {
      variables: {
        day: moment().subtract(1, 'day').toDate(),
        week: moment().subtract(1, 'week').toDate(),
        month: moment().subtract(1, 'month').toDate(),
        year: moment().subtract(1, 'year').toDate(),
        disposition: "pending",
      },
    },
    name: "pending",
  }),
  graphql(QUERY, {
    options: {
      variables: {
        day: moment().subtract(1, 'day').toDate(),
        week: moment().subtract(1, 'week').toDate(),
        month: moment().subtract(1, 'month').toDate(),
        year: moment().subtract(1, 'year').toDate(),
        disposition: "rejected",
      },
    },
    name: "rejected",
  }),
  graphql(QUERY, {
    options: {
      variables: {
        day: moment().subtract(1, 'day').toDate(),
        week: moment().subtract(1, 'week').toDate(),
        month: moment().subtract(1, 'month').toDate(),
        year: moment().subtract(1, 'year').toDate(),
      },
    },
    name: "all",
  }),
)

export default enhance(CardSingups)
