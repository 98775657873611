import gql from "graphql-tag"
import { CREATE, UPDATE, DELETE, GET_MANY_REFERENCE } from "ra-core"
import get from "lodash/get";

export default ({ queryType, params }) => {
  switch (queryType) {
    case GET_MANY_REFERENCE:
      return getManyReference(params)
    case CREATE:
      return create(params)
    case UPDATE:
      return update(params)
    case DELETE:
      return remove(params)
    default:
      throw new Error(`not implemented: ${queryType}`)
  }
}

const getManyReference = ({ target, id, pagination: { page, perPage } }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
  }
  if (target === "customer") {
    return getManyReferenceForCustomer({ ...variables })
  } else {
    throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = (variables) => {
  return {
    query: gql`
        query GetCustomerNotes($id: Int!, $limit: Int!, $offset: Int!) {
            customer(id: $id) {
                customerNoteSlice(limit: $limit, offset: $offset) {
                    total: totalCount,
                    data: items {
                        id
                        body
                        externalLink
                        author {
                            id
                            firstName
                            lastName
                        }
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { customer: { customerNoteSlice: { data, total } } } }) => ({
      data,
      total,
    }),
  }
}

const update = ({ data }) => {
  return {
    query: gql`
      mutation UpdateCustomerNote($id: Int!, $input: UpdateCustomerNoteInput!) {
        updateCustomerNote(id: $id, input: $input) {
            id
            body
            externalLink
            createdAt
            updatedAt
        }
      }
    `,
    variables: {
      id: get(data, "id"),
      input: {
        body: get(data, "body"),
        externalLink: get(data, "externalLink"),

      },
    },
    parseResponse: ({ data: { updateCustomerNote: data } }) => ({ data, total: 1 }),
  }
}


const remove = ({ data }) => {
  return {
    query: gql`
      mutation UpdateCustomerNote($id: Int!) {
        deleted: deleteCustomerNote(id: $id)
      }
    `,
    variables: {
      id: get(data, "id"),
    },
    parseResponse: ({ data: { deleted } }) => ({ data: {}, total: deleted ? 1 : 0 }),
  }
}

export const create = ({ data }) => {
  return {
    query: gql`
        mutation CreateCustomerNote($input: CreateCustomerNoteInput!) {
            createCustomerNote(input: $input) {
              id
              body
              externalLink
              createdAt
              updatedAt
            }
        }
    `,
    variables: { input: data },
    parseResponse: (response) => ({
      data: response.data.createCustomerNote,
    }),
  }
}
