import React from "react"
import gql from "graphql-tag"
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core"
import Icon from "@material-ui/icons/Face"
import get from "lodash/get"
import Tooltip from "@material-ui/core/Tooltip"
import { useQuery } from "@apollo/client";

const ACCOUNT = gql`
    query ($id: Int!) {
        object: account(id: $id) {
            impersonateURL
        }
    }
`

const USER = gql`
    query ($id: Int!) {
        object: user(id: $id) {
            impersonateURL
        }
    }
`

const Progress = () => (
  <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" style={{ height: 100 + "vh" }}>
    <Grid item>
      <CircularProgress variant="indeterminate"/>
    </Grid>
    <Grid item>
      <Typography align="center">impersonating...</Typography>
    </Grid>
  </Grid>
)

const Impersonate = ({ query, id }) => {
  const { loading, error, data  } = useQuery(query, { variables: { id } });
  if (!loading && !error && !!data) {
    const { object: { impersonateURL }} = data
    window.location.href = impersonateURL
  }

  return <Progress/>
}

export const ImpersonateAccount = ({ match: { params: { id } } }) => (
  <Impersonate query={ACCOUNT} id={id}/>
)

export const ImpersonateUser = ({ match: { params: { id } } }) => (
  <Impersonate query={USER} id={id}/>
)

export const ImpersonateButton = ({ record, source, type }) => {
  if (!record) {
    return null
  }

  const recordId = get(record, source ? source + ".id" : "id")
  const isBlocked = get(record, source ? source + ".isBlocked" : "isBlocked")
  return (
    <Tooltip title={isBlocked ? "Impersonate disabled due to account being blocked" : "Impersonate"}>
      <span>
        <Button component="a" href={`/impersonate/${type}/${recordId}`} target="_blank" disabled={isBlocked}>
          <Icon color={isBlocked ? "disabled": "primary"}/>
        </Button>
      </span>
    </Tooltip>
  )
}

ImpersonateButton.defaultProps = {
  addLabel: false,
  type: "account",
}
