import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  GET_MANY_REFERENCE,
} from "react-admin"
import gql from "graphql-tag"
import get from "lodash/get"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_LIST:
      return getList({ ...params })
    case GET_ONE:
      return getOne({ ...params })
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    case CREATE:
      return create({ ...params })
    case UPDATE:
      return update({ ...params })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}

const getOne = ({ id }) => ({
  query: gql`
    query User($id: Int!) {
      user(id: $id) {
        id
        firstName
        lastName
        email
        lastLogin
      }
    }`,
  variables: { id },
  parseResponse: ({ data: { user: data } }) => ({ data }),
})

const getList = () => {
  throw new Error("not implemented")
}

const update = ({ data }) => {
  return {
    query: gql`
      mutation
      UpdateUser($id: Int!, $update: UpdateUserInput!) {
        updateUser(id: $id, update: $update) {
          id
          email
          firstName
          lastName
          lastLogin
        }
      }
    `,
    variables: {
      id: get(data, "id"),
      update: {
        email: get(data, "email"),
        firstName: get(data, "firstName"),
        lastName: get(data, "lastName"),
        password: get(data, "password"),
      },
    },
    parseResponse: ({ data: { updateUser: data } }) => ({ data, total: 1 }),
  }
}

const create = () => {
  throw new Error("not implemented")
}

const getManyReference = ({ target, id }) => {
  switch (target) {
    case "account":
      return getManyReferenceForAccount({ id })
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForAccount = ({ id }) => ({
  query: gql`
    query($id: Int!) {
      account(id: $id) {
        userSlice {
          items {
            id
            email
            firstName
            lastName
            lastLogin
          }
          totalCount
        }
      }
    }
  `,
  variables: { id },
  parseResponse: ({ data: { account: { userSlice: { items: data, totalCount: total } } } }) => ({ data, total }),
})
