import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import DisplayNameField from "./DisplayNameField"

const CustomerField = ({ record = {}, tab, source, size = 25 }) => {
  if (!record) {
    return null
  }
  let account
  if (!!source) {
    account = get(record, source)
  } else {
    account = record
  }
  if (!account) {
    return null
  }

  return <a href={`/customers/${account.id}/show${tab ? "/" + tab : ""}`}>
    <DisplayNameField record={account} size={size}/>
  </a>
}

CustomerField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  addLabel: PropTypes.bool,
}

CustomerField.defaultProps = {
  label: "Customer",
  addLabel: true,
}

export default CustomerField
