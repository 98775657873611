export const CONTRACT_STATUS = {
  ACTIVE: "active",
  SUSPENDED: "suspended",
  FREE: "free",
  TRIAL: "trial",
}

export const CONTRACT_CLASSNAME_BY_STATUS = {
  [CONTRACT_STATUS.ACTIVE]: "contractStatusActive",
  [CONTRACT_STATUS.SUSPENDED]: "contractStatusSuspended",
  [CONTRACT_STATUS.FREE]: "contractStatusFree",
  [CONTRACT_STATUS.TRIAL]: "contractStatusTrial",
}

export const CONTRACT_COLORS = {
  [CONTRACT_CLASSNAME_BY_STATUS[CONTRACT_STATUS.ACTIVE]]: {
    color: "green",
  },
  [CONTRACT_CLASSNAME_BY_STATUS[CONTRACT_STATUS.SUSPENDED]]: {
    color: "orange",
  },
  [CONTRACT_CLASSNAME_BY_STATUS[CONTRACT_STATUS.FREE]]: {
    color: "blue",
  },
  [CONTRACT_CLASSNAME_BY_STATUS[CONTRACT_STATUS.TRIAL]]: {
    color: "#08605F",
  },
}

export const FEATURE_RESIDENTIAL_COMMITMENT = "residential_commitment"
export const FEATURE_RESIDENTIAL_OVERAGE = "residential_overage"
export const FEATURE_DATACENTER_COMMITMENT = "datacenter_commitment"
export const FEATURE_DATACENTER_OVERAGE = "datacenter_overage"
export const FEATURE_MOBILE_COMMITMENT = "mobile_commitment"
export const FEATURE_MOBILE_OVERAGE = "mobile_overage"

export const FEATURE_RESIDENTIAL_RECHARGE = "residential_recharge_threshold"

export const FEATURE_IP_TARGETS = "ip_targets"
export const FEATURE_ALEXA_TOP_TARGETS = "alexa_top_targets"
export const FEATURE_GOOGLE_SEARCH_TARGETS = "google_search_targets"

export const FEATURE_API_SERP = "api_serp"
export const FEATURE_API_FETCH = "api_fetch"
export const FEATURE_API_NEWSMEDIA = "api_newsmedia"
export const FEATURE_API_ENRICHMENT= "api_enrichment"

export const FEATURE_PROXY_CONCURRENCY = "proxy_concurrency"
export const FEATURE_PROXY_TUNNELING = "proxy_tunneling"
export const FEATURE_PROXY_MITM = "proxy_mitm"

export const FEATURE_HINTS = {
  [FEATURE_IP_TARGETS]: "Grants access to raw IP targets (e.g. 192.101.201.99). Disabled by default, high expertise required",
  [FEATURE_ALEXA_TOP_TARGETS]: "Grants access to Alex top 100 except Google. Disabled by default",
  [FEATURE_GOOGLE_SEARCH_TARGETS]: "Grants access to Google. Disabled by default",

  [FEATURE_API_SERP]: `Enables SERP API (Google, Bing, Yahoo). 
  Well tuned SERP collection API, pre-configured cookies, headers in HTTP requests. 
  99% success rate. Docs: https://docs.spider.com/docs/v1#tag/rest-serp`,
  [FEATURE_API_FETCH]: `SERP API-like HTTP requests. 
  Good for huge volumes of HTTP requests through our proxy, but with REST API
  Docs: https://docs.spider.com/docs/v1#tag/rest-fetch
  high expertise required
  `,
  [FEATURE_API_ENRICHMENT]: "Enables Muck Rack API (newsmedia related)",

  [FEATURE_RESIDENTIAL_RECHARGE]: "Bandwidth left on balance that triggers overage",

  [FEATURE_PROXY_TUNNELING]: "SSH tunneling, no transparency over traffic, deprecated, only old customers supported",
  [FEATURE_PROXY_MITM]: "MITM mode is a default way our proxy operates in. No proxy access if unchecked",
}

export const RECHARGE_FEATURES = [
  FEATURE_RESIDENTIAL_RECHARGE,
]

export const BANDWIDTH_FEATURES = [
  FEATURE_RESIDENTIAL_COMMITMENT,
  FEATURE_RESIDENTIAL_OVERAGE,
  FEATURE_DATACENTER_COMMITMENT,
  FEATURE_DATACENTER_OVERAGE,
  FEATURE_MOBILE_COMMITMENT,
  FEATURE_MOBILE_OVERAGE,
]

export const PREPAID_FEATURES = [
  FEATURE_RESIDENTIAL_COMMITMENT,
  FEATURE_DATACENTER_COMMITMENT,
  FEATURE_MOBILE_COMMITMENT,
]
export const POSTPAID_FEATURES = [
  FEATURE_RESIDENTIAL_OVERAGE,
  FEATURE_DATACENTER_OVERAGE,
  FEATURE_MOBILE_OVERAGE,
]
export const TARGETS_FEATURES = [
  FEATURE_IP_TARGETS,
  FEATURE_ALEXA_TOP_TARGETS,
  FEATURE_GOOGLE_SEARCH_TARGETS,
]

export const PROXY_FEATURES = [FEATURE_PROXY_MITM, FEATURE_PROXY_TUNNELING]
export const API_FEATURES = [FEATURE_API_SERP, FEATURE_API_FETCH, FEATURE_API_ENRICHMENT]
export const ADD_FEATURES = [
  FEATURE_PROXY_CONCURRENCY,
  FEATURE_API_NEWSMEDIA,
]

export const ALL_FEATURES = [
  ...RECHARGE_FEATURES,
  ...PREPAID_FEATURES,
  ...POSTPAID_FEATURES,
  ...TARGETS_FEATURES,
  ...API_FEATURES,
  ...ADD_FEATURES,
]
