import React from "react"
import { List, Datagrid, DateField, TextField, Filter, SelectInput, SearchInput } from "react-admin"

import Pagination from "../Pagination"
import CustomerField from "../CustomerField"
import CurrencyField from "../CurrencyField"
import DownloadInvoiceField from "../DownloadInvoiceField"
import BooleanYesNoField from "../BooleanYesNoField"
import BulkActionButtons from "./BulkActionButtons"
import TextUrlField from "../TextUrlField"


export const InvoicesFilters = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn/>
    <SelectInput source="type" allowEmpty alwaysOn label="Type" choices={[
      { id: 'volume', name: 'Volume' },
      { id: 'quota', name: 'Quota' },
    ]}/>
  </Filter>
)


export default ({ ...props }) => {
  return <List
    bulkActionButtons={<BulkActionButtons/>}
    pagination={<Pagination/>}
    perPage={25}
    sort={{ field: "updatedAt", order: "DESC" }}
    filters={<InvoicesFilters/>}
    {...props}
  >
    <Datagrid {...props} >
      <TextUrlField label="#" source="previewURL" displaySource="ref"/>
      <TextField style={{textTransform: "capitalize"}} source="type"/>
      <CurrencyField source="totalAmount"/>
      <CustomerField source="billingPeriod.customer.account" tab="invoices" label="Customer" sortable={false}/>
      <DateField source="billingPeriod.startDate" label="Billing Period Start"/>
      <DateField source="billingPeriod.endDate" label="Billing Period End"/>
      <DateField source="updatedAt" showTime/>
      <BooleanYesNoField source="isPaid" label="Paid"/>
      <DownloadInvoiceField label="Download"/>
    </Datagrid>
  </List>
}