import React from "react"
import { IconButton, Tooltip } from "@material-ui/core"
import ContactSupportIcon from "@material-ui/icons/ContactSupport"
import { withStyles } from "@material-ui/core"


const styles = () => ({
  white: {
    color: "white"
  },
})

export default withStyles(styles)(({ classes }) => (
  <Tooltip title="Freshdesk">
    <IconButton
      className={classes.white}
      target="_blank"
      rel="noopener noreferrer"
      href="https://spiderhelp.freshdesk.com/a/tickets/filters/unresolved">
      <ContactSupportIcon/>
    </IconButton>
  </Tooltip>
))