import React from "react"
import { Tooltip, Link } from "@material-ui/core"
import get from "lodash/get"
import LaunchIcon from "@material-ui/icons/Launch"

export const ExternalLinkField = ({ source, record }) => {
    const externalURL = get(record, source)
    if (!externalURL) {
        return null
    }

    return (
        <Tooltip title={externalURL}>
            <span>
              <Link href={externalURL} target="_blank">
                <LaunchIcon />
              </Link>
            </span>
        </Tooltip>
    )
}

export default ExternalLinkField
