import React from "react"
import { Show, TabbedShowLayout, TabbedShowLayoutTabs, Tab } from "react-admin"
import { withStyles } from "@material-ui/core"
import DisplayNameField from "../../DisplayNameField"
import Actions from "./Actions"
import Summary from "./Summary"
import Usage from "./Usage"
import Users from "./Users"
import Transactions from "./Transactions"
import Invoices from "./Invoices"
import Zones from "./Zones"
import Contracts from "./Contracts"
import Logins from "./Logins"
import Notes from "./Notes"
import BalanceTransactions from "./BalanceTransactions"
import FeatureRequests from "./FeatureRequests"

const CustomerPreview = (props) => {
  return (
    <Show actions={<Actions />} title={<DisplayNameField />} {...props}>
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}
      >
        <Tab className={props.classes.tab} label="Summary">
          <Summary />
        </Tab>
        <Tab className={props.classes.tab} label="Usage" path="usage">
          <Usage />
        </Tab>
        <Tab className={props.classes.tab} label="Contracts" path="contracts">
          <Contracts />
        </Tab>
        <Tab
          className={props.classes.tab}
          label="Balance"
          path="balance-transactions"
        >
          <BalanceTransactions />
        </Tab>
        <Tab className={props.classes.tab} label="Notes" path="notes">
          <Notes />
        </Tab>
        <Tab className={props.classes.tab} label="Users" path="users">
          <Users />
        </Tab>
        <Tab
          className={props.classes.tab}
          label="Zones"
          path="zones"
          style={{ paddingBottom: "10px" }}
        >
          <Zones />
        </Tab>
        <Tab
          className={props.classes.tab}
          label="Transactions"
          path="transactions"
        >
          <Transactions />
        </Tab>
        <Tab className={props.classes.tab} label="Invoices" path="invoices">
          <Invoices />
        </Tab>
        <Tab
          className={props.classes.tab}
          label="Access requests"
          path="feature-request"
        >
          <FeatureRequests />
        </Tab>
        <Tab className={props.classes.tab} label="Logins" path="logins">
          <Logins />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const style = () => ({
  tab: {
    minWidth: "120px",
  },
})

export default withStyles(style)(CustomerPreview)
