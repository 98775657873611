import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import FullNameField from '../FullNameField'
import redirect from './redirect'

const Title = ({ record }) => record ? <FullNameField record={record} size={32}/> : null

export default (props) => (
  <Edit title={<Title/>} undoable={false} {...props}>
    <SimpleForm label="Summary" redirect={redirect} variant="standard" margin="normal">
      <TextInput source="id" disabled/>
      <TextInput source="email"/>
      <TextInput source="firstName"/>
      <TextInput source="lastName"/>
      <TextInput source="password"/>
    </SimpleForm>
  </Edit>
)
