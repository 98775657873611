import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "@apollo/client"

import { ImpersonateUser, ImpersonateAccount } from "./components"
import Admin from "./Admin"
import TokenLogin from "./components/TokenLogin"

import { apolloClientStaff } from "./apolloClient"

export default () => {
  return <BrowserRouter>
    <ApolloProvider client={apolloClientStaff()}>
      <Switch>
        <Route exact path="/login/token/:token" component={TokenLogin}/>
        <Route exact path="/impersonate/account/:id" component={ImpersonateAccount}/>
        <Route exact path="/impersonate/user/:id" component={ImpersonateUser}/>
        <Route path="/" component={Admin}/>
      </Switch>
    </ApolloProvider>
  </BrowserRouter>
}
