import React from "react"
import { pure } from "recompose"
import RefundsField from "./BraintreeTransactionRefundsField"
import RefundedByField from "./BraintreeTransactionRefundedByField"


const Field = ({ record, refundsSource, refundedBySource, ...props }) => {
  return (<>
    <RefundsField record={record} source={refundsSource} {...props}/>
    <RefundedByField record={record} source={refundedBySource} {...props}/>
  </>)
}

const PureField = pure(Field)

PureField.defaultProps = {
  label: "Refunds/Refunded by",
  refundsSource: "refunds",
  refundedBySource: "refundedBy",
  sortable: false,
}

export default PureField
