import React from 'react'
import { Create, SimpleForm, ReferenceInput, TextInput, SelectInput } from 'react-admin'
import redirect from './redirect'

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect} variant="standard" margin="normal">
      <ReferenceInput label="Customer" source="account" reference="customers">
        <SelectInput optionText="company"/>
      </ReferenceInput>
      <TextInput source="email"/>
      <TextInput source="firstName"/>
      <TextInput source="lastName"/>
      <TextInput source="password"/>
    </SimpleForm>
  </Create>
)
