import {
  ApolloClient, ApolloLink, from, HttpLink, InMemoryCache
} from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import { getAPIUrl } from "./config"


const GRAPHQL_URL = getAPIUrl();
const retryLink = new RetryLink();

const apolloClient = (uri) => {
  const httpLink = new HttpLink({ uri, fetch })

  const authMiddleware = new ApolloLink((operation, forward) => {
    let auth = JSON.parse(localStorage.getItem('auth-token') || null)
    if (!!auth) {
      const expiresAt = moment(auth.expiresAt)
      const notBefore = moment(auth.notBefore)
      if (moment().isBefore(expiresAt) && moment().isAfter(notBefore)) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
      }
    }
    return forward(operation)
  })

  const errorMiddleware = onError(({ networkError }) => {
    if (!!networkError) {
      // @ts-ignore
      const { response } = networkError
      if (!!response && (response.status === 401 || response.status === 403)) {
        localStorage.removeItem('me')
        localStorage.removeItem('auth-token')
        localStorage.removeItem('refresh-token')
      }
    }
  })

  const link = from([authMiddleware, retryLink, errorMiddleware, httpLink])

  const cache = new InMemoryCache()

  return new ApolloClient({ link, cache })
}

export const apolloClientAuth = () => {
  return apolloClient(GRAPHQL_URL + '/_graphql/auth/')
}

export const apolloClientStaff = () => {
  return apolloClient(GRAPHQL_URL + '/_graphql/staff/')
}
