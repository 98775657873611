import React, { useState } from "react"
import { Pagination } from "react-admin"
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  withStyles,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core"
import gql from "graphql-tag"
import { Query } from "@apollo/client/react/components"
import moment from "moment"
import identity from "lodash/identity"

const PER_PAGE_OPTIONS = [25, 50, 100]

const GROUP_LOGINS_BY_OPTIONS = {
  NONE: {
    DESCRIPTION: "All login events",
    CODE: "none",
  },
  IP: {
    DESCRIPTION: "Last login event per IP",
    CODE: "ip",
  },
}

const getLocation = (userLogin = {}) =>
  [userLogin.locality, userLogin.region, userLogin.country]
    .filter(identity)
    .join(" ,")

const getGoogleMapsUrl = (location) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    location,
  )}`

const UserLogins = ({
  items = [],
  totalCount,
  page,
  setPage,
  perPage,
  setPerPage,
  filters,
  groupBy,
}) => (
  <Card elevation={0}>
    <CardContent>
      {filters}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Ip</TableCell>
            <TableCell>{groupBy === GROUP_LOGINS_BY_OPTIONS.IP.CODE ? "Last login date" : "Date"}</TableCell>
            <TableCell>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, id) => {
            const location = getLocation(item)
            return (
              <TableRow key={id}>
                <TableCell align="center">
                  <code>{item.id}</code>
                </TableCell>
                <TableCell align="center">
                  <code>{item.ip}</code>
                </TableCell>
                <TableCell align="center">
                  {moment(item.createdAt).format("MMM DD, h:mm a")}
                </TableCell>
                <TableCell align="center">
                  {location && (
                    <a href={getGoogleMapsUrl(location)}>{location}</a>
                  )}
                  {!location && "-"}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <Pagination
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        total={totalCount}
      />
    </CardContent>
  </Card>
)

function Logins({ userId, extraFilter, classes }) {
  const [groupBy, setGroupBy] = useState(GROUP_LOGINS_BY_OPTIONS.NONE.CODE)
  const [perPage, setPerPage] = useState(PER_PAGE_OPTIONS[0])
  const [page, setPage] = useState(1)

  if (!userId) {
    return null
  }

  return (
    <Query
      query={loginsQuery}
      variables={{
        userId: userId,
        limit: perPage,
        offset: (page - 1) * perPage,
        group: groupBy,
      }}
    >
      {({ loading, error, data: { userLogins } = {} } = {}) => {
        if (!!loading) {
          return (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )
        }

        if (error) {
          return <Typography color="error">{String(error)}</Typography>
        }

        return (
          <UserLogins
            items={userLogins.items}
            totalCount={userLogins.totalCount}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            groupBy={groupBy}
            filters={
              <React.Fragment>
                <Grid container spacing={4}>
                  {extraFilter && (
                    <Grid item>
                      {extraFilter}
                    </Grid>
                  )}
                  <Grid item>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={groupBy}
                        onChange={(e) => setGroupBy(e.target.value)}
                      >
                        {Object.values(GROUP_LOGINS_BY_OPTIONS).map(
                          (option) => (
                            <MenuItem value={option.CODE}>
                              {option.DESCRIPTION}
                            </MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          />
        )
      }}
    </Query>
  )
}

export default withStyles(() => ({
  loader: {
    display: "flex",
    padding: "50px 0",
    justifyContent: "center",
  },
}))(Logins)

const loginsQuery = gql`
  query(
    $userId: Int!
    $limit: Int!
    $offset: Int!
    $group: UserLoginSliceGroupType!
  ) {
    userLogins: userLoginSlice(
      userId: $userId
      limit: $limit
      offset: $offset
      filter: { group: $group }
    ) {
      totalCount
      items {
        id
        ip
        continent
        country
        region
        locality
        postalCode
        org
        createdAt
      }
    }
  }
`
