import { get } from "lodash"
import React from "react"

const MonthlyCell = ({source, formData}) => {
  if (!get(formData, `${source}.active`)) {
    return <span>0</span>
  }

  return <span>{get(formData, `${source}.unitPrice`) * get(formData, `${source}.quantity`, 1)}</span>
}

export default MonthlyCell
