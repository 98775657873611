import React from "react"
import { pure } from "recompose"
import { get } from "lodash"
import IDFiled from "./IDField"

const Field = ({ record, source, ...props }) => {
  const refundedBy = get(record, source)
  if (!refundedBy) {
    return null
  }
  return <>{refundedBy.map((txn) => {
    return <IDFiled record={txn} source="id" {...props}/>
  })}</>
}

const PureField = pure(Field)

PureField.defaultProps = {
  label: "Refunded by",
  source: "refundedBy",
  sortable: false,
}

export default PureField
