import {
  GET_ONE,
  GET_LIST,
  GET_MANY_REFERENCE,
} from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_ONE:
      return getOne({ ...params })
    case GET_LIST:
      return getList({ ...params })
    case GET_MANY_REFERENCE:
      return getManyReference({...params})
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}

const getOne = ({ id }) => ({
  query: gql`
    query ($id: ID!) {
       transaction(id: $id) {
         id
         status
         type
         createdAt
         updatedAt
         amount
         customer {
           id
           firstName
           lastName
         }
         braintreeURL
       }
    }`,
  variables: { id: id },
  parseResponse: ({ data: { transaction } }) => ({ data: transaction })
})

const getList = ({ pagination: { page, perPage }, filter: { q: query, status }, sort }) => {
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: {
      query,
      status,
    },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
      query ($limit: Int!, $offset: Int!, $filter: TransactionSliceFilter, $sort: TransactionSliceSorter) {
        transactionSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
          totalCount
          items {
            id
            amount
            type
            status
            creditCard {
              customer {
                customer {
                  account {
                    id
                    email
                    displayName
                  }
                }
              }
            }
            refunds {
              id
            }
            refundedBy {
              id
            }
            subscription {
              id
            }
            invoice {
              url
            }
            createdAt
            braintreeURL
          }
        }
      }
    `,
    variables,
    parseResponse: (response) => {
      const {
        data: {
          transactionSlice: { items, totalCount },
        },
      } = response
      return {
        data: items,
        total: totalCount,
      }
    },
  }
}

const getManyReference = ({ target, id, pagination: {page, perPage}, sort }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: { createdAt: true, createdAtDesc: true, ...querySortHelper(sort)},
    filter: {}
  }
  switch (target) {
    case "customer":
      return getManyReferenceForCustomer({ ...variables })
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = (variables) => {
  return {
    query: gql`
      query ($id: Int!, $limit: Int!, $offset: Int!, $filter: TransactionSliceFilter, $sort: TransactionSliceSorter) {
        customer(id: $id) {
          transactionSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
            totalCount
            items {
              id
              createdAt
              updatedAt
              status
              amount
              type
              braintreeURL
              refunds {
                id
              }
              refundedBy {
                id
              }
              invoice {
                url
              }
            }
          }
        }
      }
    `,
    variables: variables,
    parseResponse: ({ data: { customer: { transactionSlice: { items: data, totalCount: total } } } }) => ({
      data,
      total,
    }),
  }
}