import React from "react"
import pure from "recompose/pure"
import get from "lodash/get"

const IPAddressField = pure(({ record = {}, source }) => {
  const ipAddress = get(record, source)
  return (
    ipAddress ? <div>
      {`${ipAddress.traits.ipAddress} - ${ipAddress.country.names.en} / ${ipAddress.city.names.en}`}
    </div> : null
  )
})

IPAddressField.defaultProps = {
  label: 'IP Address',
  sortable: false,
}

export default IPAddressField