import React from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import ServiceInput from "./ServiceInput"


const styles = {
  table: {
    marginTop: 14,
  },
}

const FeaturesTable = ({title, features, onValueChange, classes}) => (
  <>
    <Typography color="secondary" variant="h6" gutterbottom="true">{title}</Typography>
    <Table className={classes.table} size="small" padding="none">
      <TableHead>
        <TableRow>
          <TableCell align="left">Active</TableCell>
          <TableCell align="left">Name</TableCell>
          <TableCell align="left">Price per Unit, $</TableCell>
          <TableCell align="left">Quantity</TableCell>
          <TableCell align="left">Unit</TableCell>
          <TableCell align="left">Monthly, $</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {features.map((s, idx) => <ServiceInput
          key={idx}
          onValueChange={onValueChange}
          service={s}
        />)}
      </TableBody>
    </Table>
  </>
)


export default withStyles(styles)(FeaturesTable)
