import React from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import { crudUpdateMany } from "react-admin"
import { Button } from "@material-ui/core"
import PaidIcon from "@material-ui/icons/Payment"

const enhance = compose(
  connect(null, { crudUpdateMany }),
)

const Action = enhance(({ basePath, selectedIds, isPaid, crudUpdateMany, children }) => {
  return <Button variant="text" size="small" onClick={() => {
    crudUpdateMany("invoices", selectedIds, { isPaid }, basePath);
  }}>
    {children}
  </Button>
})

export const MarkAsPaidButton = ({...props}) => {
  return <Action isPaid={true} {...props}>
    <PaidIcon/> Mark as Paid
  </Action>
}

export default ({ ...props }) => (
  <>
    <MarkAsPaidButton {...props}/>
  </>
)
