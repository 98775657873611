import * as React from "react"
import { Create, TabbedForm, FormTab } from "react-admin"
import Details, { autogenerateHandle } from "./Details"
import ContractFeatures from "./ContractFeatures"
import CustomerNoteForm from "../../customerNotes/components/CustomerNoteForm"
import { FEATURE_PROXY_MITM } from "../constants"

const CustomerCreate = (props) => {
  return (
    <Create {...props}>
      <TabbedForm redirect="show" decorators={[autogenerateHandle]}>
        <FormTab label="Contract">
          <ContractFeatures defaultFeatures={[
            {
              feature: FEATURE_PROXY_MITM,
              unitPrice: 0,
              quantity: 1,
            },
          ]}/>
        </FormTab>
        <FormTab label="Account">
          <Details/>
        </FormTab>
        <FormTab label="Note">
          <CustomerNoteForm/>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default CustomerCreate
