import React from "react"

import { List, Datagrid, Filter, SearchInput, DateField, TextField } from "react-admin"
import CurrencyField from "../CurrencyField"
import CustomerField from "../CustomerField"
import IDField from "../IDField"
import Pagination from "../Pagination"
import BraintreeTransactionRefundsOrRefundedByField from "../BraintreeTransactionRefundsOrRefundedByField"
import BraintreeLinkField from "../BraintreeLinkField"
import DownloadInvoiceField from "../DownloadInvoiceField"

export const TransactionsFilter = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn/>
  </Filter>
)

export default ({...props}) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<TransactionsFilter/>}
    pagination={<Pagination/>}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <IDField source="id"/>
      <DateField source="createdAt" showTime />
      <CustomerField source="creditCard.customer.customer.account" tab="transactions" label="Customer" sortable={false}/>
      <TextField source="type" sortable={false}/>
      <TextField source="status" sortable={false}/>
      <CurrencyField source="amount" sortable={false}/>
      <BraintreeTransactionRefundsOrRefundedByField/>
      <BraintreeLinkField label="Braintree"/>
      <DownloadInvoiceField source="invoice" label="Invoice"/>
    </Datagrid>
  </List>
)