import filesize from "filesize"
import { formatMoney as money } from "accounting"

export const formatFileSize = (value) => {
  const exponent = value / (1024 * 1024 * 1024) > 1 ? 3 : -1
  return filesize(value, { exponent })
}

export const formatFileSizeGiB = (value) => {
  return filesize(value, { output: "object", exponent: 3 }).value
}

export const formatMoney = (v) => {
  return money(v)
}
