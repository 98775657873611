import React, { useEffect, useState } from "react"
import { Admin as ReactAdmin, Resource } from "react-admin"
import theme from "./theme"
import { createBrowserHistory } from "history"
import { Dashboard, Layout, LoginPage } from "./components"
import { SignupList, SignupShow } from "./resources/signups"
import { ContractList } from "./resources/contracts"
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "./resources/customers"
import {
  PartnerCreate,
  PartnerEdit,
  PartnerShow,
  PartnersList,
} from "./resources/partners"
import { StaffCreate, StaffEdit, StaffShow, StaffList } from "./resources/staff"
import {
  CustomerFeatureRequestCreate,
  CustomerFeatureRequestList,
} from "./resources/customerFeatureRequests"
import {
  PremiumDomainList,
  PremiumDomainCreate,
  PremiumDomainEdit,
} from "./resources/premiumDomains"
import { UserCreate, UserEdit } from "./resources/users"
import { ZonesList } from "./resources/zones"
import { TransactionList, TransactionShow } from "./resources/transactions"
import { InvoiceList } from "./resources/invoices"
import customRoutes from "./customRoutes"
import {
  PartnerAppCreate,
  PartnerAppEdit,
  PartnerAppsList,
  PartnerAppsShow,
} from "./resources/partnerApps"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core"
import authProvider from "./authProvider"
import buildDataProvider from "./buildDataProvider"

const Loading = () => (
  <Dialog open={true} fullWidth={true}>
    <DialogTitle>Loading</DialogTitle>
    <DialogContent>
      <LinearProgress variant="indeterminate" />
    </DialogContent>
  </Dialog>
)

const Admin = () => {
  const [dataProvider, setDataProvider] = useState(null)
  const [history, setHistory] = useState(null)

  useEffect(() => {
    buildDataProvider().then((dataProvider) => {
      setDataProvider(() => dataProvider)
    })
    setHistory(createBrowserHistory())
  }, [])

  return !authProvider || !dataProvider ? (
    <Loading />
  ) : (
    <ReactAdmin
      theme={theme}
      history={history}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      layout={Layout}
      loginPage={LoginPage}
    >
      <Resource name="signups" list={SignupList} show={SignupShow} />
      <Resource name="contracts" list={ContractList} />
      <Resource
        name="customers"
        show={CustomerShow}
        list={CustomerList}
        edit={CustomerEdit}
        create={CustomerCreate}
      />
      <Resource name="customerNotes" />
      <Resource name="workersByCountrySeries" />
      <Resource
        name="partners"
        list={PartnersList}
        create={PartnerCreate}
        edit={PartnerEdit}
        show={PartnerShow}
      />
      <Resource
        name="customer-feature-request"
        list={CustomerFeatureRequestList}
        create={CustomerFeatureRequestCreate}
      />
      <Resource
        name="premium-domains"
        list={PremiumDomainList}
        create={PremiumDomainCreate}
        edit={PremiumDomainEdit}
      />
      <Resource name="users" create={UserCreate} edit={UserEdit} />
      <Resource
        name="transactions"
        list={TransactionList}
        show={TransactionShow}
      />
      <Resource name="invoices" list={InvoiceList} />
      <Resource name="zones" list={ZonesList} />
      <Resource
        name="staff"
        list={StaffList}
        create={StaffCreate}
        show={StaffShow}
        edit={StaffEdit}
      />
      <Resource
        name="partner-apps"
        edit={PartnerAppEdit}
        create={PartnerAppCreate}
        show={PartnerAppsShow}
        list={PartnerAppsList}
      />
      <Resource name="premium-domains" />
      <Resource name="balance-transactions" />
    </ReactAdmin>
  )
}

export default Admin
