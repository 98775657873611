import React from "react"
import {
  TextField,
} from "react-admin"
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"

export default ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Payment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Payment method</TableCell>
              <TableCell>
                <TextField record={record} source="paymentMethod"/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      {record.paypalAccount && (
        <Grid item md={6} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Paypal Account</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Paypal E-Mail</TableCell>
                <TableCell>
                  <TextField record={record} source="paypalAccount.email"/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Holder Name</TableCell>
                <TableCell>
                  <TextField record={record} source="paypalAccount.holderName"/>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Created</TableCell>
                <TableCell>
                  <TextField record={record} source="paypalAccount.createdAt"/>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  )
}