export const REQUESTED_FEATURE = {
  ALEXA_TOP_TARGETS: "alexa_top_targets",

  API_FETCH: "api_fetch",
  API_SERP: "api_serp",
}

export const REQUESTED_FEATURE_DISPLAY_NAME = {
  [REQUESTED_FEATURE.ALEXA_TOP_TARGETS]: "Alexa top targets",

  [REQUESTED_FEATURE.API_FETCH]: "Fetch API",
  [REQUESTED_FEATURE.API_SERP]: "SERP API",
}
