import React from "react"
import { useGetManyReference } from "react-admin"
import ChangeUserPassword from "./ChangeUserPassword";
import find from "lodash/find"

function matchMainAccountUser(account, users) {
    if (!users || !account) {
        return
    }

    return find(users, user => user.email === account.email)
}

const ChangeAccountPassword = (props) => {
    const { loading, data } = useGetManyReference(
        "users",
        "account",
        props.record.id,
    )

    return <ChangeUserPassword basePath={props.basePath} loading={loading} record={matchMainAccountUser(props.record, data)}/>
}

export default ChangeAccountPassword