import React from "react"
import {
  Show,
  TabbedShowLayout,
  Tab,
} from "react-admin"
import Summary from "./Summary"
import Actions from "./Actions"
import UserLogins from "../../../components/Logins"

export default ({ ...props }) => {
  return <Show actions={<Actions/>} {...props}>
    <TabbedShowLayout>
      <Tab label="Summary">
        <Summary/>
      </Tab>
      <Tab label="Logins">
        <UserLogins userId={props.id} />
      </Tab>
    </TabbedShowLayout>
  </Show>
}