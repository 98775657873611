import React from "react"
import {
  ADD_FEATURES,
  API_FEATURES,
  BANDWIDTH_FEATURES,
  PROXY_FEATURES,
  TARGETS_FEATURES,
  FEATURE_PROXY_CONCURRENCY, FEATURE_RESIDENTIAL_RECHARGE
} from "../constants";
import FeaturesTable from "./features/FeaturesTable"
import FeaturesGrid from "./features/FeaturesGrid"
import Grid from "@material-ui/core/Grid"
import { find } from "lodash"

const DEFAULT_PROXY_CONCURRENCY = 200

const CONTRACT_SECTIONS = [
  {
    title: "Recharge config",
    features: [FEATURE_RESIDENTIAL_RECHARGE],
    Component: FeaturesTable,
  },
  {
    title: "Bandwidth config",
    features: BANDWIDTH_FEATURES,
    Component: FeaturesTable,
  },
  {
    title: "Target domain access config",
    features: TARGETS_FEATURES,
    Component: FeaturesGrid,
  },
  {
    title: "API Access config",
    features: API_FEATURES,
    Component: FeaturesGrid,
  },
  {
    title: "Dev proxy config",
    subtitle: "MITM enables proxy access",
    features: PROXY_FEATURES,
    Component: FeaturesGrid,
  },
  {
    title: "Additional services",
    features: ADD_FEATURES,
    Component: FeaturesTable,
  },
]

const ContractFeaturesTemplate = ({ defaultFeatures, onValueChange }) => {
  const getDefaults = (names, idx) => {
    return names.map((feature, fIdx) => {
      const defaultValues = find(defaultFeatures, (f) => f.feature === feature)
      const active = feature === FEATURE_PROXY_CONCURRENCY || !!defaultValues
      const defaultQuantity =
        feature === FEATURE_PROXY_CONCURRENCY ? DEFAULT_PROXY_CONCURRENCY : 1
      return {
        ...(defaultValues || { quantity: defaultQuantity, unitPrice: 0 }),
        feature: feature,
        source: `features[${fIdx + idx}]`,
        active: active,
        idx: fIdx + idx,
      }
    })
  }

  const countPreviousFeatures = (source, idx) => {
    let count = 0

    while (idx !== 0) {
      count += CONTRACT_SECTIONS[idx - 1].features.length
      idx -= 1
    }

    return count
  }

  return (
    <Grid container spacing={2}>
      {CONTRACT_SECTIONS.map((s, idx) => (
        <Grid item xs={10} key={idx}>
          {
            <s.Component
              onValueChange={onValueChange}
              features={getDefaults(
                s.features,
                countPreviousFeatures(CONTRACT_SECTIONS, idx),
              )}
              title={s.title}
              subtitle={s.subtitle}
              examples={s.examples}
            />
          }
        </Grid>
      ))}
    </Grid>
  )
}

export default ContractFeaturesTemplate
