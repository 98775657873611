export const convertGBToBytes = (v) => (!!v ? v * Math.pow(1024, 3) : 0);
export const convertBytesToGiB = (v) => (!!v ? v / Math.pow(1024, 3) : 0);
export const convertMBToBytes = (v) => (!!v ? v * Math.pow(1024, 2) : 0);
export const convertBytesToMiB = (v) => (!!v ? v / Math.pow(1024, 2) : 0);

export const unitsToBytes = (amount, units) => {
  switch (units) {
    case "mb":
      return convertMBToBytes(amount);
    default:
      return convertGBToBytes(amount);
  }
};

export const bytesToUnits = (amount, units) => {
  console.log("byte2units", amount, units);
  switch (units) {
    case "mb":
      return convertBytesToMiB(amount);
    default:
      return convertBytesToGiB(amount);
  }
};

const units = ['b', 'kb', 'mb', 'gb']
export const unitFromBytes = (amount, idx = 0) => {
  return 'mb'
  if (amount <= 1024) {
    return units[idx]
  }

  return unitFromBytes(amount/1024, idx+1)
}
