import React from "react"
import { Typography, Card, CardHeader, CardContent } from "@material-ui/core"

export default ({ record  }) => {
  if (!record) {
    return null
  }
  return <Card>
    <CardHeader subheader="Developer Info"/>
    <CardContent>
      <Typography variant="caption">Name</Typography>
      <Typography paragraph>{record.develop}</Typography>

      <Typography variant="caption">Email</Typography>
      <Typography paragraph>{record.developerEmail}</Typography>

      <Typography variant="caption">Website</Typography>
      <Typography paragraph>
        {
          record.developerWebsite ?
            <a href={record.developerWebsite} target="_blank" rel="noopener noreferrer">
              {record.developerWebsite}
            </a> : null
        }
      </Typography>

      <Typography variant="caption">Info</Typography>
      <Typography paragraph>{record.developerInfo}</Typography>
    </CardContent>
  </Card>
}