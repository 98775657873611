import {
  GET_LIST,
  GET_MANY_REFERENCE,
} from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    case GET_LIST:
      return getList({ ...params })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}


const getList = ({ pagination: { page, perPage }, filter: { q: query, status }, sort }) => {
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: {
      query,
      status,
    },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
        query GetContractSlice($limit: Int!, $offset: Int!, $filter: ContractSliceFilter, $sort: ContractSliceSorter) {
            customerSlice(limit: $limit, filter: { ids: $ids  }) {
                items {
                    id
                    account {
                        id
                        email
                        displayName
                    }
                }
            }
        }
    `,
    variables,
    parseResponse: (response) => {
      const {
        data: {
          customerSlice: { items, totalCount },
        },
      } = response
      return {
        data: items,
        total: totalCount,
      }
    },
  }
}

const getManyReference = ({ target, ...props }) => {
  switch (target) {
    case "customer":
      return getManyReferenceForCustomer({...props})
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = ({ id, pagination: { page, perPage }, filter, sort }) => {
  const variables = {
    id,
    limit: perPage,
    offset: (page - 1) * perPage,
    filter,
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
        query GetManyContractSlice($limit: Int!, $offset: Int!, $filter: ContractSliceFilter, $sort: ContractSliceSorter) {
            contractSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                items {
                    id
                    createdAt
                    plan {
                        id
                        displayName
                    }
                    issuedBy {
                        id
                        displayName
                    }
                    status
                }
                totalCount
            }
        }
    `,
    variables,
    parseResponse: ({ data: { contractSlice: {items: data, totalCount: total} }}) => ({data, total}),
  }
}
