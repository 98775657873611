import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import { RadioButtonGroupInput } from "react-admin"
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { CircularProgress } from "@material-ui/core"
import get from "lodash/get"
import ContractFeaturesTemplate from "./ContractFeaturesTemplate"
import { useForm } from "react-final-form"
import { BANDWIDTH_FEATURES } from "../constants";

const ContractFeatures = ({defaultPlan, defaultFeatures, data: { loading, error, billingPlans }}) => {
  const form = useForm()
  const [selectedPlan, selectPlan] = useState(defaultPlan || "micro")
  if (!!error) {
    console.error(error)
  }

  if (loading || error) {
    return <CircularProgress variant="indeterminate"/>
  }

  const overwriteFormState = (newFeatures) => {
    const formState = form.getState()
    const {values: { features }} = formState
    newFeatures.forEach(f => {
      const featureIdx = features.map(s => s.feature).indexOf(f.feature)
      form.change(`features[${featureIdx}.quantity`, f.quantity)
      form.change(`features[${featureIdx}.unitPrice`, f.unitPrice)
      form.change(`features[${featureIdx}.unit`, get(f, 'unit', '').toLowerCase())
      form.change(`features[${featureIdx}.active`, true)
    })
    if (newFeatures.length > 0) {
      BANDWIDTH_FEATURES.forEach((feature) => {
        const fIdx = features.map(s => s.feature).indexOf(feature)
        form.change(`features[${fIdx}].active`, !!newFeatures.find(pf => pf.feature === feature))
      })
    }
  }

  const setPlan = (plan) => {
    const newPlan = plans.find(p => p.slug === plan);
    overwriteFormState(newPlan.features)
    selectPlan(plan)
  }
  const customPlan = { slug: "custom", displayName: "Custom", features: [] }
  const plans = [...billingPlans.items, customPlan]
  const currentPlan = plans.find(p => p.slug === selectedPlan);
  if (!defaultFeatures || defaultFeatures.length === 0) {
    defaultFeatures = currentPlan.features;
  }

  const onValueChange = (feature, source, value) => {
    if (BANDWIDTH_FEATURES.indexOf(feature) !== -1) {
      form.change('plan', customPlan.slug)
    }
  }

  return (
    <Grid container>
      <Grid md={12} xs={12} lg={12} item>
        <RadioButtonGroupInput
          defaultValue={selectedPlan}
          onChange={setPlan}
          source="plan"
          choices={plans}
          optionValue="slug"
          optionText="displayName"
        />
      </Grid>
      <ContractFeaturesTemplate
        onValueChange={onValueChange}
        defaultFeatures={defaultFeatures}/>
    </Grid>
  )
}

const enhance = compose(
  graphql(gql`query BillingPlans {
      billingPlans {
          items {
              id
              slug
              displayName
              features {
                id
                feature
                unitPrice
                unit
                quantity
              }
          }
      }
  }
  `, { options: { fetchPolicy: "no-cache" } }),
)

export default enhance(ContractFeatures)
