import React from "react";
import { EditButton } from "react-admin";

import { Button, CardActions, withStyles } from "@material-ui/core";

import Icon from "@material-ui/icons/ShowChart";

import Block from "./Block";
import Refresh from "./Refresh";
import { ImpersonateButton } from "../../../../components";
import IssueApiToken from "../../../../components/IssueAPIToken";
import DeleteAccount from "../../../../components/DeleteAccount";
import ActivateContract from "./ActivateContract";
import MarkAsFreeContract from "./MarkAsFreeContract";
import CancelContract from "./CancelContract";
import ContractStatus from "./ContractStatus";
import ChangeAccountPassword from "../../../../components/ChangeAccountPassword";
import AddBalanceTransaction from "./AddBalanceTransaction";
import get from "lodash/get";

const styles = () => ({
  actions: {
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end"
  }
});

const ShowIndex = ({ classes, basePath, data }) => {
  if (!data) {
    return null;
  }


  const { contract: { plan }} = data
  const onFreePlan = get(plan, 'slug') === 'free'

  return (
    <CardActions className={classes.actions}>
      <ContractStatus customer={data} />
      {!onFreePlan && <ActivateContract customer={data} />}
      <CancelContract customer={data} />
      {!onFreePlan && <MarkAsFreeContract customer={data} />}
      <AddBalanceTransaction record={data} />
      <Block user={data.account} />
      <IssueApiToken account={data.account} />
      <Refresh customer={data} />
      <ImpersonateButton record={data} source="account" type="account" />
      <Button color="primary"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://dash.blueswan.io/d/ANSIJocik/proxy-usage?orgId=1&var-accounts=${data.handle}`}
      >
        <Icon className={classes.bandwidthUsageIcon} />
        <span>Bandwidth</span>
      </Button>
      <DeleteAccount record={data.account} />
      <ChangeAccountPassword basePath={basePath} record={data.account} />
      {!onFreePlan && <EditButton basePath={basePath} record={data} />}
    </CardActions>
  );
}

export default withStyles(styles)(ShowIndex);
