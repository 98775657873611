import React from "react"
import { pure } from "recompose"
import { get } from "lodash"
import { Button } from "@material-ui/core"
import DownloadIcon from "@material-ui/icons/PictureAsPdf"

const DownloadInvoiceField = ({ record, source }) => {
  if (!record) {
    return null
  }
  let invoice
  if (!!source) {
    invoice = get(record, source)
  } else {
    invoice = record
  }
  if (!invoice) {
    return null
  }
  const { url } = invoice

  return <Button variant="text" href={url} target="_blank"><DownloadIcon/></Button>
}

const PureDownloadInvoiceField = pure(DownloadInvoiceField)

PureDownloadInvoiceField.defaultProps = {
  label: "Invoice",
  source: undefined,
  sortable: false,
}

export default PureDownloadInvoiceField
