import React from "react"
import { Datagrid, DateField, TextField, ReferenceManyField } from "react-admin"
import Pagination from "../../Pagination"
import TextUrlField from "../../TextUrlField"

import IDField from "../../IDField"

export default ({ record, ...props }) => {
  return <ReferenceManyField
    addLabel={false}
    reference="partner-apps"
    target="partner"
    pagination={<Pagination/>}
    perPage={25}
    sort={{ field: "createdAt", order: "DESC" }}
    record={record}
    {...props}
  >
    <Datagrid rowClick="show">
      <IDField/>
      <DateField source="updatedAt" showTime/>
      <TextUrlField label="Playstore URL" source="playstoreURL" displaySource="name"/>
      <TextField source="integrationStatus" sortable={false}/>
      <TextField label="Average DAU" source="averageDAU" sortable={false}/>
      <TextField label="$ / 1k hourly active devices" source="pricePer1KHad" sortable={false}/>
    </Datagrid>
  </ReferenceManyField>
}
