import React from "react"
import { EditButton } from "react-admin"

import {
  CardActions,
  withStyles,
} from "@material-ui/core"
import Block from "./Block"
import ChangeUserPassword from "../../../../components/ChangeUserPassword"


const styles = () => ({
  actions: {
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
})

export default withStyles(styles)(({ classes, basePath, data }) => (
  data ? <CardActions className={classes.actions}>
    <Block user={data}/>
    <ChangeUserPassword record={data}/>
    <EditButton basePath={basePath} record={data}/>
  </CardActions> : null
))