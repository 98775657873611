import React, { useState } from "react"

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Typography,
  Tooltip,
} from "@material-ui/core"
import BlockIcon from "@material-ui/icons/Lock"
import UnblockIcon from "@material-ui/icons/LockOpen"

export const Block = ({ user, mutate, refreshView }) => {
  const [loading, setLoading] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  if (!user) {
    return null;
  }

  const toggleBlocked = () => {
    setLoading(true)
    mutate({
      variables: {
        id: user.id,
        isBlocked: !user.isBlocked,
      },
    }).catch((e) => {
      throw e
    }).finally(() => {
      setDialogOpen(false)
      setLoading(false)
      refreshView()
    })
  }

  return <>
    {user.isBlocked
      ?
      <Button
        color="secondary"
        onClick={toggleDialog}
      >
        <Typography color="inherit" style={{ marginRight: 0.5 + "rem" }}>UNBLOCK</Typography>
        <UnblockIcon/>
      </Button>
      :
      <Tooltip title="Block account">
        <Button
          color="primary"
          onClick={toggleDialog}
        >
          <BlockIcon/>
        </Button>
      </Tooltip>
    }
    <Dialog open={dialogOpen} onClose={toggleDialog} disableBackdropClick={loading}>
      <DialogTitle>
        {loading ? <CircularProgress size={16}/> :
          user.isBlocked
            ? "Are you sure you want to unlock this account"
            : "Are you sure you want to block this account"
        }
      </DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={toggleBlocked} disabled={loading}>Confirm</Button>
        <Button onClick={toggleDialog} disabled={loading}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </>
}