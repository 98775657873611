import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography, Card, CardContent,
} from "@material-ui/core"
import RejectIcon from "@material-ui/icons/Delete"
import ApproveIcon from "@material-ui/icons/Check"
import { withRouter } from "react-router-dom"
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import moment from "moment"

const LoadingDialog = ({ isOpen }) => (
  <Dialog open={isOpen} reason={true}>
    <DialogContent>
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress variant="indeterminate"/>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
)

const ConfirmDialog = ({ title, isOpen, onClose, onConfirm, onCancel, initialNotify }) => {
  const [notify, setNotify] = useState(initialNotify)
  const toggleNotify = () => {setNotify(!notify)}

  const handleClose = () => {
    setNotify(initialNotify)
    onClose()
  }

  const handleCancel = () => {
    setNotify(initialNotify)
    onCancel()
  }

  const handleConfirm = () => {
    onConfirm(notify)
  }

  return <Dialog open={isOpen} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <FormControlLabel
        control={
          <Checkbox
            checked={notify}
            onChange={toggleNotify}
            color="primary"
          />
        }
        label={<Typography>Notify partner?</Typography>}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>Confirm</Button>
      <Button onClick={handleCancel} color="primary">Cancel</Button>
    </DialogActions>
  </Dialog>
}

const Action = ({ icon: Icon, label, title, color, onConfirm, initialNotify, loading }) => {
  const [isOpen, setOpen] = useState(false)
  const toggleConfirmOpen = () => { setOpen(!isOpen) }

  return <>
    <LoadingDialog isOpen={loading}/>
    <ConfirmDialog
      title={title}
      isOpen={isOpen && !loading}
      onClose={toggleConfirmOpen}
      onCancel={toggleConfirmOpen}
      onConfirm={onConfirm}
      initialNotify={initialNotify}
    />
    <Button
      size="large"
      variant="contained"
      color={color}
      onClick={toggleConfirmOpen}
    >
      <Icon/> {label}
    </Button>
  </>
}

const approveEnhancer = compose(
  withRouter,
  graphql(gql`
      mutation ($id: Int!, $notify: Boolean!) {
          approvePartnerApp(id: $id, input: { notifyAccount: $notify }) {id}
      }
  `),
)

const Approve = approveEnhancer(({ partnerApp, mutate, history }) => {
  const [loading, setLoading] = useState(false)
  return <Action
    icon={ApproveIcon}
    label="Approve"
    title="Approve Partner App"
    color="secondary"
    initialNotify={true}
    loading={loading}
    onConfirm={(notify) => {
      setLoading(true)
      mutate(
        { variables: { id: partnerApp.id, notify } },
      ).then(() => {
        history.push("/partner-apps")
      }).catch(
        (e) => { throw e },
      ).finally(
        () => setLoading(false),
      )
    }}
  />
})

const rejectEnhancer = compose(
  withRouter,
  graphql(gql`
      mutation ($id: Int!, $notify: Boolean!) {
          rejectPartnerApp(id: $id, input: { notifyAccount: $notify }) {id}
      }
  `),
)

const Reject = rejectEnhancer(({ partnerApp, mutate, history }) => {
  const [loading, setLoading] = useState(false)
  return <Action
    partnerApp={partnerApp}
    icon={RejectIcon}
    label="Reject"
    title="Reject Partner App"
    color="primary"
    variant="contained"
    initialNotify={false}
    loading={loading}
    onConfirm={(notify) => {
      setLoading(true)
      mutate(
        { variables: { id: partnerApp.id, notify } },
      ).then(
        () => {
          history.push("/partner-apps")
        },
      ).catch(
        (e) => { throw e },
      ).finally(
        () => setLoading(false),
      )
    }}
  />
})

const DecisionMade = ({partnerApp}) => (
    <Card>
      <CardContent>
        <Typography color="secondary" variant="h5" align="left" style={{ textTransform: "capitalize" }}>{partnerApp.integrationStatus}</Typography>
        <Typography color="secondary" variant="subtitle1" align="left">at {moment(partnerApp.updatedAt).format("ll")}</Typography>
      </CardContent>
    </Card>
)

const DecisionPending = ({partnerApp}) => (
    <Grid container spacing={4}>
      <Grid item>
        <Approve partnerApp={partnerApp}/>
      </Grid>
      <Grid item>
        <Reject partnerApp={partnerApp}/>
      </Grid>
    </Grid>
)

export default ({ record: partnerApp }) => {
  if (!partnerApp) {
    return null
  }
  return (partnerApp && partnerApp.integrationStatus === "waiting") ? <DecisionPending partnerApp={partnerApp} /> : <DecisionMade partnerApp={partnerApp}/>
}
