import React from "react"
import { TextInput } from "react-admin"
import { required, email, minLength } from "react-admin"
import { Grid } from "@material-ui/core"
import createDecorator from "final-form-calculate"

export const autogenerateHandle = createDecorator({
  field: "account.company",
  updates: {
    "account.handle": (ignoredValue, allValues) =>
      String(ignoredValue || "")
        .toLowerCase()
        .replace(/[^0-9a-z]/g, ""),
  },
})

export const isLowercaseAlphanumeric = (name) => (value, values) =>
  !/^[a-z0-9]+$/g.test(value)
    ? `${name} must contain only lowercase letters and numbers`
    : undefined

export default () => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="account.company"
        label="Company"
        validate={[required()]}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="account.handle"
        label="Handle"
        validate={[required(), isLowercaseAlphanumeric("Handle")]}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <TextInput
        fullWidth
        source="account.firstName"
        label="First Name"
        validate={required()}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <TextInput
        fullWidth
        source="account.lastName"
        label="Last Name"
        validate={required()}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="account.email"
        label="Email"
        validate={[required(), email()]}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="account.password"
        type="password"
        label="Password"
        autoComplete="new-password"
        validate={[required(), minLength(8)]}
      />
    </Grid>
  </Grid>
)
