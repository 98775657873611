import React, { useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { AutocompleteInput, useDataProvider, useNotify, useRedirect } from "react-admin";
import { Form } from "react-final-form"
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
    {
        card: {
            paddingBottom: "0px!important",
            paddingTop: "0px",
        },
        input: {
            marginTop: 14,
            marginBottom: 14,
            '&>div.MuiInputBase-root': {
              backgroundColor: "transparent",
            }
        },
    },
);

const CardCustomerSearch= (props) => {
    const [query, setQuery] = useState("")
    const [choices, setChoices] = useState([])
    const classes = useStyles(props);
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const redirectTo = useRedirect();

    useEffect(() => {
        dataProvider
            .getList("customers", {pagination: { page: 1, perPage: 10 }, filter: { q: query } })
            .then(({ data } = {}) => {
                setChoices(data.map(customer => ({ id: customer.id, name: customer.account.displayName })))

            })
            .catch((err) => {
                notify(
                    err.message || "Something went wrong when fetching list of customers",
                )
            })
    }, [query, dataProvider, notify])

    const handleSubmit = () => redirectTo(`/customers/?filter=${encodeURIComponent(JSON.stringify({q: query}))}`)
    const redirectToCustomer = (accountId) => redirectTo(`/customers/${accountId}/show`)

    return (
        <Card>
            <CardContent className={classes.card} >
                <Form
                  onSubmit={handleSubmit}
                  render={({ handleSubmit }) => (
                     <form onSubmit={handleSubmit}>
                         <AutocompleteInput
                             fullWidth
                             resettable
                             label="Find customer"
                             source="q"
                             options={{
                                 InputProps: {
                                     value: query,
                                     onChange: (e) => setQuery(e.target.value),
                                     className: classes.input,
                                 },
                                 helperText: ""
                             }}
                             onChange={redirectToCustomer}
                             choices={choices}
                         />
                     </form>
                    )}
                />
            </CardContent>
        </Card>
    )
}

export default CardCustomerSearch
