import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email"/>
        <TextInput source="firstName"/>
        <TextInput source="lastName"/>
        <TextInput source="password"/>
      </SimpleForm>
    </Create>
  )
}
