import { GET_LIST, GET_ONE, GET_MANY, UPDATE, CREATE } from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"

const getOneQuery = gql`
  query Customer($id: Int!, $billingSort: BillingPeriodSliceSorter!) {
    customer(id: $id) {
      id
      handle
      createdAt
      updatedAt
      account {
        id
        isBlocked
        firstName
        lastName
        company
        displayName
        email
        phone
        country
        region
        postalCode
        locality
        streetAddress
        extendedAddress
        lastLogin
        lastActivity
        signup {
          id
          createdAt
          updatedAt
          minfraud {
            response {
              riskScore
              ipAddress {
                traits {
                  ipAddress
                }
                city {
                  names {
                    en
                  }
                }
                country {
                  names {
                    en
                  }
                }
              }
              creditCard {
                brand
                country
                issuer {
                  name
                }
              }
            }
          }
        }
      }
      braintreeCustomer {
        id
        braintreeURL
      }
      creditCard {
        createdAt
        cardType
        cardholderName
        issuingBank
      }
      contract {
        id
        status
        plan {
          id
          slug
          displayName
        }
        features {
          id
          feature
          unitPrice
          unit
          quantity
        }
        billingPeriods: billingPeriodSlice(sort: $billingSort) {
          items {
            id
            startDate
            endDate
            balance {
              id
              createdAt
              link
              quantity
              balance
              comment
            }
          }
          totalCount
        }
      }
    }
  }
`

const getOne = ({ params: { id } }) => ({
  query: getOneQuery,
  variables: { id, billingSort: { endDate: true, endDateDesc: true } },
  parseResponse: ({ data: { customer } }) => ({ data: customer }),
})

const getMany = ({ params: { ids } }) => {
  const variables = {
    ids: [...ids],
    limit: ids.length,
  }
  return {
    variables,
    query: gql`
      query($ids: [Int!], $limit: Int!) {
        customerSlice(limit: $limit, filter: { ids: $ids }) {
          items {
            id
            account {
              id
              email
              displayName
            }
          }
        }
      }
    `,
    parseResponse: ({
      data: {
        customerSlice: { items },
      },
    }) => ({
      data: items,
    }),
  }
}

const getListQuery = gql`
  query CustomerSlice(
    $limit: Int!
    $offset: Int!
    $filter: CustomerSliceFilter
    $sort: CustomerSliceSorter
  ) {
    slice: customerSlice(
      limit: $limit
      offset: $offset
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        handle
        createdAt
        updatedAt
        account {
          id
          role
          lastLogin
          isBlocked
          lastActivity
          email
          firstName
          lastName
          company
          displayName
        }
        contract {
          id
          status
        }
        customerDetails {
          subscriptionPlan {
            id
            slug
          }
          subscriptionStatus
          nextBillingDate
          bandwidth30Days
          lastProxyUsage
        }
        notes: customerNoteSlice(limit: 1, offset: 0) {
            items {
                id
                body
                externalLink
                createdAt
            }
        }
      }
      totalCount
    }
  }
`

const getList = ({ params }) => {
  const {
    pagination: { page, perPage },
    filter: { q: query, customerDetails, account },
  } = params
  let { sort } = params
  let filter = {
    role: "customer",
    query,
    ...(customerDetails || {}),
    ...(account || {}),
  }

  let variables = {
    filter,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: sort ? querySortHelper(sort) : undefined,
  }

  return {
    query: getListQuery,
    variables,
    parseResponse: (response) => ({
      data: response.data.slice.items,
      total: response.data.slice.totalCount,
    }),
  }
}

const createQuery = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      account {
        id
      }
    }
  }
`

const create = ({ params }) => {
  let {
    data: { account, features, plan, body = "", externalLink = "" },
  } = { ...params }
  const note = { body, externalLink }

  // clean up features
  features = features.filter((f) => f.active)
  features.forEach((f) => delete f.active)
  let input = {
    account,
    features,
    plan,
    note: body || externalLink ? note : undefined,
  }

  return {
    query: createQuery,
    variables: { input },
    parseResponse: (response) => ({
      data: response.data.createCustomer,
    }),
  }
}

const updateQuery = gql`
  mutation UpdateCustomer(
    $id: Int!
    $input: UpdateCustomerInput!
    $billingSort: BillingPeriodSliceSorter!
  ) {
    updateCustomer(id: $id, input: $input) {
      id
      handle
      createdAt
      updatedAt
      account {
        id
        isBlocked
        firstName
        lastName
        company
        displayName
        email
        phone
        country
        region
        postalCode
        locality
        streetAddress
        extendedAddress
        lastLogin
        lastActivity
        signup {
          id
          createdAt
          updatedAt
        }
      }
      braintreeCustomer {
        id
        braintreeURL
      }
      contract {
        id
        status
        plan {
          id
          slug
          displayName
        }
        features {
          id
          feature
          unitPrice
          unit
          quantity
        }
        billingPeriods: billingPeriodSlice(sort: $billingSort) {
          items {
            startDate
            endDate
            balance {
              id
              createdAt
              link
              quantity
              balance
              comment
            }
          }
          totalCount
        }
      }
    }
  }
`

const update = ({ params: { data } }) => {
  const {
    account: {
      id,
      firstName,
      lastName,
      company,
      email,
      phone,
      country,
      postalCode,
      region,
      locality,
      streetAddress,
      extendedAddress,
    },
    features,
    plan,
  } = data

  // clean up features
  let featuresInput = features.filter((f) => f.active)
  featuresInput.forEach((f) => delete f.active)

  let input = {
    account: {
      firstName,
      lastName,
      company,
      email,
      phone,
      country,
      postalCode,
      region,
      locality,
      streetAddress,
      extendedAddress,
    },
    plan,
    features: featuresInput,
  }

  return {
    query: updateQuery,
    variables: {
      id,
      input,
      billingSort: { endDate: true, endDateDesc: true },
    },
    parseResponse: (response) => ({
      data: response.data.updateCustomer,
    }),
  }
}

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_LIST:
      return getList({ params, resource })
    case GET_MANY:
      return getMany({ params, resource })
    case GET_ONE:
      return getOne({ params, resource })
    case UPDATE:
      return update({ params, resource })
    case CREATE:
      return create({ params })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}
