import React from "react"
import CheckIcon from "@material-ui/icons/Check"
import CheckIconIcon from "@material-ui/icons/Clear"
import { Typography } from "@material-ui/core"
import get from "lodash/get"

const IsActiveField = (props) => {
  const isActive = get(props.record, props.source, false)
  return (
    <Typography align="center">
      {isActive ? <CheckIcon /> : <CheckIconIcon />}
    </Typography>
  )
}

export default IsActiveField
