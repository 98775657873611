import get from "lodash/get"
import pure from "recompose/pure"
import React from "react"
import {formatFileSize} from "../format"

const FilesizeField = ({ record = {}, source, style = { display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }, className }) => {
  const value = get(record, source, 0)

  return (
    record ? <div style={style} className={className}>
      {formatFileSize(value)}
    </div> : null
  )
}

const PureFilesizeField = pure(FilesizeField)
export default PureFilesizeField