import React from "react"
import get from "lodash/get"
import { compose } from "recompose"
import SummaryBandwidth from "./SummaryBandwidth"

import {
  TextField,
  DateField,
} from "react-admin"

import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  withStyles,
} from "@material-ui/core"

import ShowIcon from "@material-ui/icons/RemoveRedEye"

import FullNameField from "../../FullNameField"
import IDField from "../../IDField"
import IPAddressField from "../components/IPAddressField"
import CreditCardField from "../components/CreditCardField"
import CustomerLastNoteField from "../components/CustomerLastNoteField"
import MinfraudCreditCardField from "../components/MinfraudCreditCardField"
import ContractDetails from "./ContractInfo"
import ServiceDetails from "./ServiceDetails"

const Summary = ({ classes, record, recordCallback }) => {
  if (!!recordCallback) {
    recordCallback(record)
  }

  if (!record) {
    return null
  }

  const { contract } = record
  if (!contract || !contract.billingPeriods || !contract.features) {
    return null
  }

  const braintreeCustomer = get(record, "braintreeCustomer")
  const {features, plan} = contract

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card elevation={0}>
          <CardContent style={{ paddingBottom: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={`${classes.tableHeader} ${classes.notesCell}`} colSpan={2}>Last note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.notesCell}>
                    <CustomerLastNoteField customerId={record.id} className={classes.notesField} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card elevation={0}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeader} colSpan={2}>Customer Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>
                    <IDField record={record}/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Proxy Handle</TableCell>
                  <TableCell>
                    <TextField record={record} source="handle"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <FullNameField record={record} source="account"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.company"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.email"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.phone"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.streetAddress"/>
                    <TextField record={record} source="account.extendedAddress"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.locality"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Postal Code</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.postalCode"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State / Region</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.region"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>
                    <TextField record={record} source="account.country"/>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created At</TableCell>
                  <TableCell>
                    <DateField record={record} source="createdAt"/>
                  </TableCell>
                </TableRow>
                {record.account.signup &&
                <>
                  <TableRow>
                    <TableCell>Signup details</TableCell>
                    <TableCell>
                      <a href={`/signups/${get(record, "account.signup.id")}/show`}>
                        <ShowIcon color="secondary"/>
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Signed Up At</TableCell>
                    <TableCell>
                      <DateField record={record} source="account.signup.createdAt"/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Approved At</TableCell>
                    <TableCell>
                      <DateField record={record} source="account.signup.updatedAt"/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Risk Score</TableCell>
                    <TableCell>
                      <TextField record={record} source="account.signup.minfraud.response.riskScore"/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IP Address</TableCell>
                    <TableCell>
                      <IPAddressField record={record} source="account.signup.minfraud.response.ipAddress"/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Credit Card</TableCell>
                    <TableCell>
                      <CreditCardField record={record} source="creditCard"/>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Minfraud CC info</TableCell>
                    <TableCell>
                      <MinfraudCreditCardField record={record} source="account.signup.minfraud.response.creditCard"/>
                    </TableCell>
                  </TableRow>
                </>
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card elevation={0}>
          <CardContent>
            <ContractDetails braintreeCustomer={braintreeCustomer} contract={contract}/>
          </CardContent>
        </Card>
        <Card elevation={0}>
          <CardContent>
            <ServiceDetails features={features} plan={plan}/>
          </CardContent>
        </Card>
        <SummaryBandwidth contract={contract}/>
      </Grid>
    </Grid>
  )
}

const styles = (theme) => ({
  tableHeader: {
    fontWeight: "bold",
    fontSize: 80 + "%",
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  bandwidthUsageIcon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  quotasTableCell: {
    textAlign: "right",
  },
  quotasTableCellWide: {
    textAlign: "right",
    minWidth: 4.5 + "rem",
  },
  quotasTableCellHeader: {
    fontWeight: "bold",
    fontSize: 80 + "%",
    textAlign: "left",
  },
  externalButton: {
    marginLeft: theme.spacing(-1),
    textTransform: "none",
    fontWeight: "normal",
  },
  externalIcon: {
    width: 16,
    marginRight: 8,
  },
  notesField: {
    whiteSpace: "pre-line",
  },
  notesCell: {
    border: "none",
  },
})

const enhance = compose(
  withStyles(styles),
)

export default enhance(Summary)
