import React from "react"
import {
  Edit as RAEdit,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  SaveButton,
} from "react-admin"
import { Grid, Button } from "@material-ui/core"
import { Link } from "react-router-dom"

import FullNameField from "../../FullNameField"

import CustomerCreate from "../components/CustomerCreate"
import ContractEdit from "../components/ContractEdit"
import identity from "lodash/identity"

export const Create = props => {
  return <CustomerCreate {...props}/>
}

const EditTitle = ({ record }) => record ? <FullNameField record={record} size={32}/> : null

const EditToolbar = ({ record, ...props }) => (
  <Toolbar record={record} {...props}>
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item>
        <SaveButton record={record} {...props}/>
      </Grid>
      <Grid item>
        <Button variant="outlined" component={Link} to={`/customers/${record.id}/show`}>Cancel</Button>
      </Grid>
    </Grid>
  </Toolbar>
)

export const Edit = (props) => {
  return (
    <RAEdit title={<EditTitle/>} undoable="false" {...props}>
      <TabbedForm redirect="show" toolbar={<EditToolbar/>}>
        <FormTab label="Contract">
          <ContractEdit/>
        </FormTab>
        <FormTab label="Contact Info">
          <Grid container spacing={2} style={{width: '100%'}}>
            <Grid item xs={6}>
              <TextInput label="ID" source="account.id" fullWidth disabled/>
            </Grid>
            <Grid item xs={6}>
              <TextInput source="handle" fullWidth disabled/>
            </Grid>
            <Grid item xs={6}>
              <TextInput label="First Name" source="account.firstName" fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextInput label="Last Name" source="account.lastName" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Email" source="account.email" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Company" source="account.company" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Phone" source="account.phone" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Postal Code" source="account.postalCode" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Country" source="account.country" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Region" source="account.region" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Locality" source="account.locality" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Street Address" source="account.streetAddress" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput label="Extended Address" source="account.extendedAddress" fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <TextInput multiline label="Notes" source="account.notes" parse={identity} fullWidth/>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </RAEdit>
  )
}
