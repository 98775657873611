import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE,
  DELETE,
  GET_MANY,
} from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"
import get from "lodash/get"
import { getMe } from "../../auth"

const getOneQuery = gql`
  query Staff($id: Int!) {
    user(id: $id) {
      id
      createdAt
      updatedAt
      lastLogin
      firstName
      lastName
      displayName
      email
      isBlocked
      account {
          id
          isBlocked
      }
    }
  }
`

const getOne = ({ params: { id } }) => ({
  query: getOneQuery,
  variables: { id },
  parseResponse: ({ data: { user } }) => ({ data: user }),
})

const getListQuery = gql`
  query StaffSlice($limit: Int!, $offset: Int!, $filter: UserSliceFilter, $sort: UserSliceSorter) {
    slice: staffSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      items {
        id
        createdAt
        updatedAt
        lastLogin
        email
        firstName
        lastName
        displayName
        isBlocked
        account {
            id
            isBlocked
        }
      }
      totalCount
    }
  }
`

const getList = ({ params }) => {
  const { pagination: { page, perPage }, filter: { q: query, account }, sort } = params
  let filter = { query, ...(account || {}), role: "staff" }
  let variables = {
    filter,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: sort ? querySortHelper(sort) : undefined,
  }

  return {
    query: getListQuery,
    variables,
    parseResponse: (response) => ({
      data: response.data.slice.items,
      total: response.data.slice.totalCount,
    }),
  }
}

const getMany = ({ params: { ids } }) => {
  const variables = {
    ids: [...ids],
    limit: ids.length,
  }
  return {
    variables,
    query: gql`
      query ($ids: [Int!], $limit: Int!) {
          staffSlice(limit: $limit, filter: { ids: $ids  }) {
              items {
                  id
                  createdAt
                  email
                  isBlocked
                  displayName
              }
          }
      }
    `,
    parseResponse: ({ data: { partnerSlice: { items } } }) => ({
      data: items,
    }),
  }
}

const create = ({params: {data}}) => {
  const {accountID} = getMe();
  return {
    query: gql`
        mutation CreateUser($create: CreateUserInput!) {
            createUser(create: $create) {
                id
            }
        }
    `,
    variables: {
      create: {
        accountID,
        firstName: get(data, "firstName"),
        lastName: get(data, "lastName"),
        email: get(data, "email"),
        password: get(data, "password"),
      },
    },
    parseResponse: ({ data: { createUser: data } }) => ({ data, total: 1 }),
  }
}

const update = ({params: {data}}) => {
  return {
    query: gql`
        mutation UpdateUser($id: Int!, $update: UpdateUserInput!) {
            updateUser(id: $id, update: $update) {
                id
                email
                firstName
                lastName
                lastLogin
                isBlocked
            }
        }
    `,
    variables: {
      id: get(data, "id"),
      update: {
        email: get(data, "email"),
        firstName: get(data, "firstName"),
        lastName: get(data, "lastName"),
        password: get(data, "password"),
      },
    },
    parseResponse: ({ data: { updateUser: data } }) => ({ data, total: 1 }),
  }
}

const deleteUser = ({params: {id}}) => {
  return {
    query: gql`
        mutation DeleteUser($id: Int!) {
            deleteUser(id: $id) {
                id
            }
        }
    `,
    variables: {id},
    parseResponse: ({ data: { deleteUser: data } }) => ({ data, total: 1 }),
  }
}

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_MANY:
      return getMany({ params, resource })
    case GET_LIST:
      return getList({ params, resource })
    case GET_ONE:
      return getOne({ params, resource })
    case UPDATE:
      return update({ params, resource })
    case CREATE:
      return create({ params, resource })
    case DELETE:
      return deleteUser({ params, resource })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}
