import { connect } from "react-redux"
import gql from "graphql-tag"
import { refreshView } from "ra-core"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "recompose"
import {Block} from "../../../../components/BlockAction"


const enhance = compose(
  connect(null, { refreshView }),
  graphql(gql`
    mutation UpdateUser($id: Int!, $isBlocked: Boolean!) {
      updateUser(id: $id, update: {
        isBlocked: $isBlocked,
      }){ id}
    }
  `),
)

export default enhance(Block)
