import React from "react"
import StackGrid from "react-stack-grid"
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin"
import { Grid, Card,CardHeader,CardContent, Typography, Tooltip, IconButton } from "@material-ui/core"
import ArrowForward from "@material-ui/icons/ArrowForward"
import CustomerField from "../../CustomerField"
import DisplayNameField from "../../DisplayNameField"
import Decision from "./Decision"
import CustomerLastNoteField from "../../customers/components/CustomerLastNoteField"
import {
  MinfraudScore,
  MinfraudBillingAddress,
  MinfraudCreditCard,
  MinfraudEmail,
  MinfraudIPAddress,
  MinfraudIPLocation,
} from "./Minfraud"
import Twilio from "./Twilio"
import { SubscriptionPlan } from "./SubscriptionPlan"

const Aside = ({ record }) => {
  if (!record) return null
  return (
    <div
      style={{
        marginLeft: 1 + "rem",
        marginRight: 1 + "rem",
        minWidth: 60 + "rem",
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="baseline"
        justifyContent="flex-start"
        direction="row"
      >
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader subheader="Last note"/>
            <CardContent>
              <Grid
              container
              spacing={2}
              alignItems="baseline"
              justifyContent="space-between"
              direction="row"
              >
                <Grid item>
                  {record.customer !== undefined &&
                    <Typography>
                      <CustomerLastNoteField customerId={record.customer.id}/>
                    </Typography>
                  }
                </Grid>
                <Grid item>
                  <Tooltip title="Go to Notes section...">
                    <IconButton 
                      aria-label="Go to Notes section..." 
                      href={
                        record.customer !== undefined && `/customers/${record.customer.id}/show/notes`}
                    >
                      <ArrowForward />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <MinfraudScore record={record} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Decision record={record} />
        </Grid>
        <Grid item xs={12}>
          <StackGrid
            columnWidth={50 + "%"}
            duration={0}
            gutterHeight={16}
            gutterWidth={16}
          >
            {record.twilio ? <Twilio record={record} /> : null}
            {record.minfraud && record.minfraud.retry === 0 ? (
              <MinfraudBillingAddress record={record} />
            ) : null}
            {record.minfraud && record.minfraud.retry === 0 ? (
              <MinfraudEmail record={record} />
            ) : null}
            {record.minfraud && record.minfraud.retry === 0 ? (
              <MinfraudIPAddress record={record} />
            ) : null}
            {record.minfraud && record.minfraud.retry === 0 ? (
              <MinfraudIPLocation record={record} />
            ) : null}
            {record.minfraud && record.minfraud.retry === 0 ? (
              <MinfraudCreditCard record={record} />
            ) : null}
            <SubscriptionPlan record={record} />
          </StackGrid>
        </Grid>
      </Grid>
    </div>
  )
}

export default (props) => {
  return (
  <>
  <Show aside={<Aside />} title={<DisplayNameField />} {...props}>
    <SimpleShowLayout>
      <CustomerField label="Customer" source="account" />
      <TextField label="First Name" source="firstName" />
      <TextField label="Last Name" source="lastName" />
      <TextField label="Company" source="company" />
      <TextField label="Email" source="email" />
      <DateField label="Signed up at" showTime source="createdAt" />
      <TextField label="Country" source="country" />
      <TextField label="Postal code" source="postalCode" />
      <TextField label="Region" source="region" />
      <TextField label="Locality" source="locality" />
      <TextField label="Street Address" source="streetAddress" />
      <TextField label="Extended Address" source="extendedAddress" />
      <TextField label="Use case description" source="useCaseDescription" />
    </SimpleShowLayout>
  </Show>
  </>
  )
}
