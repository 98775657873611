import React from "react"
import { Edit, TabbedForm, FormTab, TextInput } from "react-admin"
import FullNameField from "../FullNameField"

const Title = ({ record }) => record ? <FullNameField record={record} size={32}/> : null

export default (props) => (
  <Edit title={<Title/>} undoable={false} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Summary">
        <TextInput source="id" disabled/>
        <TextInput label="First Name" source="firstName"/>
        <TextInput label="Last Name" source="lastName"/>
        <TextInput label="Email" source="email"/>
      </FormTab>
    </TabbedForm>
  </Edit>
)
