import React from "react"
import { Typography, Card, CardHeader, CardContent } from "@material-ui/core"

export default ({ record  }) => {
  if (!record) {
    return null
  }
  return <Card>
    <CardHeader subheader="App statistics"/>
    <CardContent>
      <Typography variant="caption">Rating</Typography>
      <Typography paragraph>{record.starRating}</Typography>

      <Typography variant="caption">Reviews</Typography>
      <Typography paragraph>{record.reviews}</Typography>

      <Typography variant="caption">Installs</Typography>
      <Typography paragraph>{record.installs}</Typography>

      <Typography variant="caption">Size</Typography>
      <Typography paragraph>{record.size}</Typography>
    </CardContent>
  </Card>
}