import React, { useState } from "react"
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import CancelSubscriptionIcon from '@material-ui/icons/Pause';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Typography } from "@material-ui/core"
import {connect} from "react-redux"
import { refreshView } from "ra-core"
import { showNotification } from 'react-admin'
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"

const CancelContract = ({customer, mutate, refreshView, showNotification}) => {
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  if (!customer || !customer.contract) {
    return null;
  }

  const {contract: {status}} = customer;
  if (status.toLowerCase() === "suspended") {
    return null
  }

  const toggleCancel = () => {
    setLoading(true)
    mutate({
      variables: {
        id: customer.id,
      },
    }).catch((e) => {
      throw e
    }).finally(() => {
      setDialogOpen(false)
      setLoading(false)
      showNotification("Contract has been suspended. Refreshing..")
      setTimeout(() => {
        refreshView()
      }, 1000)
    })
  }

  return <>
    <Button
      color="primary"
      onClick={toggleDialog}
    >
      <Typography style={{ marginRight: 0.5 + "rem" }}>Suspend</Typography>
      <CancelSubscriptionIcon/>
    </Button>
    <Dialog open={dialogOpen} onClose={toggleDialog} disableBackdropClick={loading}>
      <DialogTitle>
        {loading ? <CircularProgress size={16}/> : "Are you sure you want to suspend customer?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          A customer with a suspended contract cannot use proxy. Billing system ignores all unpaid bandwidth used by suspended customer.<br/><br/>
          However, user can still purchase a new plan from user panel by paying instantly for it. Please consider blocking account to fully forbid access to the system.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={toggleCancel} disabled={loading}>Confirm</Button>
        <Button onClick={toggleDialog} disabled={loading}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </>
}

const enhance = compose(
  connect(null, {refreshView, showNotification}),
  graphql(gql`
      mutation SuspendContract ($id: Int!) {
          suspendCustomerContract(id: $id){
              id
          }
      }
  `),
)

export default enhance(CancelContract)
