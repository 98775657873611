import React, { useState } from "react"
import keyBy from "lodash/keyBy"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  CircularProgress,
  withStyles,
  Grid,
  Button,
} from "@material-ui/core"
import { withRouter } from "react-router-dom"
import { TextField, DatagridBody, DatagridHeaderCell } from "react-admin"
import get from "lodash/get"

import CustomerNoteEditDialog, {
  TYPE as EDIT_DIALOG_TYPE,
} from "../../../customerNotes/components/EditDialog"
import DisplayNameField from "./DisplayNameField"
import FilesizeField from "../../../FilesizeField"
import CustomerContractStatusField from "./CustomerContractStatusField"
import NoteContentField from "./NoteContentField"
import DateField from "./DateField"
import CurrencyField from "../../../CurrencyField"
import IDField from "../../../IDField"
import LastLoginField from "./LastLoginField"
import Typography from "@material-ui/core/Typography"
import { getRowStyle } from "../../../customers/styles"
import { compose } from "recompose"

const FILTER_24_HOURS = "24-hours"
const FILTER_7_DAYS = "7-days"
const FILTER_30_DAYS = "30-days"
const FILTER_1_YEAR = "1-year"

const COLUMNS_LAYOUT = {
  [FILTER_24_HOURS]: {
    HEADERS: [FILTER_24_HOURS, FILTER_7_DAYS, FILTER_30_DAYS, FILTER_1_YEAR],
    SOURCES: [
      "serpRequests24Hours",
      "requests24Hours",
      "bandwidth24Hours",
      "bandwidth7Days",
      "bandwidth30Days",
      "bandwidth1Year",
    ],
  },
  [FILTER_7_DAYS]: {
    HEADERS: [FILTER_7_DAYS, FILTER_30_DAYS, FILTER_1_YEAR, FILTER_24_HOURS],
    SOURCES: [
      "serpRequests7Days",
      "requests7Days",
      "bandwidth7Days",
      "bandwidth30Days",
      "bandwidth1Year",
      "bandwidth24Hours",
    ],
  },
  [FILTER_30_DAYS]: {
    HEADERS: [FILTER_30_DAYS, FILTER_1_YEAR, FILTER_24_HOURS, FILTER_7_DAYS],
    SOURCES: [
      "serpRequests30Days",
      "requests30Days",
      "bandwidth30Days",
      "bandwidth1Year",
      "bandwidth24Hours",
      "bandwidth7Days",
    ],
  },
  [FILTER_1_YEAR]: {
    HEADERS: [FILTER_1_YEAR, FILTER_24_HOURS, FILTER_7_DAYS, FILTER_30_DAYS],
    SOURCES: [
      "serpRequests1Year",
      "requests1Year",
      "bandwidth1Year",
      "bandwidth24Hours",
      "bandwidth7Days",
      "bandwidth30Days",
    ],
  },
}

const describeFilter = (f) => f.split("-").map(x => x.toLowerCase()).join(" ")

const DatagridBodyContent = ({
  setIdOfCustomerToCreateNote,
  columnsLayout,
  classes,
}) => [
  <IDField source="id" link="show" label="#" sortable />,
  <DisplayNameField
    key="account"
    source="account"
    label="Name"
    sortable={false}
  />,
  <TextField
    key="dyn1"
    source={`customerDetails.${columnsLayout.SOURCES[0]}`}
    label="SERP"
    className={classes.tableCellRight}
  />,
  <TextField
    key="dyn2"
    source={`customerDetails.${columnsLayout.SOURCES[1]}`}
    label="Request API"
    className={classes.tableCellRight}
  />,
  <FilesizeField
    key="dyn3"
    source={`customerDetails.${columnsLayout.SOURCES[2]}`}
    label="Total GB"
    className={classes.tableCellRight}
    style={{}}
  />,
  <FilesizeField
    key="dyn4"
    source={`customerDetails.${columnsLayout.SOURCES[3]}`}
    label="Total GB"
    className={classes.tableCellRight}
    style={{}}
  />,
  <FilesizeField
    key="dyn5"
    source={`customerDetails.${columnsLayout.SOURCES[4]}`}
    label="Total GB"
    className={classes.tableCellRight}
    style={{}}
  />,
  <FilesizeField
    key="dyn6"
    source={`customerDetails.${columnsLayout.SOURCES[5]}`}
    label="Total GB"
    className={classes.tableCellRight}
    style={{}}
  />,
  <CurrencyField
    key="invoices.items.0.totalAmount"
    source="invoices.items.0.totalAmount"
    label={"Last invoice"}
    sortable={false}
    className={classes.tableCellRight}
    style={{}}
  />,
  <CustomerContractStatusField
    key="customerDetails.subscriptionStatus"
    source="customerDetails.subscriptionStatus"
    label="Type"
    sortBy="subscriptionStatus"
  />,
  <NoteContentField
    key="notes.items.0"
    source="notes.items.0"
    label="Notes"
    setIdOfCustomerToCreateNote={setIdOfCustomerToCreateNote}
    headerClassname={classes.tableNotesCell}
    sortable={false}
  />,
  <DateField
    key="notes.items.0.createdAt"
    source="notes.items.0.createdAt"
    label="Note date"
    options={{ month: "short", day: "numeric" }}
    sortBy="lastNoteDate"
  />,
  <DateField
    key="customerDetails.lastProxyUsage"
    source="customerDetails.lastProxyUsage"
    label="Data usage"
    options={{ month: "short", day: "numeric" }}
  />,
  <LastLoginField
    key="account.lastLogin"
    source="account.lastLogin"
    label="Last login"
    options={{ month: "short", day: "numeric" }}
    sortBy="lastLoginOrCreatedAt"
  />,
]

const ActiveCustomersGrid = ({
  title,
  data,
  setSort,
  classes,
  currentSort,
  refetch,
  location,
  history
}) => {
  const params = new URLSearchParams(location.search);
  const layout = get(COLUMNS_LAYOUT, params.get('layout'), COLUMNS_LAYOUT[FILTER_24_HOURS])
  const [columnsLayout, setColumnsLayout] = useState(layout)
  const [idOfCustomerToCreateNote, setIdOfCustomerToCreateNote] = useState(null)

  const getLayoutChangeHandler = (nextLayout) => () => {
    setColumnsLayout(COLUMNS_LAYOUT[nextLayout])
    params.set('layout', nextLayout)
    history.push(location.pathname + "?" + params.toString())
  }

  const closeCreateNoteDialog = () => {
    refetch()
    setIdOfCustomerToCreateNote(null)
  }

  if (!data) {
    return null
  }

  const { loading, error, customerSlice } = data
  if (loading || error || !customerSlice) {
    return <CircularProgress />
  }

  const { items } = customerSlice
  const bodyContent = DatagridBodyContent({
    setIdOfCustomerToCreateNote,
    columnsLayout,
    classes,
  })

  return (
    <React.Fragment>
      <Grid container className={classes.tableTop}>
        <Grid><Typography>{title}</Typography></Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableEmptyCell}/>
            <TableCell className={classes.tableEmptyCell}/>
            {columnsLayout.HEADERS.map((h, idx) => <TableCell
              key={idx}
              className={`${classes.tableHeader} ${classes.tableCell}`}
              colSpan={idx === 0 ? 3 : 1}
            >
              <Button
                variant="text"
                className={classes.tableHeaderBtn}
                onClick={getLayoutChangeHandler(h)}>
                {describeFilter(h)}
              </Button>
            </TableCell>)}
          </TableRow>
          <TableRow>
            {bodyContent.map((field, index) => (
              <DatagridHeaderCell
                key={index}
                currentSort={currentSort}
                updateSort={setSort}
                className={`${classes.tableHeader} ${classes.tableCell} ${field
                  .props.headerClassname || ""}`}
                field={field}
                isSorting={
                  currentSort.field ===
                  (field.props.sortBy || field.props.source)
                }
              />
            ))}
          </TableRow>
        </TableHead>
        <DatagridBody
          classes={{
            rowCell: classes.tableCell,
          }}
          data={keyBy(items, "id")}
          ids={items.map(({ id }) => id)}
          selectedIds={[]}
          resource="customers"
          basePath="/customers"
          rowStyle={getRowStyle}
        >
          {bodyContent}
        </DatagridBody>
      </Table>

      {idOfCustomerToCreateNote && (
        <CustomerNoteEditDialog
          type={EDIT_DIALOG_TYPE.CREATE}
          record={{}}
          customerID={idOfCustomerToCreateNote}
          onClose={closeCreateNoteDialog}
          previewMode
        />
      )}
    </React.Fragment>
  )
}

const styles = theme => ({
  tableTop: {
    marginBottom: theme.spacing(2),
  },
  tableEmptyCell: {
    border: "none",
  },
  tableHeader: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
  tableCell: {
    padding: theme.spacing(0.5),
  },
  tableCellRight: {
    display: "block",
    textAlign: "right",
  },
  tableNotesCell: {
    width: "250px",
  },
  tableHeaderBtn: {
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor: "initial"
    },
  }
})


const enhance = compose(
  withStyles(styles),
  withRouter,
)

export default enhance(ActiveCustomersGrid)
