import React from "react"
import { TableCell, TableRow } from "@material-ui/core"
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import {
  FEATURE_RESIDENTIAL_OVERAGE,
  FEATURE_DATACENTER_OVERAGE,
  FEATURE_MOBILE_OVERAGE,
  FEATURE_RESIDENTIAL_COMMITMENT, FEATURE_DATACENTER_COMMITMENT, FEATURE_MOBILE_COMMITMENT,
} from "../constants"
import { getFeatureDescription } from "../components/features/utils"

const VolumeFeatureRow = ({ feature }) => {
  return <TableRow>
    <TableCell>{getFeatureDescription(feature.feature)}</TableCell>
    <TableCell>${feature.unitPrice}</TableCell>
    <TableCell>{feature.quantity}</TableCell>
    <TableCell>{feature.unit}</TableCell>
    <TableCell><AllInclusiveIcon/></TableCell>
  </TableRow>
}

const QuotaFeatureRow = ({feature}) => {
  return <TableRow>
    <TableCell>{getFeatureDescription(feature.feature)}</TableCell>
    <TableCell>${feature.unitPrice}</TableCell>
    <TableCell>{feature.quantity}</TableCell>
    <TableCell>{feature.unit}</TableCell>
    <TableCell>${feature.quantity * feature.unitPrice}</TableCell>
  </TableRow>
}

const RegularFeatureRow = ({feature}) => {
  return <TableRow>
    <TableCell>{getFeatureDescription(feature.feature)}</TableCell>
    <TableCell>${feature.unitPrice}</TableCell>
    <TableCell>{feature.quantity}</TableCell>
    <TableCell>{feature.unit}</TableCell>
    <TableCell>-</TableCell>
  </TableRow>
}

const FeatureInfoRow = ({ feature }) => {
  switch (feature.feature) {
    case FEATURE_RESIDENTIAL_OVERAGE:
    case FEATURE_DATACENTER_OVERAGE:
    case FEATURE_MOBILE_OVERAGE:
      return <VolumeFeatureRow feature={feature}/>
    case FEATURE_RESIDENTIAL_COMMITMENT:
    case FEATURE_DATACENTER_COMMITMENT:
    case FEATURE_MOBILE_COMMITMENT:
      return <QuotaFeatureRow feature={feature}/>
    default:
        return <RegularFeatureRow feature={feature}/>
  }
}

export default FeatureInfoRow