import React, { useState } from "react"
import {
  AreaChart,
  ChartContainer,
  ChartRow,
  Charts,
  Legend,
  Resizable,
  YAxis,
  styler,
} from "react-timeseries-charts"
import { Index, TimeSeries } from "pondjs"
import {formatFileSize} from "../format"
import moment from "moment"
import { compose } from "recompose"
import { withStyles, Typography, Grid } from "@material-ui/core"

const colors = {
  datacenter: "#ECAB29",
  mobile: "#E4373E",
  residential: "#38B2AA",
  selected: "#3F4B3B",
}

const enhance = compose(
  withStyles(() => ({
    cellLeft: {
      fontFamily: "monospace",
      width: 28 + "%",
    },
    cellRight: {
      fontFamily: "monospace",
      textAlign: "right",
      width: 18 + "%",
    },
  })),
)

export default enhance(({ classes, series: data, start, end }) => {

  const axis = []
  const hours = new Map()
  for (let m = start.clone().utc(); m.isSameOrBefore(end); m.add(1, "hour")) {
    let key = Index.getIndexString("1h", m)
    axis.push(key)
    hours.set(key, {
      datacenter: 0,
      mobile: 0,
      residential: 0,
    })
  }

  data.forEach(({ time, link, value }) => {
    let key = Index.getIndexString("1h", moment(time).utc().toDate())
    let item = hours.get(key)
    item[link] = Number(value)
  })

  const series = new TimeSeries({
    name: "Bandwidth",
    columns: ["index", "datacenter", "mobile", "residential", "sum"],
    points: axis.map((key) => {
      let item = hours.get(key)
      return [key, item.datacenter, item.mobile, item.residential, item.datacenter + item.mobile + item.residential]
    }),
  })

  const style = styler([
    { key: "datacenter", color: colors.datacenter, selected: colors.selected },
    { key: "mobile", color: colors.mobile, selected: colors.selected },
    { key: "residential", color: colors.residential, selected: colors.selected },
  ])

  const [tracker, setTracker] = useState()

  const values = tracker
    ? {
      datacenter: series.at(series.bisect(tracker)).get("datacenter"),
      mobile: series.at(series.bisect(tracker)).get("mobile"),
      residential: series.at(series.bisect(tracker)).get("residential"),
    }
    : { datacenter: null, mobile: null, residential: null }

  const LegendItem = ({ label, value }) => (
    <Grid container spacing={1}>
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography>{value || value === 0 ? formatFileSize(value) : null}</Typography>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Resizable>
        <ChartContainer
          timeRange={series.range()}
          format={(d) => (moment(d).format("MMM D HH:mm"))}
          onTrackerChanged={setTracker}
          trackerPosition={tracker}
        >
          <ChartRow height="300">
            <YAxis
              id="bandwidth"
              min={0}
              max={series.max("sum")}
              type="linear"
              visible={true}
              showGrid={true}
              width={40}
            />
            <Charts>
              <AreaChart
                axis="bandwidth"
                stack={true}
                series={series}
                columns={{
                  up: ["mobile", "datacenter", "residential"],
                }}
                style={style}
              />
            </Charts>
            <YAxis
              id="bandwidth"
              min={0}
              max={series.max("sum")}
              type="linear"
              visible={true}
              showGrid={true}
              width={40}
            />
          </ChartRow>
        </ChartContainer>
      </Resizable>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Legend
            type="swatch"
            stack={true}
            style={style}
            categories={[
              { key: "datacenter", label: <LegendItem label="Datacenter" value={values.datacenter}/> },
              { key: "residential", label: <LegendItem label="Residential" value={values.residential}/> },
              { key: "mobile", label: <LegendItem label="Mobile" value={values.mobile}/> },
            ]}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography color="textSecondary">
            {tracker
              ? moment(tracker).utc().startOf("hour").format("lll")
              : <>{` `}</>
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  )
})