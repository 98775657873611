import React from "react"
import { TextField, Datagrid, DateField, ReferenceManyField } from "react-admin"
import Pagination from "../../Pagination"
import FilesizeField from "../../FilesizeField"

const Table = record => (
  <Datagrid style={{textAlign: "left"}}>
    <TextField style={{textTransform: "capitalize"}} source="link" sortable={false}/>
    <DateField source="createdAt" showTime label="Date created" sortable={false}/>
    <FilesizeField source="quantity" label="Quantity" sortable={false}/>
    <FilesizeField source="balance" label="Balance" sortable={false}/>
    <TextField source="comment" sortable={false}/>
  </Datagrid>
)

export default ({ record, recordCallback, ...props }) => {
  if (!!recordCallback) {
    recordCallback(record)
  }
  return <ReferenceManyField
    addLabel={false}
    reference="balance-transactions"
    target="customer"
    pagination={<Pagination/>}
    perPage={25}
    record={record}
    {...props}
  >
    {Table(record)}
  </ReferenceManyField>
}
