import gql from "graphql-tag"
import { GET_LIST } from "ra-core"

export default ({ queryType, params }) => {
  switch (queryType) {
    case GET_LIST:
      return getList(params)
    default:
      throw new Error(`not implemented: ${queryType}`)
  }
}

const getList = ({ pagination: { page, perPage }, sort }) => {
  return {
    variables: {
      limit: perPage,
      offset: (page - 1) * perPage,
      sorter: sort,
    },
    query: gql`
        query WorkersByCountrySeries($limit: Int!, $offset: Int!, $sorter: WorkersByCountrySorter) {
            workersByCountrySeries(limit: $limit, offset: $offset, sorter: $sorter) {
               id: country
               country
               workers
            }
        }
    `,
    parseResponse: ({ data: { workersByCountrySeries } }) => ({ data: workersByCountrySeries }),
  }
}


