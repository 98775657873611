import React from "react"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  SelectInput,
} from "react-admin"
import get from "lodash/get"
import Pagination from "../Pagination"
import IDField from "../IDField"
import CustomerField from "../CustomerField"
import BulkActionButtons from "./BulkActionButtons"
import TextWithTooltipField from "../TextWithTooltipField"
import { REQUESTED_FEATURE_DISPLAY_NAME } from "./constants"

const F = ({ ...props }) => (
  <Filter {...props} variant="standard">
    <TextInput alwaysOn resettable source="query" label="Search" />
    <SelectInput
      alwaysOn
      allowEmpty
      resettable
      source="disposition"
      label="Disposition"
      choices={[
        { id: "pending", name: "Pending" },
        { id: "requested", name: "Requested" },
        { id: "acknowledged", name: "Acknowledged" },
      ]}
    />
    <ReferenceInput
      alwaysOn
      resettable
      source="customer"
      label="Customer"
      reference="customers"
    >
      <AutocompleteInput
        allowEmpty
        fullWidth
        resettable
        optionText="account.displayName"
        optionValue="id"
      />
    </ReferenceInput>
  </Filter>
)

const RequestedFeature = (props) => {
  const feature = get(props, "record.feature", "")
  return get(REQUESTED_FEATURE_DISPLAY_NAME, feature, feature)
}

const RequestedFeatureStatus = (props) => {
  const feature = get(props, "record.feature")
  if (!feature) {
    return "-"
  }

  const status = get(
    props,
    ["record", "customer", "contract", "features"],
    [],
  ).find((f) => f.feature === feature)

  return status ? "Enabled" : "Disabled"
}

export default (props) => {
  return (
    <List
      title="Access Requests"
      bulkActionButtons={<BulkActionButtons />}
      pagination={<Pagination />}
      perPage={25}
      sort={{ field: "createdAt", order: "ASC" }}
      filters={<F />}
      {...props}
    >
      <Datagrid>
        <IDField />
        <CustomerField
          source="customer.account"
          label="Customer"
          sortable={false}
        />
        <RequestedFeature label="Requested feature" />
        <RequestedFeatureStatus label="Current status" />
        <TextField
          style={{ textTransform: "capitalize" }}
          source="disposition"
        />
        <TextWithTooltipField source="comment" />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  )
}
