import React from "react"
import { Datagrid, DateField, TextField, ReferenceManyField } from "react-admin"
import DownloadInvoiceField from "../../DownloadInvoiceField"
import Pagination from "../../Pagination"
import BraintreeLinkField from "../../BraintreeLinkField"
import CurrencyField from "../../CurrencyField"
import IDField from "../../IDField"
import BraintreeTransactionRefundsOrRefundedByField from "../../BraintreeTransactionRefundsOrRefundedByField"

export default ({ record, ...props }) => {
  return <ReferenceManyField
    addLabel={false}
    reference="transactions"
    target="customer"
    pagination={<Pagination/>}
    perPage={25}
    sort={{ field: "createdAt", order: "DESC" }}
    record={record}
    {...props}
  >
    <Datagrid>
      <IDField source="id"/>
      <DateField source="createdAt" showTime/>
      <TextField source="type" sortable={false}/>
      <TextField source="status" sortable={false}/>
      <CurrencyField source="amount" sortable={false}/>
      <BraintreeTransactionRefundsOrRefundedByField/>
      <BraintreeLinkField label="Braintree"/>
      <DownloadInvoiceField source="invoice" label="Invoice"/>
    </Datagrid>
  </ReferenceManyField>
}
