import get from "lodash/get";
import { getGTag, getUetq } from "./gtag";

export const countConversion = (conv, plan, account, signupID, callback) => {
  if (get(account, `conversion.source`, '') === 'google') {
    return countGoogle(conv, plan, account, signupID, callback)
  }

  if (get(account, `conversion.source`, '') === 'bing') {
    return countBing(conv, plan, account, signupID, callback)
  }

  return callback()
}

const countGoogle = (conv, plan, account, signupID, callback) => {
  const gtag = getGTag()
  return gtag(`event`, `signup_approved`, {
    event_category: `Sign-up`,
    event_label: `Sign-up`,
    gclid: get(account, `conversion.clkid`, ''),
    keyword: get(account, `conversion.keyword`, ''),
    campaign: get(account, `conversion.campaign`, ''),
    event_callback: () => {
      const transactionID = signupID;
      gtag(`event`, `purchase`, {
        transaction_id: `T${transactionID}`,
        value: plan.price,
        currency: `USD`,
        items: [
          {
            id: `I${transactionID}`,
            name: plan.slug,
            category: `proxy`,
            quantity: 1,
            price: plan.price,
          },
        ],
        event_timeout: 5000,
        event_callback: callback,
      })
    },
  })
}
const countBing = (conv, plan, account, signupID, callback) => {
  const uetq = getUetq()
  uetq.push(`event`, `signup_approved`, {
    event_category: `Sign-up`,
    event_label: `Sign-up`,
    value: plan.price,
    event_timeout: 5000,
    msclkid: get(account, `conversion.clkid`, ''),
    keyword: get(account, `conversion.keyword`, ''),
    campaign: get(account, `conversion.campaign`, ''),
    event_callback: () => {
      uetq(`event`, `conversion`, {
        send_to: `AW-756723432/FATgCKj17JcBEOjd6ugC`,
        value: plan.price,
        currency: `USD`,
        event_timeout: 5000,
        event_callback: () => {
          const transactionID = signupID;
          uetq(`event`, `purchase`, {
            transaction_id: `T${transactionID}`,
            value: plan.price,
            currency: `USD`,
            items: [
              {
                id: `I${transactionID}`,
                name: plan.name,
                category: `proxy`,
                quantity: 1,
                price: plan.price,
              },
            ],
            event_timeout: 5000,
            event_callback: callback,
          })
        },
      })
    },
  })
}
