import React from 'react'
import { List, Datagrid, Filter, SelectInput, SearchInput, Pagination, DateField, TextField } from "react-admin"

import IDField from "../IDField"
import DisplayNameField from "../DisplayNameField"
import BooleanYesNoField from "../BooleanYesNoField"

export const StaffFilter = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn/>
    <SelectInput source="isBlocked" label="Is Blocked" choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ]}/>
  </Filter>
)

const StaffPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

export default ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<StaffFilter/>}
    pagination={<StaffPagination/>}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <IDField sortable={true}/>
      <DisplayNameField/>
      <TextField source="email" label="Email" addLabel/>
      <BooleanYesNoField source="isBlocked" label="Blocked"/>
      <DateField label="Last Login" source="lastLogin"/>
      <DateField label="Created" source="createdAt"/>
    </Datagrid>
  </List>
)