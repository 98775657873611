import React from "react"
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin"
import { REQUESTED_FEATURE, REQUESTED_FEATURE_DISPLAY_NAME } from "./constants"
import { Typography } from "@material-ui/core"

export default ({ customer, title, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="show" variant="standard" margin="normal">
      {title && (
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      )}
      {!customer && (
        <ReferenceInput
          label="Customer"
          source="customer"
          reference="customers"
          perPage={10000}
          required
        >
          <AutocompleteInput
            allowEmpty
            fullWidth
            resettable
            optionText="account.displayName"
            optionValue="id"
          />
        </ReferenceInput>
      )}
      {customer && (
        <AutocompleteInput
          source="customer"
          optionText="account.displayName"
          optionValue="id"
          choices={[customer]}
          initialValue={customer.id}
          disabled
        />
      )}
      <SelectInput
        source="feature"
        required
        choices={Object.values(REQUESTED_FEATURE).map((feature) => ({
          id: feature,
          name: REQUESTED_FEATURE_DISPLAY_NAME[feature],
        }))}
      />
      <TextInput source="comment" />
    </SimpleForm>
  </Create>
)
