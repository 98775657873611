import gql from "graphql-tag"
import { GET_MANY_REFERENCE } from "ra-core"

export default ({ queryType, params }) => {
  switch (queryType) {
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    default:
      throw new Error(`not implemented: zones ${queryType}`)
  }
}

const getManyReference = ({ target, id, pagination: { page, perPage } }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
  }
  if (target === "customer") {
    return getManyReferenceForCustomer({ ...variables })
  } else {
    throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = (variables) => {
  return {
    query: gql`
        query GetCustomerZones($id: Int!, $limit: Int!, $offset: Int!) {
            customer(id: $id) {
                zoneSlice(limit: $limit, offset: $offset) {
                    total: totalCount,
                    data: items {
                        id
                        createdAt
                        updatedAt
                        name
                        password
                        isActive

                        quotaDatacenter
                        quotaMobile
                        quotaResidential
                    }
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { customer: { zoneSlice: { data, total } } } }) => ({
      data,
      total,
    }),
  }
}