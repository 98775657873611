import React from "react"
import { Link } from "react-admin"
import { compose, pure } from "recompose"
import { NumberField } from "react-admin"
import { withStyles } from "@material-ui/core"
import get from "lodash/get"

const PlainIDField = ({ record, source, classes, ...props }) => {
  return (
    <NumberField
      source={source}
      label={props.label}
      sortable={props.sortable}
      options={{
        useGrouping: false,
        minimumIntegerDigits: 5,
        maximumFractionDigits: 0,
      }}
      className={classes.number}
      record={record}
    />
  )
}

const IDField = ({ record, source, resource, link, classes, ...props }) => {
  if (!record) {
    return null
  }
  const field = (
    <PlainIDField
      record={record}
      source={source}
      classes={classes}
      resource={resource}
      {...props}
    />
  )
  if (!link) {
    return field
  }

  return <Link
    target="_blank"
    rel="noopener noreferrer"
    to={`${resource}/${get(record, source)}/${link}`}
  >{field}</Link>
}

const enhance = compose(
  pure,
  withStyles((theme) => ({
    number: {
      fontFamily: "Monaco, 'Courier New', monospace",
      fontSize: 0.7 + "rem",
      color: theme.palette.secondary.main,
    },
  })),
)

const PureIDField = enhance(IDField)

PureIDField.defaultProps = {
  label: "#",
  source: "id",
  sortable: false,
}

export default PureIDField
