import gql from "graphql-tag"
import {
  GET_ONE,
  GET_MANY,
  GET_LIST,
  UPDATE,
  CREATE,
  DELETE,
  DELETE_MANY,
} from "ra-core"
import get from "lodash/get"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_ONE:
      return getOne({ ...params })
    case GET_MANY:
      return getMany({ ...params })
    case GET_LIST:
      return getList({ ...params })
    case DELETE:
      return remove({ params, resource })
    case DELETE_MANY:
      return removeMany({ params, resource })
    case UPDATE:
      return update({ params, resource })
    case CREATE:
      return create({ params, resource })
    default:
      throw new Error(`not implemented: ${queryType} ${get(resource, "name")}`)
  }
}

const getOne = ({ id }) => {
  return {
    variables: { id },
    query: gql`
      query PremiumDomain($id: Int!) {
        premiumDomain(id: $id) {
          id
          name
          category
          isActive
        }
      }
    `,
    parseResponse: ({ data: { premiumDomain } }) => ({
      data: premiumDomain,
    }),
  }
}

const getMany = ({ ids }) => {
  const variables = {
    ids: [...ids],
    limit: ids.length,
  }
  return {
    variables,
    query: gql`
      query PremiumDomainListRef($ids: [Int!], $limit: Int!) {
        premiumDomainSlice(
          limit: $limit
          filter: { ids: $ids, isActive: true }
        ) {
          items {
            id
            name
          }
        }
      }
    `,
    parseResponse: ({
      data: {
        premiumDomainSlice: { items },
      },
    }) => ({
      data: items,
    }),
  }
}

const getList = ({
  pagination: { page, perPage },
  filter: { query, isActive, category },
  sort,
}) => {
  return {
    variables: {
      limit: perPage,
      offset: perPage * (page - 1),
      filter: { isActive, query, category },
      sort: querySortHelper(sort),
    },
    query: gql`
      query PremiumDomainList(
        $limit: Int!
        $offset: Int!
        $filter: PremiumDomainSliceFilter
        $sort: PremiumDomainSliceSorter
      ) {
        premiumDomainSlice(
          limit: $limit
          offset: $offset
          filter: $filter
          sort: $sort
        ) {
          items {
            id
            name
            category
            isActive
          }
          totalCount
        }
      }
    `,
    parseResponse: ({
      data: {
        premiumDomainSlice: { items, totalCount },
      },
    }) => {
      return {
        data: items || [],
        total: totalCount,
      }
    },
  }
}

const update = ({ params: { data = {} } }) => {
  const { id, name, category, isActive } = data
  return {
    query: gql`
      mutation UpdatePremiumDomain(
        $id: Int!
        $input: UpdatePremiumDomainInput!
      ) {
        updatePremiumDomain(id: $id, input: $input) {
          id
          name
          category
          isActive
        }
      }
    `,
    variables: {
      id,
      input: {
        name,
        category,
        isActive,
      },
    },
    parseResponse: (response) => ({
      data: response.data.updatePremiumDomain,
    }),
  }
}

const create = ({ params: { data = {} } }) => {
  const { name, category, isActive } = data
  return {
    query: gql`
      mutation CreatePremiumDomain($input: CreatePremiumDomainInput!) {
        createPremiumDomain(input: $input) {
          id
          name
          category
          isActive
        }
      }
    `,
    variables: {
      input: {
        name,
        category,
        isActive,
      },
    },
    parseResponse: (response) => ({
      data: response.data.createPremiumDomain,
    }),
  }
}

const remove = ({ params }) => {
  return {
    query: gql`
      mutation DeletePremiumDomain($input: Int!) {
        deletePremiumDomain(id: $input)
      }
    `,
    variables: {
      input: params.id,
    },
    parseResponse: (response) => ({
      data: {},
    }),
  }
}

const removeMany = ({ params }) => {
  return {
    query: gql`
      mutation DeletePremiumDomainMany($input: [Int!]!) {
        deletePremiumDomainMany(ids: $input)
      }
    `,
    variables: {
      input: params.ids,
    },
    parseResponse: (response) => ({
      data: {},
    }),
  }
}

const querySortHelper = ({ field, order }) => {
  return {
    [field]: true,
    [field + "Desc"]: order === "DESC",
  }
}
