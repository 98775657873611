import React, { useState } from "react"
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import IssueKeyButton from "@material-ui/icons/VpnKey"
import IconCopyToClipboard from '@material-ui/icons/VpnKey';
import Tooltip from "@material-ui/core/Tooltip"
import { connect } from "react-redux"
import {
  Button,
  Dialog,
  Grid,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Input,
  DialogActions,
} from "@material-ui/core"
import { showNotification } from "react-admin"
import copy from "copy-to-clipboard"

const IssueApiToken = ({ account, mutate, showNotification }) => {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState(null)

  if (!account) {
    return null
  }

  const issueToken = () => {
    setLoading(true)
    mutate({
      variables: {
        id: account.id,
      },
    }).then(({ data: { token } }) => {
      setToken(token)
    }).catch((e) => {
      throw e
    }).finally(() => {
      setLoading(false)
    })
  }

  const copyToClipboard = () => {
    copy(token)
    showNotification("Token has been copied into clipboard")
  }

  const closeDialog = () => {
    setToken(null)
  }

  return <>
    <Tooltip title="Issue API token">
      <span>
        <Button
          color="primary"
          onClick={issueToken}
          disabled={loading}
        >
          <IssueKeyButton/>
        </Button>
      </span>
    </Tooltip>
    <Dialog open={!!token} onClose={closeDialog}>
      <DialogTitle>
        Please copy API Token before closing this dialog
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid container>
            <Input
              readOnly={true}
              style={{ width: "100%" }}
              value={token}
              startAdornment={
                <InputAdornment position="start">
                  <IconCopyToClipboard style={{ cursor: "pointer" }} onClick={copyToClipboard}/>
                </InputAdornment>
              }/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  </>
}


const enhance = compose(
  connect(null, { showNotification }),
  graphql(gql`
      mutation IssueAPIToken ($id: Int!) {
          token: issueApiToken(id: $id)
      }
  `),
)
export default enhance(IssueApiToken)
