import gql from "graphql-tag"


export const updateAccount = (id, input) => {
  return {
    query: gql`
        mutation ($id: Int!, $input: UpdateAccountInput) {
            updateAccount(id: $id, input: $input) {
                id
            }
        }
    `,
    variables: {
      id, input: input,
    },
    parseResponse: (response) => ({
      data: response.data.updateAccount,
    }),
  }
}

export const create = ({ params: { data } }) => {
  const input = { ...data, notifyAccount: false, notifyCreator: false }
  return {
    query: gql`
        mutation ($input: CreateAccountInput) {
            createAccount(input: $input) {
                id
            }
        }
    `,
    variables: { input: input },
    parseResponse: (response) => ({
      data: response.data.createAccount,
    }),
  }
}