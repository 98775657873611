import React from "react"
import { Query } from "@apollo/client/react/components"
import moment from "moment"
import gql from "graphql-tag"
import {formatFileSize} from "../../../format"
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableHead,
  withStyles,
} from "@material-ui/core"
import { compose } from "recompose"
import UsageChart from "../../../components/UsageChart"
import { useQuery } from "@apollo/client";

const query = gql`
  query ($account: String, $start: Time, $end: Time) {
    series: bandwidthSeries(
      filter: {account: $account, start: $start, end: $end}
      grouper: {time: true, link: true}
    ) {
      time
      link
      value
    }
  }
`

const enhance = compose(
  withStyles((theme) => ({
    right: {
      textAlign: "right",
    },
  })),
)

export default enhance(({ classes, record, recordCallback }) => {
  if (!record) {
    return null
  }
  if (!!recordCallback) {
    recordCallback(record)
  }
  const end = moment().utc()
  const start = end.clone().subtract(1, "month")
  const { loading, error, data  } = useQuery(query, {
    variables: { account: record.handle, start, end }
  });
  if (!!loading || !!error) {
    return null
  }

  const { series } = data;
  let rows = new Map()
  let totals = {
    datacenter: 0,
    mobile: 0,
    residential: 0,
  }

  for (let m = end.clone().startOf("day"); m.isSameOrAfter(start); m.subtract(1, "day")) {
    rows.set(m.format(), {
      datacenter: 0,
      mobile: 0,
      residential: 0,
    })
  }

  series.forEach(({ time, link, value }) => {
    let key = moment(time).utc().startOf("day").format()
    let row = rows.get(key)
    if (!row) {
      row = {
        datacenter: 0,
        mobile: 0,
        residential: 0,
      }
      rows.set(key, row)
    }
    row[link] += Number(value)
    totals[link] += Number(value)
  })

  return <Card elevation={0}>
    <CardContent>
      <UsageChart series={series} start={start} end={end}/>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell className={classes.right}>Datacenter</TableCell>
            <TableCell className={classes.right}>Mobile</TableCell>
            <TableCell className={classes.right}>Residential</TableCell>
            <TableCell className={classes.right}>All</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Array.from(rows, ([key, row]) => {
              return (<TableRow key={key}>
                  <TableCell>
                    <code>{moment(key).format("ll")}</code>
                  </TableCell>
                  <TableCell align="right">
                    <code>{formatFileSize(row.datacenter)}</code>
                  </TableCell>
                  <TableCell align="right">
                    <code>{formatFileSize(row.mobile)}</code>
                  </TableCell>
                  <TableCell align="right">
                    <code>{formatFileSize(row.residential)}</code>
                  </TableCell>
                  <TableCell align="right">
                    <code>{formatFileSize(row.datacenter + row.mobile + row.residential)}</code>
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <code>TOTAL</code>
            </TableCell>
            <TableCell align="right">
              <code>{formatFileSize(totals.datacenter)}</code>
            </TableCell>
            <TableCell align="right">
              <code>{formatFileSize(totals.mobile)}</code>
            </TableCell>
            <TableCell align="right">
              <code>{formatFileSize(totals.residential)}</code>
            </TableCell>
            <TableCell align="right">
              <code>{formatFileSize(totals.datacenter + totals.mobile + totals.residential)}</code>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </CardContent>
  </Card>
})
