import gql from "graphql-tag"
import {
  GET_ONE,
  GET_LIST,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE_MANY,
} from "react-admin"
import querySortHelper from "../querySortHelper"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_ONE:
      return getOne({ ...params })
    case GET_LIST:
      return getList({ ...params })
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    case UPDATE_MANY:
      return updateMany({ ...params })
    case CREATE:
      return create({ ...params })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}

const getOne = ({ id }) => ({
  query: gql`
    query CustomerFeature($id: Int!) {
      customerFeature(id: $id) {
        id
        createdAt
        updatedAt
        name
        disposition
        comment
        customer {
          id
          account {
            id
            email
            displayName
          }
          contract {
            id
            features {
              id
              feature
              unitPrice
              unit
              quantity
            }
          }
        }
      }
    }
  `,
  variables: { id },
  parseResponse: ({ data: { accountFeature } }) => ({ data: accountFeature }),
})

const getList = ({
  pagination: { page, perPage },
  filter: { query, customer, disposition },
  sort,
}) => {
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: { query, customer, disposition },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
      query CustomerFeatures(
        $limit: Int!
        $offset: Int!
        $filter: CustomerFeatureRequestSliceFilter
        $sort: CustomerFeatureRequestSliceSorter
      ) {
        customerFeatureRequestSlice(
          limit: $limit
          offset: $offset
          filter: $filter
          sort: $sort
        ) {
          total: totalCount
          data: items {
            id
            createdAt
            updatedAt
            feature
            disposition
            comment
            customer {
              account {
                id
                email
                displayName
              }
              contract {
                id
                features {
                  id
                  feature
                  unitPrice
                  unit
                  quantity
                }
              }
            }
          }
        }
      }
    `,
    variables: variables,
    parseResponse: ({
      data: {
        customerFeatureRequestSlice: { data, total },
      },
    }) => {
      return { data, total }
    },
  }
}

const getManyReference = ({ target, ...props }) => {
  switch (target) {
    case "customer":
      return getManyReferenceForCustomer({ ...props })
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = ({
  id,
  pagination: { page, perPage },
  filter: { q: query },
  sort,
}) => {
  const variables = {
    id,
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: { query },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
      query CustomerFeaturesRef(
        $id: Int!
        $limit: Int!
        $offset: Int!
        $filter: CustomerFeatureSliceFilter
        $sort: CustomerFeatureSliceSorter
      ) {
        customer(id: $id) {
          featureRequestsSlice(
            limit: $limit
            offset: $offset
            filter: $filter
            sort: $sort
          ) {
            total: totalCount
            data: items {
              id
              createdAt
              updatedAt
              disposition
              comment
            }
          }
        }
      }
    `,
    variables: variables,
    parseResponse: ({
      data: {
        customer: { featureRequestsSlice },
      },
    }) => featureRequestsSlice,
  }
}

const updateMany = ({ ids, data: input }) => ({
  query: gql`
    mutation($ids: [Int!], $input: UpdateCustomerFeatureRequestInput!) {
      data: updateCustomerFeatureRequestMany(ids: $ids, input: $input) {
        id
      }
    }
  `,
  variables: { ids, input },
  parseResponse: ({ data: { data } }) => ({ data }),
})

const create = ({ data: { customer, feature, comment } }) => {
  return {
    variables: { customer, feature, comment: comment || "" },
    query: gql`
      mutation($customer: Int!, $feature: String!, $comment: String!) {
        createCustomerFeatureRequest(
          input: {
            customer: $customer
            feature: $feature
            comment: $comment
            allow: true
            disposition: acknowledged
          }
        ) {
          id
        }
      }
    `,
    parseResponse: ({ data: { createCustomerFeatureRequest: data } }) => ({
      data,
      total: 1,
    }),
  }
}
