import React, { Component } from "react"
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  FunctionField,
  Filter,
  SelectInput,
  SearchInput,
} from "react-admin"
import CustomerIcon from "@material-ui/icons/AccountCircle"
import { Tabs, Tab, Divider } from "@material-ui/core"
import get from "lodash/get"
import IDField from "../IDField"
import FullNameField from "../FullNameField"
import Pagination from "../Pagination"
import { withStyles } from "@material-ui/styles"

class TabbedDatagrid extends Component {
  tabs = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
    { value: "all", label: "All" },
  ]

  state = {
    pending: [],
    approved: [],
    rejected: [],
  }

  updatedAtAliases = {
    all: "Updated At",
    rejected: "Rejected At",
    approved: "Approved At",
  }

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.disposition]) {
      return { ...state, [props.filterValues.disposition]: props.ids }
    }
    return null
  }

  handleTabChange = (event, value) => {
    const { filterValues, setFilters } = this.props
    setFilters({ ...filterValues, disposition: value })
  }

  render() {
    const { classes, filterValues, ...props } = this.props
    return (
      <>
        <Tabs
          variant="fullWidth"
          value={filterValues.disposition}
          indicatorColor="primary"
          onChange={this.handleTabChange}
        >
          {this.tabs.map((choice) => (
            <Tab key={choice.value} label={choice.label} value={choice.value} />
          ))}
        </Tabs>
        <Divider />
        <Datagrid
          {...props}
          classes={classes}
          ids={this.state[filterValues.disposition]}
          rowClick="show"
        >
          <IDField />
          <DateField label="Signed up at" source="createdAt" sortable={true} />
          {filterValues.disposition !== "pending" && (
            <FunctionField
              label={this.updatedAtAliases[filterValues.disposition]}
              render={(record) => (
                <DateField
                  style={{ textTransform: "capitalize" }}
                  record={record}
                  source="updatedAt"
                />
              )}
            />
          )}
          <FullNameField label="Name" sortable={false} />
          <TextField
            style={{ textTransform: "capitalize" }}
            source="disposition"
            label="Status"
            sortable={false}
          />
          <TextField source="company" sortable={false} />
          <TextField
            style={{ textTransform: "capitalize" }}
            label="Plan"
            source="plan.slug"
            sortable={false}
          />
          <TextField source="email" sortable={false} />
          <NumberField
            label="Risk Score"
            source="minfraud.response.riskScore"
            sortable={false}
          />
          {["all", "approved"].some((x) => filterValues.disposition === x) && (
            <FunctionField
              label="Account"
              render={(record) =>
                record.account ? (
                  <a href={`/customers/${get(record, "account.id")}/show`}>
                    <CustomerIcon color="secondary" />
                  </a>
                ) : null
              }
            />
          )}
        </Datagrid>
      </>
    )
  }
}

export const SignupsFilter = (props) => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn />
    <SelectInput
      source="createdAfter"
      alwaysOn
      label="Created In Last"
      choices={[
        { id: "day", name: "Last 24-hours" },
        { id: "week", name: "Last 7-days" },
        { id: "month", name: "Last 30-days" },
        { id: "year", name: "Last 12-months" },
      ]}
    />
    {/*<ReferenceInput label="IP" source="ip" reference="signups">*/}
    {/*  <SelectInput optionText="ip" />*/}
    {/*</ReferenceInput>*/}

    <SearchInput source="ip" alwaysOn />
  </Filter>
)

const styles = (theme) => ({
  headerCell: {
    padding: "5px 5px 5px 5px",
  },
  rowCell: {
    padding: "5px 5px 5px 10px",
  },
})

export default withStyles(styles)(({ classes, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    filterDefaultValues={{ disposition: "pending" }}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<SignupsFilter />}
    pagination={<Pagination />}
    perPage={25}
  >
    <TabbedDatagrid classes={classes} />
  </List>
))
