import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  SearchInput,
  SelectInput,
  Pagination,
} from "react-admin"
import { Typography } from "@material-ui/core"

import IDField from "../IDField"
import { ImpersonateButton } from "../../components"
import DisplayNameField from "../DisplayNameField"
import FilesizeField from "../FilesizeField"
import { withStyles } from "@material-ui/styles"
import NoteContentField from "../reports/activeCustomers/components/NoteContentField"
import CustomerNoteEditDialog, { TYPE as EDIT_DIALOG_TYPE } from "../customerNotes/components/EditDialog"
import { getRowStyle } from "./styles"

export const CustomersFilter = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn allowEmpty/>
    <SelectInput source="customerDetails.subscriptionStatus" allowEmpty alwaysOn label="Status" choices={[
      { id: "trial", name: "Trial" },
      { id: "active", name: "Active" },
      { id: "free", name: "Free" },
      { id: "suspended", name: "Suspended" },
    ]}/>
    <SelectInput source="customerDetails.subscriptionPlan" allowEmpty alwaysOn label="Plan" choices={[
      { id: 1, name: "[10001] Starter" },
      { id: 2, name: "[10002] Professional" },
      { id: 3, name: "[10003] Spiderman" },
      { id: 4, name: "[20001] Micro" },
      { id: 5, name: "[20002] Starter" },
      { id: 6, name: "[20003] Professional" },
      { id: 7, name: "[20004] Spiderman" },
      { id: 8, name: "[20005] Enterprise" },
      { id: 13, name: "[30001] Free" },
    ]}/>
    <SelectInput source="account.isBlocked" label="Is Blocked" allowEmpty choices={[
      { id: true, name: "Yes" },
      { id: false, name: "No" },
    ]}/>
  </Filter>
)

// eslint-disable-next-line
const CustomerActionsField = ({ record }) => (
  <span className="nowrap">
    <Link to={`/customers/${record.id}/show`}><Typography>View Details</Typography></Link><br/>
    <Link to={`${record.id}/edit`}>Edit Settings</Link><br/>
    <Link to="#">Login</Link>
  </span>
)

const CustomersDatagrid = ({ classes, ...props }) => {
  const [idOfCustomerToCreateNote, setIdOfCustomerToCreateNote] = useState(null)

  const closeCreateNoteDialog = () => {
    setIdOfCustomerToCreateNote(null)
  }

  return (
    <React.Fragment>
      <Datagrid
        {...props}
        classes={classes}
        rowStyle={getRowStyle}>
        <IDField source="id" link="show"/>
        <DisplayNameField source="account"/>
        <TextField label="Email" source="account.email" sortBy="email" addLabel/>
        <TextField label="Handle" source="handle" sortBy="handle" addLabel/>
        <TextField
          style={{ textTransform: "capitalize" }}
          label="Plan"
          sortable={false}
          source="customerDetails.subscriptionPlan.slug"/>
        <TextField
          style={{ textTransform: "capitalize" }}
          label='Status'
          sortBy="subscriptionStatus"
          source="customerDetails.subscriptionStatus"/>
        <DateField label="Billing Date" source="customerDetails.nextBillingDate" sortBy="nextBillingDate"/>
        <FilesizeField source="customerDetails.bandwidth30Days" sortBy="bandwidth30Days" label="30 Days Usage"/>
        <DateField source="createdAt" sortBy="createdAt" label="Date created"/>
        <DateField source="account.lastLogin" sortBy="lastLogin" label="Last login"/>
        <DateField source="customerDetails.lastProxyUsage" sortBy="lastProxyUsage" label="Last Proxy Activity"/>
        <NoteContentField
          key="notes.items.0"
          source="notes.items.0"
          label="Notes"
          setIdOfCustomerToCreateNote={setIdOfCustomerToCreateNote}
          headerClassname={classes.tableNotesCell}
          sortable={false}
        />
        <ImpersonateButton source="account" type="account"/>
      </Datagrid>
      {idOfCustomerToCreateNote && (
        <CustomerNoteEditDialog
          type={EDIT_DIALOG_TYPE.CREATE}
          record={{}}
          customerID={idOfCustomerToCreateNote}
          onClose={closeCreateNoteDialog}
          previewMode
        />
      )}
    </React.Fragment>
  )
}

const CustomersPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const styles = theme => ({
  headerCell: {
    padding: "0px 5px 0px 5px",
  },
  rowCell: {
    padding: "0px 5px 0px 10px",
  },
})

export default withStyles(styles)(({ classes, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "lastLogin", order: "DESC" }}
    filters={<CustomersFilter/>}
    pagination={<CustomersPagination/>}
    perPage={25}
  >
    <CustomersDatagrid classes={classes}/>
  </List>
))