import React from "react"
import Chip from "@material-ui/core/Chip"
import { CONTRACT_CLASSNAME_BY_STATUS, CONTRACT_COLORS } from "../constants"
import { withStyles } from "@material-ui/styles"
import classnames from "classnames"
import get from "lodash/get"

const ContractStatus = ({ classes, className, contract }) => {
  if (!contract) {
    return null
  }

  const contractClassName = get(classes, CONTRACT_CLASSNAME_BY_STATUS[contract.status])
  return <Chip
    variant="outlined"
    className={classnames("contract", className, contractClassName)}
    label={contract.status} />
}

const style = {
  contract: {
    cursor: "default",
    fontWeight: 400,

  },
  ...CONTRACT_COLORS,
}

export default withStyles(style)(ContractStatus)
