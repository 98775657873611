import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  withStyles,
  CircularProgress,
} from "@material-ui/core"
import get from "lodash/get"
import { Form } from "react-final-form"
import {
  SaveButton,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin"
import DeleteIcon from "@material-ui/icons/Delete"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import CustomerNoteForm from "./CustomerNoteForm"
import moment from "moment"

export const TYPE = {
  EDIT: "edit",
  CREATE: "create",
  DELETE: "delete",
}
const DIALOG_TITLE = {
  [TYPE.EDIT]: "Edit note",
  [TYPE.CREATE]: "Create a note",
  [TYPE.DELETE]: "Are you sure you want to delete following note?",
}

const EditCustomerNoteDialog = ({
  type,
  record,
  customerID,
  onClose,
  previewMode,
  classes,
}) => {
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedNote, setSelectedNote] = useState({})
  const [totalNotes, setTotalNotes] = useState(-1)

  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (!previewMode || (selectedPage === 0 && totalNotes !== -1)) {
      return
    }

    setLoading(true)
    const page = selectedPage <= 0 ? 1 : selectedPage
    dataProvider
      .getManyReference("customerNotes", {
        target: "customer",
        id: customerID,
        pagination: { page, perPage: 1 },
      })
      .then((data) => {
        setLoading(false)
        setTotalNotes(data.total)
        if (!!data.total) {
          setSelectedNote(data.data[0])
        }
      })
      .catch((err) => {
        setLoading(false)
        notify(err.message || "Something went wrong when fetching note")
      })
  }, [totalNotes, previewMode, selectedPage, customerID, dataProvider, notify])

  const setNextPage = () => setSelectedPage(selectedPage - 1)
  const setPrevPage = () => setSelectedPage(selectedPage + 1)
  const handleSubmit = ({ body, externalLink } = {}) => {
    // prevent multiple edits at the same time
    if (saving) {
      return
    }

    setSaving(true)

    if (type === TYPE.EDIT || (previewMode && selectedPage !== 0)) {
      return dataProvider
        .update("customerNotes", {
          data: {
            id: record.id || selectedNote.id,
            body,
            externalLink: externalLink || "",
          },
        })
        .then(() => {
          setSaving(false)
          refresh()

          if (!previewMode) {
            onClose()
          }

          notify("Note successfully updated")
        })
        .catch((err) => {
          setSaving(false)

          notify(err.message || "Something went wrong when updating note")
        })
    }

    if (type === TYPE.DELETE) {
      return dataProvider
        .delete("customerNotes", { data: { id: record.id } })
        .then(() => {
          setSaving(false)
          refresh()
          onClose()
          notify("Note successfully deleted")
        })
        .catch((err) => {
          setSaving(false)

          notify(err.message || "Something went wrong when deleting note")
        })
    }

    return dataProvider
      .create("customerNotes", {
        data: { customerID, body, externalLink: externalLink || "" },
      })
      .then(() => {
        setSaving(false)
        refresh()
        onClose()
        notify("Note successfully created")
      })
      .catch((err) => {
        setSaving(false)

        notify(err.message || "Something went wrong when saving note")
      })
  }

  const note = selectedPage !== 0 ? selectedNote : record
  return (
    <Dialog
      onClose={onClose}
      aria-label={DIALOG_TITLE[type]}
      maxWidth="md"
      fullWidth
      open
    >
      <Form
        onSubmit={handleSubmit}
        initialValues={{ body: note.body, externalLink: note.externalLink }}
        keepDirtyOnReinitialize
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {selectedPage !== 0
                    ? `Preview ${selectedPage}/${totalNotes}`
                    : DIALOG_TITLE[type]}
                </Grid>
                {previewMode && (
                  <Grid item>
                    <Button
                      disabled={selectedPage === totalNotes}
                      onClick={setPrevPage}
                    >
                      <NavigateBeforeIcon />
                      Prev
                    </Button>
                    <Button disabled={selectedPage === 0} onClick={setNextPage}>
                      Next
                      <NavigateNextIcon />
                    </Button>
                    <Button onClick={onClose}>
                      <Typography>Cancel</Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </DialogTitle>
            <DialogContent>
              {selectedPage === 0 && (
                <CustomerNoteForm disabled={type === TYPE.DELETE} />
              )}
              {selectedPage !== 0 && loading && (
                <Grid container justifyContent="center">
                  <CircularProgress />
                </Grid>
              )}
              {selectedPage !== 0 && !loading && (
                <Grid container alignItems="center">
                  <Grid item xs={2} className={classes.previewFirstColumn}>
                    <Typography>Date</Typography>
                    <Typography>
                      {moment(selectedNote.createdAt).format("DD/MM/YY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <TextInput
                      source="body"
                      type="text"
                      label="Content"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <TextInput
                      source="externalLink"
                      type="text"
                      label="External link"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.previewFirstColumn}>
                    <Typography>Written by</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography>
                      {`${get(selectedNote, "author.firstName")} ${get(
                        selectedNote,
                        "author.lastName",
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: !previewMode ? "space-between" : "flex-end",
              }}
            >
              {!previewMode && (
                <Button onClick={onClose}>
                  <Typography>Cancel</Typography>
                </Button>
              )}
              {type === TYPE.DELETE && selectedPage === 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <DeleteIcon />
                  <Typography>DELETE</Typography>
                </Button>
              )}
              {type !== TYPE.DELETE && (
                <SaveButton
                  saving={saving}
                  handleSubmitWithRedirect={handleSubmit}
                />
              )}
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

const style = {
  previewFirstColumn: {
    textAlign: "center",
  },
}

export default withStyles(style)(EditCustomerNoteDialog)
