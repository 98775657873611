import React from "react"
import {
  TextField,
  DateField,
  Datagrid,
  ReferenceManyField,
} from "react-admin"

import IDField from "../../IDField"

export default ({...props}) => {
  const { record, recordCallback } = props
  if (!!recordCallback) {
    recordCallback(record)
  }
  return <ReferenceManyField
    reference="contracts"
    target="customer"
    sort={{ field: "createdAt", order: "DESC" }}
    filter={{customer: record.id}}
    {...props}
  >
    <Datagrid style={{textAlign: "left"}}>
      <IDField source="id"/>
      <DateField label="Created At" source="createdAt"/>
      <TextField style={{textTransform: "capitalize"}} source="status"/>
      <TextField label="Plan" source="plan.displayName"/>
      <TextField label="Issued By" source="issuedBy.displayName"/>
    </Datagrid>
  </ReferenceManyField>
}