import React from "react"
import { withStyles } from "@material-ui/styles"
import { Tooltip } from "@material-ui/core"
import ContractStatusField from "../../components/ContractStatus"

const ContractStatus = ({ classes, customer: { contract } = {} }) => {
  if (!contract) {
    return null;
  }

  return (
    <Tooltip title="Contract status" className={classes.contract}>
      <ContractStatusField contract={contract} />
    </Tooltip>
  )
}

const style = {
  contract: {
    textTransform: "capitalize",
    cursor: "default",
  },
}

export default withStyles(style)(ContractStatus)
