import React from "react"
import { compose, pure } from "recompose"
import { get } from "lodash"
import { Button, withStyles } from "@material-ui/core"
import LaunchIcon from "@material-ui/icons/Launch"

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(-1),
    textTransform: "none",
    fontWeight: "normal",
  },
  iconMargin: {
    width: 16,
    marginRight: 8,
  },
  icon: {
    width: 16,
  },
})

const BraintreeLinkField = ({ record, source, children, classes }) => {
  const href = source ? get(record, `${source}.braintreeURL`) : get(record, `braintreeURL`)
  if (!href) {
    return null
  }
  return <Button target="_blank" href={href} className={classes.button} size="small" variant="text">
    { children ? <LaunchIcon className={classes.iconMargin}/> : <LaunchIcon className={classes.icon}/> }
    {children}
  </Button>
}

const enhance = compose(
  pure,
  withStyles(styles),
)

const EnhancedBraintreeLinkField = enhance(BraintreeLinkField)

EnhancedBraintreeLinkField.defaultProps = {
  sortable: false,
}

export default EnhancedBraintreeLinkField
