import React from 'react'
import Gravatar from 'react-gravatar'
import pure from 'recompose/pure'
import get from 'lodash/get'
import { Link } from "react-admin"
import { compose } from "recompose"
import { withStyles } from "@material-ui/core"
import classnames from "classnames"

const DisplayNameField = pure(({ record = {}, classes, source, resource, link, size = 25 }) => {
  const email = get(record, source ? source + ".email" : "email")
  const displayName = get(record, source ? source + ".displayName" : "displayName")
  if (!link) {
    return (
      record ? <div className={classes.text}>
        <Gravatar email={email} size={size} style={{marginRight: 0.25 + "rem"}}/>
        {displayName}
      </div> : null
    )
  }

  return <Link
    target="_blank"
    rel="noopener noreferrer"
    to={`${resource}/${get(record, "id")}/${link}`}
    className={classnames(classes.link, classes.text)}
  >
    <Gravatar email={email} size={size} style={{marginRight: 0.25 + "rem"}}/>
    {displayName}
  </Link>
})


const enhance = compose(
  pure,
  withStyles((theme) => ({
    link: {
      color: "black",
      '&:hover': {
        textDecoration: "none!important",
      },
    },
    text: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      fontFamily: "inherit"
    }
  })),
)

const PureDisplayNameField = enhance(DisplayNameField)

PureDisplayNameField.defaultProps = {
  label: 'Name',
  sortable: false,
}

export default PureDisplayNameField
