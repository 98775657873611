import React, { Component } from "react"

import { List, Datagrid, DateField, TextField } from "react-admin"
import IDField from "../IDField"
import Pagination from "../Pagination"
import { Divider, Tab, Tabs } from "@material-ui/core"
import TextUrlField from "../TextUrlField"
import CurrencyField from "../CurrencyField"

class PartnerAppsDatagrid extends Component {
  tabs = [
    { value: "", label: "All" },
    { value: "approved", label: "Approved" },
    { value: "waiting", label: "Waiting" },
    { value: "rejected", label: "Rejected" },
  ]

  state = {
    all: [],
    approved: [],
    waiting: [],
    rejected: [],
  }

  static getDerivedStateFromProps(props, state) {
    if (props.ids !== state[props.filterValues.integrationStatus]) {
      return { ...state, [props.filterValues.integrationStatus]: props.ids }
    }
    return null
  }

  handleTabChange = (event, value) => {
    const { filterValues, setFilters } = this.props
    setFilters({ ...filterValues, integrationStatus: value })
  }

  render() {
    const { filterValues, ...props } = this.props;
    return (
      <>
        <Tabs
          variant="fullWidth"
          value={filterValues.integrationStatus}
          indicatorColor="primary"
          onChange={this.handleTabChange}
        >
          {this.tabs.map((choice) => (
            <Tab key={choice.value} label={choice.label} value={choice.value}/>
          ))}
        </Tabs>
        <Divider/>
        <Datagrid {...props} ids={this.state[filterValues.integrationStatus]} rowClick="show">
          <IDField sortable={true}/>
          <DateField source="updatedAt"/>
          {filterValues.integrationStatus === '' && (<TextField label="Status" source="integrationStatus" sortable={false}/>)}
          <TextUrlField label="Playstore URL" source="playstoreURL" displaySource="name"/>
          <TextField source="starRating"/>
          <TextField source="installs"/>
          <CurrencyField source="pricePer1KHad" options={{ maximumFractionDigits: 4 }} addLabel/>
        </Datagrid>
      </>
    )
  }
}

export default (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ integrationStatus: "" }}
    pagination={<Pagination/>}
    perPage={25}
  >
    <PartnerAppsDatagrid/>
  </List>
)