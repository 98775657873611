import React from "react"
import { Edit, TabbedForm, FormTab, TextInput } from "react-admin"
import FullNameField from "../FullNameField"

const Title = ({ record }) => record ? <FullNameField record={record} size={32}/> : null

export default (props) => (
  <Edit title={<Title/>} undoable={false} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Summary">
        <TextInput source="account.id" disabled/>
        <TextInput source="handle" disabled/>
        <TextInput label="First Name" source="account.firstName"/>
        <TextInput label="Last Name" source="account.lastName"/>
        <TextInput label="Company" source="account.company"/>
        <TextInput label="Email" source="account.email"/>
        <TextInput label="Phone" source="account.phone"/>
        <TextInput label="Country" source="account.country"/>
        <TextInput label="Postal Code" source="account.postalCode"/>
        <TextInput label="Region" source="account.region"/>
        <TextInput label="Locality" source="account.locality"/>
        <TextInput label="Street Address" source="account.streetAddress"/>
        <TextInput label="Extended Address" source="account.extendedAddress"/>
      </FormTab>
    </TabbedForm>
  </Edit>
)
