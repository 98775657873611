import React from "react"
import { Typography } from "@material-ui/core"

import get from "lodash/get"
import { PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME } from "../constants"

const DomainsCategoryField = (props) => {
  const category = get(props.record, props.source, "")
  return (
    <Typography align="center">
      {get(PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME, category, category)}
    </Typography>
  )
}

export default DomainsCategoryField
