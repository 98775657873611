import React, { useState } from "react"
import { useNotify, useRedirect } from "react-admin"
import { Button, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import Dialog from "@material-ui/core/Dialog"
import Tooltip from "@material-ui/core/Tooltip"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogActions from "@material-ui/core/DialogActions"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { compose } from "recompose"

export const DeleteAccountDialog = ({
  closeDialog,
  mutate,
  record,
  classes,
}) => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()

  const onClick = () => {
    // prevent multiple edits at the same time
    if (loading) {
      return
    }

    setLoading(true)

    mutate({
      variables: {
        id: record.id,
      },
    })
      .then(() => {
        notify("Account successfully deleted")
        redirect("/customers")
        closeDialog()
      })
      .catch((e) => {
        notify(e.message || "Something went wrong when deleting account")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
      open
      aria-label="Delete account"
    >
      <DialogTitle>Are you sure you want to delete this account?</DialogTitle>
      <DialogContent>
        <Typography>
          Deleting account will suspend current contract and delete all related
          users. Account information (e.g. invoices, bandwidth usage) will not
          be deleted. However, it will remain hidden in staff panel. It is not
          possible to revert this operation. Would you like to proceed?
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button onClick={closeDialog}>
          <Typography>Cancel</Typography>
        </Button>
        <Button onClick={onClick} disabled={loading}>
          <DeleteIcon className={classes.deleteButton} />
          <Typography className={classes.deleteButton}>Delete</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DeleteAccount = (props) => {
  const [isDialogOpen, setToggled] = useState(false)

  if (!props.record && !props.loading) {
    return null
  }

  const toggleDialog = () => setToggled(!isDialogOpen)

  return (
    <React.Fragment>
      <Tooltip title="Delete account">
        <span>
          <Button
            color="primary"
            onClick={toggleDialog}
            disabled={props.loading}
          >
            {props.loading && <CircularProgress />}
            {!props.loading && <DeleteIcon />}
          </Button>
        </span>
      </Tooltip>
      {isDialogOpen && !props.loading && (
        <DeleteAccountDialog closeDialog={toggleDialog} {...props} />
      )}
    </React.Fragment>
  )
}

const style = {
  deleteButton: {
    color: "red",
  },
}

export default compose(
  withStyles(style),
  graphql(gql`
    mutation DeleteCustomer($id: Int!) {
      deleteCustomer(id: $id)
    }
  `),
)(DeleteAccount)
