import React from "react"
import { AppBar } from "react-admin"
import { Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import FreshdeskButton from "./FreshdeskButton"

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
}

export default withStyles(styles)(({ classes, ...props }) => {
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      <FreshdeskButton />
    </AppBar>
  )
})
