import React from "react"
import get from "lodash/get"
import { CircularProgress } from "@material-ui/core"
import {useGetManyReference} from "react-admin";

const CustomerLastNoteField = ({ customerId, className }) => {
  const { loading, data } = useGetManyReference('customerNotes', "customer", customerId, { page: 1, perPage: 1 })

  if (loading) {
    return <CircularProgress/>
  }

  return <span className={className}>{get(Object.values(data), '0.body')}</span>
}

export default CustomerLastNoteField
