import React from "react"
import {
  TextField,
  DateField,
} from "react-admin"
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import FullNameField from "../../FullNameField"

export default ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>User Information</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>
              <FullNameField label="Name" record={record}/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>
              <TextField record={record} source="email"/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Created At</TableCell>
            <TableCell>
              <DateField record={record} source="createdAt"/>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Login</TableCell>
            <TableCell>
              <TextField record={record} source="lastLogin"/>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  )
}