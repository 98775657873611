import React, { useState } from "react"
import { List, ListItemText, MenuItem } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { MenuItemLink } from "react-admin"

import DashboardIcon from "@material-ui/icons/Dashboard"
import SignupsIcon from "@material-ui/icons/PersonAdd"
import CustomersIcon from "@material-ui/icons/People"
import StaffIcon from "@material-ui/icons/SupervisorAccount"
import PremiumDomainsIcon from "@material-ui/icons/CloudDone"
import AccessRequestIcon from "@material-ui/icons/CloudQueue"
import BraintreeIcon from "@material-ui/icons/AttachMoney"
import GrafanaIcon from "@material-ui/icons/InsertChart"
import PartnersIcon from "@material-ui/icons/RecentActors"
import PartnerAppsIcon from "@material-ui/icons/Devices"
import InvoicesIcon from "@material-ui/icons/Description"

import SubMenu from "./SubMenu"

const Menu = ({ classes, onMenuClick, open }) => {
  const [isBraintreeOpen, setBraintreeOpen] = useState(false)
  const toggleBraintree = () => {
    setBraintreeOpen(!isBraintreeOpen)
  }

  const [isGrafanaOpen, setGrafanaOpen] = useState(false)
  const toggleGrafana = () => {
    setGrafanaOpen(!isGrafanaOpen)
  }

  return (
    <List>
      <MenuItemLink
        exact
        to="/"
        primaryText="Dashboard"
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/signups"
        primaryText="Signups"
        leftIcon={<SignupsIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/customers"
        primaryText="Customers"
        leftIcon={<CustomersIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/staff"
        primaryText="Admin users"
        leftIcon={<StaffIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/invoices"
        primaryText="Invoices"
        leftIcon={<InvoicesIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/premium-domains"
        primaryText="Premium domains"
        leftIcon={<PremiumDomainsIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/customer-feature-request"
        primaryText="Access requests"
        className={classes.multiLineMenuItem}
        leftIcon={<AccessRequestIcon />}
        onClick={onMenuClick}
      />
      {/*<MenuItemLink*/}
      {/*  to="/partners"*/}
      {/*  primaryText="Partners"*/}
      {/*  leftIcon={<PartnersIcon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*/>*/}
      {/*<MenuItemLink*/}
      {/*  to="/partner-apps"*/}
      {/*  primaryText="Partner Apps"*/}
      {/*  leftIcon={<PartnerAppsIcon />}*/}
      {/*  onClick={onMenuClick}*/}
      {/*/>*/}
      <SubMenu
        isOpen={isBraintreeOpen}
        handleToggle={toggleBraintree}
        sidebarIsOpen={open}
        name="Braintree"
        icon={<BraintreeIcon />}
      >
        <MenuItemLink
          to="/transactions"
          primaryText="Transactions"
          onClick={onMenuClick}
        />
      </SubMenu>
      <SubMenu
        isOpen={isGrafanaOpen}
        handleToggle={toggleGrafana}
        sidebarIsOpen={open}
        name="Reports"
        icon={<GrafanaIcon />}
      >
        <ExternalMenuItemLink
          primaryText="Overview"
          to="//dash.spider.com/d/JQHOv92mz/overview"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Proxy Usage"
          to="https://dash.spider.com/d/ObcdGHamk/bandwidth-usage"
          onClick={onMenuClick}
        />
        <MenuItemLink
          primaryText="Active customers"
          to="/active-customers"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Bandwidth Usage"
          to="https://dash.spider.com/d/ANSIJocik/proxy-usage"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Message Queue"
          to="https://dash.spider.com/d/NBtvy2cmk/message-queue"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Cluster Nodes"
          to="https://dash.spider.com/d/VxENBJcmk/nodes"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Worker Breakdown"
          to="https://dash.spider.com/d/p070nt5mk/worker-breakdown"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Workers on world map"
          to="https://dash.spider.com/d/ctcwRykWk/workers-by-country-map"
          onClick={onMenuClick}
        />
        <ExternalMenuItemLink
          primaryText="Workers on US map"
          to="https://dash.spider.com/d/9vkckszZz/workers-by-us-state-map"
          onClick={onMenuClick}
        />
      </SubMenu>
    </List>
  )
}

const style = {
  multiLineMenuItem: {
    wordBreak: "break-word",
    whiteSpace: "normal",
  },
}

export default withStyles(style)(Menu)

const ExternalMenuItemLink = ({ to, primaryText, onClick }) => (
  <MenuItem onClick={onClick}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <ListItemText primary={primaryText} />
    </a>
  </MenuItem>
)
