import React from 'react'
import pure from 'recompose/pure'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import Tooltip from "@material-ui/core/Tooltip"

const CustomerContractStatusField = pure(({ record = {}, source, size = 25 }) => {
  const status = get(record, source)
  return (
    record && status ? (
        <Tooltip title={capitalize(status)}>
            <div style={{ textTransform: 'uppercase', textAlign: 'center' }}>
                {status[0]}
            </div>
        </Tooltip>
    ) : null
  )
})


export default CustomerContractStatusField;
