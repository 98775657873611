import React from "react"
import get from "lodash/get"
import { withStyles, Tooltip } from "@material-ui/core"

import DashboardIcon from "@material-ui/icons/Info"

const CustomerContractStatusField = ({
  record = {},
  source,
  classes,
  setIdOfCustomerToCreateNote,
}) => {
  const onClick = () => setIdOfCustomerToCreateNote(record.id)
  const note = get(record, source)
  if (!note) {
    return (
      <button className={classes.button} onClick={onClick}>
        <i>none</i>
      </button>
    )
  }
  return (
    <div className={classes.container}>
      <div className={classes.infoIcon}>
        <Tooltip
          title={<span className={classes.popper}>{note.body}</span>}
          enterTouchDelay={50}
          leaveTouchDelay={3000}
          interactive
        >
          <DashboardIcon />
        </Tooltip>
      </div>
      <Tooltip title={<span className={classes.popper}>{note.body}</span>}>
        <button className={classes.button} onClick={onClick}>
          <div className={classes.body}>{note.body}</div>
        </button>
      </Tooltip>
    </div>
  )
}

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "@media (min-width: 960px)": {
    infoIcon: {
      display: "none",
    },
  },
  button: {
    display: "block",
    border: "none",
    padding: "0",
    margin: "auto",
    backgroundColor: "transparent",
    "&:focus": {
      outline: 0,
    },
    cursor: "pointer",
  },
  body: {
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    lineHeight: "20px",
    maxHeight: "40px",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  popper: {
    whiteSpace: "break-spaces",
    fontSize: "12px",
  },
})

export default withStyles(styles)(CustomerContractStatusField)
