import React from "react"
import { Card, CardContent } from "@material-ui/core"
import ServiceDetails from "../../customers/Show/ServiceDetails"

export const SubscriptionPlan = ({ record }) => {
  if (!record || !record.customer) {
    return null
  }

  const {plan, customer: {contract: { features }}} = record
  return (
    <Card>
      <CardContent>
        <ServiceDetails features={features} plan={plan}/>
      </CardContent>
    </Card>
  )
}
