import { signupsQuery } from "./resources/signups"
import { customersQuery } from "./resources/customers"
import { customerNotesQuery } from "./resources/customerNotes"
import { workersByCountrySeriesQuery } from "./resources/workersByCountrySeries"
import { staffQuery } from "./resources/staff"
import { usersQuery } from "./resources/users"
import { transactionsQuery } from "./resources/transactions"
import { premiumDomainsQuery } from "./resources/premiumDomains"
import { customerFeatureRequestQuery } from "./resources/customerFeatureRequests"
import { partnersQuery } from "./resources/partners"
import { invoicesQuery } from "./resources/invoices"
import { zonesQuery } from "./resources/zones"
import { partnerAppsQuery } from "./resources/partnerApps"
import { contractQuery } from "./resources/contracts"
import { balanceQuery } from "./resources/balanceTransactions"

const buildQuery = () => (queryType, resourceName, params) => {
  switch (resourceName) {
    case `signups`:
      return signupsQuery({ queryType, params })
    case `customers`:
      return customersQuery({ queryType, params })
    case `customerNotes`:
      return customerNotesQuery({ queryType, params })
    case `workersByCountrySeries`:
      return workersByCountrySeriesQuery({ queryType, params })
    case `customer-feature-request`:
      return customerFeatureRequestQuery({ queryType, params })
    case `partners`:
      return partnersQuery({ queryType, params })
    case `partner-apps`:
      return partnerAppsQuery({ queryType, params })
    case `users`:
      return usersQuery({ queryType, params })
    case `transactions`:
      return transactionsQuery({ queryType, params })
    case `premium-domains`:
      return premiumDomainsQuery({ queryType, params })
    case `invoices`:
      return invoicesQuery({ queryType, params })
    case `zones`:
      return zonesQuery({ queryType, params })
    case `staff`:
      return staffQuery({ queryType, params })
    case `contracts`:
      return contractQuery({ queryType, params })
    case `balance-transactions`:
      return balanceQuery({ queryType, params })
    default:
      throw new Error(`Unknown resource ${resourceName}`)
  }
}

export default buildQuery
