import React from "react"
import pure from "recompose/pure"
import get from "lodash/get"
import moment from "moment"

const CreditCardField = pure(({ record = {}, source }) => {
  const cc = get(record, source)
  if (!cc) {
    return null
  }

  const bank = cc.issuingBank !== "Unknown" ? cc.issuingBank : null;

  return <span>
      {cc.cardType} {bank} (provided at: {moment(cc.createdAt).format("ll")})
    </span>
})

CreditCardField.defaultProps = {
  label: "Credit Card",
  sortable: false,
}

export default CreditCardField