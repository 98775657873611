import React from "react"
import {
  Datagrid,
  TextField,
  DateField,
  ReferenceManyField,
} from "react-admin"

import FullNameField from "../../FullNameField"
import IDField from "../../IDField"
import { ImpersonateButton as ImpersonateField } from "../../../components"
import ChangeUserPassword from "../../../components/ChangeUserPassword"

export default (props) => {
  const { record, recordCallback } = props
  if (!!recordCallback) {
    recordCallback(record)
  }
  
  return <ReferenceManyField
    reference="users"
    target="account"
    {...props}
  >
    <Datagrid>
      <IDField source="id"/>
      <FullNameField/>
      <TextField source="email" sortable={false}/>
      <DateField source="lastLogin" showTime sortable={false}/>
      <ImpersonateField type="user"/>
      <ChangeUserPassword />
    </Datagrid>
  </ReferenceManyField>
}