import React, { useState } from "react"
import { useRefresh } from "react-admin"
import FeatureRequestList from "../../customerFeatureRequests/List"
import CustomerFeatureRequestCreate from "../../customerFeatureRequests/Create"
import { Dialog, Button } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"

const FeatureRequestCreate = ({ record, onSuccess, onClose }) => (
  <Dialog open={true} onClose={onClose}>
    <CustomerFeatureRequestCreate
      basePath="/customers"
      resource="customer-feature-request"
      customer={record}
      onSuccess={onSuccess}
      title="Create"
    />
  </Dialog>
)

const FeatureRequests = ({ record }) => {
  const refresh = useRefresh()
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(!isOpen)
  const onSuccess = () => {
    refresh()
    setOpen(false)
  }
  return (
    <React.Fragment>
      {isOpen && (
        <FeatureRequestCreate
          record={record}
          onSuccess={onSuccess}
          onClose={toggle}
        />
      )}
      <FeatureRequestList
        resource="customer-feature-request"
        basePath="/customers"
        filters={<React.Fragment />}
        actions={
          <div>
            <Button color="primary" onClick={toggle}>
              <AddIcon /> Create
            </Button>
          </div>
        }
        filterDefaultValues={{ customer: record.account.id }}
      />
    </React.Fragment>
  )
}

export default FeatureRequests
