import React from "react"
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  BooleanInput,
} from "react-admin"
import {
  PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME,
  PREMIUM_DOMAIN_CATEGORY,
} from "./constants"

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="list" variant="standard" margin="normal">
      <TextInput source="name" label="Domain" required />
      <SelectInput
        source="category"
        choices={Object.values(PREMIUM_DOMAIN_CATEGORY).map((category) => ({
          id: category,
          name: PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME[category],
        }))}
        label="Category"
        required
      />
      <BooleanInput defaultValue={true} source="isActive" label="Is active" />
    </SimpleForm>
  </Create>
)
