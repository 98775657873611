import React from "react"
import get from "lodash/get"
import isNumber from "lodash/isNumber"
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, withStyles,
} from "@material-ui/core"
import { compose } from "recompose"
import {formatFileSize} from "../../../format"

const enhance = compose(
  withStyles(() => ({
    tableCellHeader: {
      fontWeight: "bold",
      fontSize: 80 + "%",
      textAlign: "left",
    },
  })),
)

export default enhance(({ contract, classes }) => {
  if (!contract) {
    return null
  }

  const billingPeriod = get(contract, "billingPeriods", {items: []}).items[0]
  if (!billingPeriod) {
    return null
  }
  const { balance } = billingPeriod

  return <Card elevation={0}>
    <CardContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader} colSpan={2}>
              Bandwidth Balance
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {balance.map(({link, balance}) => <TableRow>
            <TableCell style={{textTransform: "capitalize"}} pending="none">{link}</TableCell>
            <TableCell pending="none" align="right">
              {formatFileSize(balance)}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </CardContent>
  </Card>
})
