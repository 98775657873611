import React, { useState } from "react"
import {
  SaveButton,
  TextInput,
  useNotify,
  useDataProvider,
  required,
  minLength,
} from "react-admin"
import { Grid, Button, Typography } from "@material-ui/core"
import ChangeUserPasswordIcon from "./ChangeUserPasswordIcon"
import Dialog from "@material-ui/core/Dialog"
import Tooltip from "@material-ui/core/Tooltip"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import CircularProgress from "@material-ui/core/CircularProgress"
import DialogActions from "@material-ui/core/DialogActions"
import { Form } from "react-final-form"
import get from "lodash/get"

const passwordsMatches = (value, values) =>
  value !== get(values, "password")
    ? "Passwords do not match. Try again."
    : undefined

export const ChangePasswordDialog = ({ closeDialog, record }) => {
  const [loading, setLoading] = useState(false)
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const handleSubmit = ({ password }) => {
    // prevent multiple edits at the same time
    if (loading) {
      return
    }

    setLoading(true)

    dataProvider
      .update("users", { data: { id: record.id, password } })
      .then(() => {
        closeDialog()
        notify("Password successfully changed")
      })
      .catch((err) => {
        setLoading(false)

        notify(
          err.message || "Something went wrong when user changing password",
        )
      })
  }

  return (
    <Dialog
      onClose={closeDialog}
      maxWidth="xs"
      fullWidth
      open
      aria-label="Change password"
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Change password</DialogTitle>
            <DialogContent>
              <Grid container direction="column" alignItems="center">
                <TextInput
                  initialValue={record.email}
                  source="email"
                  type="email"
                  label="Email"
                  fullWidth
                  disabled
                />
                <TextInput
                  source="password"
                  type="password"
                  label="Password"
                  validate={[required(), minLength(8)]}
                  fullWidth
                />
                <TextInput
                  source="confirmPassword"
                  type="password"
                  label="Confirm password"
                  validate={[required(), minLength(8), passwordsMatches]}
                  fullWidth
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Button onClick={closeDialog}>
                <Typography>Cancel</Typography>
              </Button>
              <SaveButton
                saving={loading}
                handleSubmitWithRedirect={handleSubmit}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

ChangePasswordDialog.defaultProps = {
  resource: "users",
}

export const ChangeUserPassword = (props) => {
  const [isDialogOpen, setToggled] = useState(false)

  if (!props.record && !props.loading) {
    return null
  }

  const toggleDialog = () => setToggled(!isDialogOpen)

  return (
    <React.Fragment>
      <Tooltip title={"Change password"}>
        <span>
          <Button
            color="primary"
            onClick={toggleDialog}
            disabled={props.loading}
          >
            {props.loading && <CircularProgress />}
            {!props.loading && <ChangeUserPasswordIcon />}
          </Button>
        </span>
      </Tooltip>
      {isDialogOpen && !props.loading && (
        <ChangePasswordDialog closeDialog={toggleDialog} {...props} />
      )}
    </React.Fragment>
  )
}

export default ChangeUserPassword
