import React from "react"
import { Datagrid, List } from "react-admin"
import IDField from "../IDField"

export default ({...props}) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <IDField source="id"/>
    </Datagrid>
  </List>
)