import gql from "graphql-tag"
import { GET_MANY_REFERENCE } from "react-admin"

export default ({ queryType, params }) => {
  switch (queryType) {
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    default:
      throw new Error(`not implemented: invoices ${queryType}`)
  }
}


const getManyReference = ({ target, id, pagination: { page, perPage } }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
  }
  if (target === "customer") {
    return getManyReferenceForCustomer({ ...variables })
  } else {
    throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = (variables) => {
  return {
    query: gql`
        query GetContractBalance($id: Int!, $limit: Int!, $offset: Int!) {
            customer(id: $id) {
                contract {
                  balanceSlice(limit: $limit, offset: $offset) {
                    total: totalCount,
                    data: items {
                      id
                      createdAt
                      link
                      quantity
                      balance
                      comment
                    }
                  }
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { customer: { contract: { balanceSlice: { data, total } } } } }) => ({
      data,
      total,
    }),
  }
}