import { isFunction } from "lodash"

export const getGTag = () => {
  if (
    typeof window === `undefined` ||
    window.location.host.indexOf(`stage`) !== -1 ||
    window.location.host.indexOf(`localtest.me`) !== -1
  ) {
    return logPush
  }

  return window.gtag || logPush
}

// MS tag
export const getUetq = () => {
  if (
    typeof window === `undefined` ||
    window.location.host.indexOf(`stage`) !== -1 ||
    window.location.host.indexOf(`localtest.me`) !== -1
  ) {
    return {push: logPush}
  }

  return window.uetq || {push: logPush}
}

const logPush = (name, label, params) => {
  if (params && params.event_callback && isFunction(params.event_callback)) {
    console.log(name, label, params)
    params.event_callback()
    return
  }

  console.log(name, label, params)
}
