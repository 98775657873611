import React from "react"
import {
  FEATURE_DATACENTER_COMMITMENT,
  FEATURE_RESIDENTIAL_COMMITMENT,
  FEATURE_MOBILE_COMMITMENT,
  FEATURE_RESIDENTIAL_OVERAGE,
  FEATURE_DATACENTER_OVERAGE,
  FEATURE_MOBILE_OVERAGE,
  FEATURE_IP_TARGETS,
  FEATURE_ALEXA_TOP_TARGETS,
  FEATURE_API_ENRICHMENT,
  FEATURE_API_NEWSMEDIA,
  FEATURE_API_FETCH,
  FEATURE_API_SERP,
  FEATURE_PROXY_CONCURRENCY,
  FEATURE_PROXY_TUNNELING,
  FEATURE_PROXY_MITM, FEATURE_GOOGLE_SEARCH_TARGETS, FEATURE_RESIDENTIAL_RECHARGE, FEATURE_HINTS
} from "../../constants";
import FeatureRow from "./FeatureRow"
import FeatureToggle from "./FeatureToggle"

const ServiceInput = ({ service, onValueChange }) => {
  switch (service.feature) {
    case FEATURE_RESIDENTIAL_COMMITMENT:
    case FEATURE_DATACENTER_COMMITMENT:
    case FEATURE_MOBILE_COMMITMENT:
      return (
        <FeatureRow
          onValueChange={onValueChange}
          {...service}
          {...{ countable: true, billable: true}}
        />
      )
    case FEATURE_RESIDENTIAL_OVERAGE:
    case FEATURE_DATACENTER_OVERAGE:
    case FEATURE_MOBILE_OVERAGE:
      return (
        <FeatureRow
          onValueChange={onValueChange}
          {...service}
          {...{
            countable: true,
            billable: true,
            hint: "Charged when threshold is reached",
          }}
        />
      )
    case FEATURE_API_FETCH:
    case FEATURE_API_SERP:
    case FEATURE_API_ENRICHMENT:
    case FEATURE_IP_TARGETS:
    case FEATURE_ALEXA_TOP_TARGETS:
    case FEATURE_GOOGLE_SEARCH_TARGETS:
    case FEATURE_PROXY_TUNNELING:
    case FEATURE_PROXY_MITM:
      return <FeatureToggle {...service} />
    case FEATURE_PROXY_CONCURRENCY:
      return (
        <FeatureRow
          {...{
            countable: true,
            quantity: 200,
            unit: "Connections",
            hint: "Free of charge",
          }}
          {...service}
        />
      )
    case FEATURE_API_NEWSMEDIA:
      return <FeatureRow {...service} {...{ unit: "Subscription", billable: true }} />
    case FEATURE_RESIDENTIAL_RECHARGE:
      return <FeatureRow {...service} {...{
        countable: true,
        hint: FEATURE_HINTS[service.feature],
      }} />
    default:
      return <span style={{ color: "red" }}>not implemented</span>
  }
}

export default ServiceInput
