import React from "react"
import {
  NumberField,
  TextField,
  DateField
} from "react-admin"
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import FullNameField from "../../FullNameField"

export default ({ record }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Company Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Company</TableCell>
              <TableCell>
                <TextField record={record} source="account.company"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Phone</TableCell>
              <TableCell>
                <TextField record={record} source="account.phone"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>
                <TextField record={record} source="account.streetAddress"/>
                <TextField record={record} source="account.extendedAddress"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City</TableCell>
              <TableCell>
                <TextField record={record} source="account.locality"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>State / Region</TableCell>
              <TableCell>
                <TextField record={record} source="account.region"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>
                <TextField record={record} source="account.country"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postal Code</TableCell>
              <TableCell>
                <TextField record={record} source="account.postalCode"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Apps</TableCell>
              <TableCell>
                <NumberField record={record} source="totalApps"/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      <Grid item md={6} xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>User Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                <FullNameField label="Name" source="account" record={record}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>
                <TextField record={record} source="account.email"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>
                <DateField record={record} source="createdAt"/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Active</TableCell>
              <TableCell>
                <TextField record={record} source="account.isBlocked"/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )
}