import React from 'react'
import { DateField } from 'react-admin'
import moment from "moment"

export default ({ billingPeriod }) => {
  if (!billingPeriod) {
    return null
  }

  const days = moment(billingPeriod.endDate).diff(moment(billingPeriod.startDate), 'days')

  return <>
    <DateField style={{ display: 'inline' }} record={billingPeriod} source="startDate"/>
    {` `}&mdash;{` `}
    <DateField style={{ display: 'inline' }} record={billingPeriod} source="endDate"/>{` `}
    <span>({days} days)</span>
  </>
}