import React from "react"
import { Typography, Card, CardHeader, CardContent } from "@material-ui/core"

export default ({ record: { twilio } }) => {
  if (!twilio) {
    return null
  }
  return <Card>
    <CardHeader subheader="Phone verification results"/>
    <CardContent>
      <Typography variant="caption">Phone number</Typography>
      <Typography paragraph>{twilio.phoneNumber}</Typography>

      <Typography variant="caption">Verified</Typography>
      <Typography paragraph>{twilio.verified ? "yes" : "no"}</Typography>

      <Typography variant="caption">Carrier name</Typography>
      <Typography paragraph>{twilio.carrierName}</Typography>

      <Typography variant="caption">Carrier type</Typography>
      <Typography paragraph>{twilio.carrierType}</Typography>

      <Typography variant="caption">Network code / country code</Typography>
      <Typography paragraph>{twilio.carrierNetworkCode} / { twilio.carrierCountryCode }</Typography>

    </CardContent>
  </Card>
}