import React from 'react'
import get from 'lodash/get'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useGetList } from 'react-admin'
import { getName as getCountryName } from 'country-list';

const CardWorkersByCountrySeries = () => {
  const { loading, data, ids = [] } = useGetList('workersByCountrySeries', { page: 1, perPage: 10 }, { workers: true, workersDesc: true })

  if (loading) {
    return <CircularProgress/>
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Country</TableCell>
          <TableCell align="right">Workers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ids.map((id) => (
            <TableRow key={id}>
              <TableCell align="left">{getCountryName(id)}</TableCell>
              <TableCell align="right">{get(data, [id, 'workers'], 0)}</TableCell>
            </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CardWorkersByCountrySeries
