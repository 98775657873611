import React from "react"
import pure from "recompose/pure"
import get from "lodash/get"
import moment from "moment"

const DateField = pure(({ record = {}, source, size = 25 }) => {
  const date = get(record, source)
  if (!date) {
    return null
  }

  return (
    <div style={{ textAlign: "center" }}>{moment(date).format("MMM-DD")}</div>
  )
})

export default DateField
