import React from "react"
import get from "lodash/get"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import DisplayNameField from "../../../DisplayNameField"
import { Link, withStyles } from "@material-ui/core"

const DisplayNameFieldRaw = ({ classes, record, ...props }) => {
  const externalNoteLink = get(
    record,
    "noteWithExternalLink.items.0.externalLink",
  )

  return (
    <div className={classes.container}>
      <DisplayNameField record={record} {...props} />
      {externalNoteLink && (
        <Link href={externalNoteLink} className={classes.link} target="_blank">
          <OpenInNewIcon className={classes.icon} />
        </Link>
      )}
    </div>
  )
}

const styles = () => ({
  container: {
    display: "flex",
  },
  link: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "18px",
    height: "18px",
  },
})

export default withStyles(styles)(DisplayNameFieldRaw)
