import React from "react"
import pure from "recompose/pure"
import get from "lodash/get"

const MinfraudCreditCardField = pure(({ record = {}, source }) => {
  const cc = get(record, source)
  return (
    cc ? <span>
      {`${cc.country} - ${cc.issuer.name}`}
    </span> : null
  )
})

MinfraudCreditCardField.defaultProps = {
  label: 'Minfraud Credit Card',
  sortable: false,
}

export default MinfraudCreditCardField