import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"
import { GET_LIST, GET_MANY_REFERENCE, UPDATE_MANY } from "react-admin"

export default ({ queryType, params }) => {
  switch (queryType) {
    case GET_LIST:
      return getList({ ...params })
    case GET_MANY_REFERENCE:
      return getManyReference({ ...params })
    case UPDATE_MANY:
      return updateMany({ ...params })
    default:
      throw new Error(`not implemented: invoices ${queryType}`)
  }
}

const cleanUpSortFields = (field) => {
  const sortFieldMap = {
    [field]: field,
    ...{
      "billingPeriod.startDate": "billingPeriodStartDate",
      "billingPeriod.endDate": "billingPeriodEndDate",
    }
  }
  return sortFieldMap[field]
}

const getList = ({ pagination: { page, perPage }, filter: { q: query, type }, sort }) => {
  sort.field = cleanUpSortFields([sort.field])

  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: { query, type: type !== "all" ? type : undefined },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
        query getInvoices($limit: Int!, $offset: Int!, $filter: InvoiceSliceFilter, $sort: InvoiceSliceSorter) {
            invoiceSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                total: totalCount
                data: items {
                    id
                    ref
                    type
                    totalAmount
                    isPaid
                    billingPeriod {
                        id
                        startDate
                        endDate
                        customer {
                            account {
                                id
                                email
                                displayName
                            }
                        }
                    }
                    createdAt
                    updatedAt
                    previewURL
                    url
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { invoiceSlice: { data, total } } }) => {
      return { data, total }
    },
  }
}

const getManyReference = ({ target, id, pagination: { page, perPage } }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: { createdAt: true, createdAtDesc: true },
    filter: {},
  }
  if (target === "customer") {
    return getManyReferenceForCustomer({ ...variables })
  } else {
    throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForCustomer = (variables) => {
  return {
    query: gql`
        query GetCustomerInvoices($id: Int!, $limit: Int!, $offset: Int!, $filter: InvoiceSliceFilter, $sort: InvoiceSliceSorter) {
            customer(id: $id) {
                invoiceSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                    total: totalCount,
                    data: items {
                        id
                        ref
                        type
                        billingPeriod {
                            id
                            startDate
                            endDate
                        }
                        isPaid
                        totalAmount
                        createdAt
                        updatedAt
                        previewURL
                        url
                    }
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { customer: { invoiceSlice: { data, total } } } }) => ({
      data,
      total,
    }),
  }
}

const updateMany = ({ ids, data: input }) => ({
  query: gql`
      mutation ($ids: [Int!], $input: UpdateCustomerInvoiceInput!) {
          data: updateCustomerInvoiceMany(ids: $ids, input: $input) {
              id
          }
      }
  `,
  variables: { ids, input },
  parseResponse: ({ data: { data } }) => ({ data })
})
