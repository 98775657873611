import React from "react"
import { Edit, TabbedForm, FormTab, TextInput, NumberInput } from "react-admin"


export default (props) => (
  <Edit undoable={false} {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Summary">
        <NumberInput source="id" disabled/>
        <TextInput label="App Name" source="name"/>
        <TextInput source="packageName"/>
        <TextInput label="App Region" source="region"/>
        <TextInput label="Playstore URL" source="playstoreURL"/>
        <NumberInput label="$ / 1k Hourly Active Devices" source="pricePer1KHad"/>
        <TextInput label="SDK Version" source="SDKVersion"/>
        <TextInput source="developer"/>
        <TextInput source="developerEmail"/>
        <TextInput source="developerWebsite"/>
        <TextInput source="developerInfo"/>
      </FormTab>
    </TabbedForm>
  </Edit>
)
