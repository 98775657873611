import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { setSidebarVisibility } from "react-admin"

const EnsureClosedSidebarRaw = ({ children, setSidebarVisibility }) => {
  useEffect(() => {
    setSidebarVisibility(false)
  }, [setSidebarVisibility])

  return <Fragment>{children}</Fragment>
}

export default connect(null, { setSidebarVisibility })(EnsureClosedSidebarRaw)
