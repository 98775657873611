import { Tooltip, Typography } from "@material-ui/core"
import React from "react"
import { ErrorOutline as HintIcon } from "@material-ui/icons"

const RechargeHint = ({hint}) => <Tooltip
  title={
    <React.Fragment>
      <Typography color="inherit">{hint}</Typography>
    </React.Fragment>
  }
>
  <HintIcon/>
</Tooltip>

export default RechargeHint