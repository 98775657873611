import { Typography } from "@material-ui/core"
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from "@material-ui/core/Grid"
import ServiceInput from "./ServiceInput"
import React from "react"

const FeaturesGrid = ({ features, title, subtitle }) => <>
  <Grid container direction="column">
    <Grid item>
      <Typography color="secondary" variant="h6" gutterbottom="true">{title}</Typography>
    </Grid>
    {subtitle && <Grid item>
      <FormHelperText color="secondary" variant="h6" gutterbottom="true">{subtitle}</FormHelperText>
    </Grid>}
  </Grid>
  <Grid container direction="row">
    {features.map((s, idx) =>
      <Grid key={idx} item xs={4}>
        <ServiceInput service={s}/>
      </Grid>,
    )}
  </Grid>
</>

export default FeaturesGrid
