import React from 'react'
import gql from 'graphql-tag'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import filesize from "filesize"
import moment from 'moment'
import get from 'lodash/get'
import {compose} from 'recompose'
import { graphql } from "@apollo/client/react/hoc"

const QUERY = gql`
  query BandwidthSeries(
    $now: Time!
    $day: Time!
    $week: Time!
    $month: Time!
    $year: Time!
  ) {
    day: bandwidthSeries(
      filter: { start: $day, end: $now }
      grouper: { link: true }
    ) {
      value
      link
    }
    week: bandwidthSeries(
      filter: { start: $week, end: $now }
      grouper: { link: true }
    ) {
      value
      link
    }
    month: bandwidthSeries(
      filter: { start: $month, end: $now }
      grouper: { link: true }
    ) {
      value
      link
    }
    year: bandwidthSeries(
      filter: { start: $year, end: $now }
      grouper: { link: true }
    ) {
      value
      link
    }
  }
`

const CardBandwidth = ({data: {loading, day, week, month, year}}) => {
  if (loading) {
    return <CircularProgress/>
  }

  const reduceSeries = (series) => {
    return (series || []).reduce((acc, s) => {
      acc[s.link] = s.value;
      return acc;
    }, {})
  }

  day = reduceSeries(day)
  week = reduceSeries(week)
  month = reduceSeries(month)
  year = reduceSeries(year)

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <></>
          </TableCell>
          <TableCell style={{ textAlign: "right"}}>Mobile</TableCell>
          <TableCell style={{ textAlign: "right"}}>Residential</TableCell>
          <TableCell style={{ textAlign: "right"}}>Datacenter</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Last 24-hours</TableCell>
          <TableCell align="right">{filesize(get(day, 'mobile', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(day, 'residential', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(day, 'datacenter', 0), {round: 0})}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last 7-days</TableCell>
          <TableCell align="right">{filesize(get(week, 'mobile', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(week, 'residential', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(week, 'datacenter', 0), {round: 0})}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last 30-days</TableCell>
          <TableCell align="right">{filesize(get(month, 'mobile', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(month, 'residential', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(month, 'datacenter', 0), {round: 0})}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last 12-month</TableCell>
          <TableCell align="right">{filesize(get(year, 'mobile', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(year, 'residential', 0), {round: 0})}</TableCell>
          <TableCell align="right">{filesize(get(year, 'datacenter', 0), {round: 0})}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const enhance = compose(
  graphql(QUERY, {
    options: {
      variables: {
        now: moment().toDate(),
        day: moment().subtract(1, 'day').toDate(),
        week: moment().subtract(1, 'week').toDate(),
        month: moment().subtract(1, 'month').toDate(),
        year: moment().subtract(1, 'year').toDate(),
      },
    },
  }),
)

export default enhance(CardBandwidth)
