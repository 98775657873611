import React from "react"
import { Create, SimpleForm, TextInput, required, email, minLength } from "react-admin"
import { Typography, Grid } from "@material-ui/core"

const defaults = {
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  country: "US",
  region: "",
  postalCode: "",
  locality: "",
  phone: "",
  streetAddress: "",
  role: "partner",
  notifyAccount: false,
  notifyCreator: false,
}


export default ({ ...props }) => {
  const initial = {
    ...defaults,
    // ...these below are useful for debugging
    // handle: faker.internet.userName().toLowerCase(),
    // firstName: faker.name.firstName(),
    // lastName: faker.name.lastName(),
    // company: faker.company.companyName(),
    // email: faker.internet.exampleEmail().toLowerCase(),
    // password: faker.internet.password(),
    // phone: faker.phone.phoneNumber(),
    // postalCode: faker.address.zipCode(),
    // locality: faker.address.city(),
    // streetAddress: faker.address.streetAddress(),
  }
  return <Create {...props}>
    <SimpleForm redirect="show" initialValues={defaults} variant="standard" margin="normal">
      <Details initial={initial}/>
      <ContactDetails initial={initial}/>
    </SimpleForm>
  </Create>
}

const Details = ({ initial }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}><Typography variant="subtitle1" color="primary">Partner details</Typography></Grid>
    <Grid item xs={12}>
      <TextInput
        fullWidth
        source="company"
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <TextInput
        fullWidth
        source="firstName"
        validate={required()}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <TextInput
        fullWidth
        source="lastName"
        validate={required()}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="email"
        validate={[required(), email()]}
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="password"
        type="password"
        autoComplete="new-password"
        validate={[required(), minLength(8)]}
      />
    </Grid>
  </Grid>
)


const ContactDetails = ({initial}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}><Typography variant="subtitle1" color="primary">Contact details</Typography></Grid>
    <Grid item xs={9}>
      <TextInput
        fullWidth
        source="streetAddress"
      />
    </Grid>
    <Grid item xs={3}>
      <TextInput
        fullWidth
        source="country"
      />
    </Grid>
    <Grid item xs={6}>
      <TextInput
        fullWidth
        source="locality"
      />
    </Grid>
    <Grid item xs={3}>
      <TextInput
        fullWidth
        source="region"
      />
    </Grid>
    <Grid item xs={3}>
      <TextInput
        fullWidth
        source="postalCode"
      />
    </Grid>
  </Grid>
)