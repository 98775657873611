import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel, CardHeader,
  Typography, Card, CardContent,
} from "@material-ui/core"
import RejectIcon from "@material-ui/icons/Delete"
import ApproveIcon from "@material-ui/icons/Check"
import Money from '@material-ui/icons/AttachMoney';
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import get from "lodash/get"
import gql from "graphql-tag"
import moment from "moment"
import {useRedirect} from "react-admin"
import { countConversion } from "../../../gtagConversions";

const LoadingDialog = ({ isOpen }) => (
  <Dialog open={isOpen} disableBackdropClick={true}>
    <DialogContent>
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress variant="indeterminate" />
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

const CONFIRM_DIALOG_TYPE = {
  APPROVE: "approve",
  REJECT: "reject",
  PREPAID: "prepaid",
};

const ConfirmDialog = ({ account, title, isOpen, onClose, onConfirm, onCancel, initialNotify, type }) => {
  const [notify, setNotify] = useState(initialNotify);
  const toggleNotify = () => {
    setNotify(!notify);
  };

  const handleClose = () => {
    setNotify(initialNotify);
    onClose();
  };

  const handleCancel = () => {
    setNotify(initialNotify);
    onCancel();
  };

  const handleConfirm = () => {
    onConfirm(notify);
  };

  return <Dialog open={isOpen} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {type === CONFIRM_DIALOG_TYPE.APPROVE && (
        <FormControlLabel
          control={
            <Checkbox
              checked={notify}
              onChange={toggleNotify}
              color="primary"
            />
          }
          label={<Typography>
            Send out notification to <a href={`mailto:${get(account, "email")}`}>{get(account, "email")}</a>?
          </Typography>}
        />
      )}
      {type === CONFIRM_DIALOG_TYPE.REJECT && (
        <Typography>
          Are you sure that you want to reject <a href={`mailto:${get(account, "email")}`}>{get(account, "email")}</a>?
        </Typography>
      )}
      {type === CONFIRM_DIALOG_TYPE.PREPAID && (
        <FormControlLabel
        control={
          <Checkbox
            checked={notify}
            onChange={toggleNotify}
            color="primary"
          />
        }
        label={<Typography>
          Send out notification to <a href={`mailto:${get(account, "email")}`}>{get(account, "email")}</a>?
        </Typography>}
      />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus>Confirm</Button>
      <Button onClick={handleCancel} color="primary">Cancel</Button>
    </DialogActions>
  </Dialog>;
};

const Action = ({ account, icon: Icon, label, title, color, onConfirm, initialNotify, loading, confirmDialogType }) => {
  const [isOpen, setOpen] = useState(false);
  const toggleConfirmOpen = () => {
    setOpen(!isOpen);
  };

  return <>
    <LoadingDialog isOpen={loading} />
    <ConfirmDialog
      account={account}
      title={title}
      isOpen={isOpen && !loading}
      onClose={toggleConfirmOpen}
      onCancel={toggleConfirmOpen}
      onConfirm={onConfirm}
      initialNotify={initialNotify}
      type={confirmDialogType}
    />
    <Button
      size="large"
      variant="contained"
      color={color}
      onClick={toggleConfirmOpen}
    >
      <Icon /> {label}
    </Button>
  </>;
};

const approveEnhancer = compose(
  graphql(gql`
    mutation ApproveSignup($id: Int!, $notify: Boolean!) {
      approveSignup(id: $id, input: { notifyAccount: $notify }) { 
        id
        plan {
          id
          slug
          price
        }
        conversion {
          id
          createdAt
          clkid
          source
          keyword
          campaign
        }
        account { 
          id 
        } 
      }
    }
  `)
);


const freePlanEnhancer = compose(
  graphql(gql`
    mutation FreePlanSignup($id: Int!, $notify: Boolean!) {
      freePlanSignup(id: $id, input: { notifyAccount: $notify }) { 
        id
        plan {
          id
          slug
          price
        }
        account { 
          id 
        } 
      }
    }
  `)
);

const Approve = approveEnhancer(({ account, mutate }) => {
  const [loading, setLoading] = useState(false);
  const redirectTo = useRedirect();
  return <Action
    account={account}
    icon={ApproveIcon}
    label="Approve"
    title="Confirm signup approval"
    color="secondary"
    initialNotify={true}
    loading={loading}
    confirmDialogType={CONFIRM_DIALOG_TYPE.APPROVE}
    onConfirm={(notify) => {
      setLoading(true);
      mutate(
        { variables: { id: account.id, notify } }
      ).then(
        ({
           data: {
             approveSignup: {
               account:
                 { id: accountID }, id: signupID, plan, conversion
             }
           }
         }) => {
          countConversion(conversion, plan, account, signupID, () => {
            setTimeout(() => {
              redirectTo(`/customers/${accountID}/show`);
            }, 400);
          });
        }
      ).catch(
        (e) => {
          throw e;
        }
      ).finally(
        () => setLoading(false)
      );
    }}
  />;
});

const rejectEnhancer = compose(
  graphql(gql`
    mutation RejectSignup($id: Int!) {
      rejectSignup(id: $id) { id }
    }
  `)
);

const Reject = rejectEnhancer(({ account, mutate }) => {
  const [loading, setLoading] = useState(false);
  const redirectTo = useRedirect();
  return <Action
    account={account}
    icon={RejectIcon}
    label="Reject"
    title="Reject signup"
    color="primary"
    variant="contained"
    loading={loading}
    confirmDialogType={CONFIRM_DIALOG_TYPE.REJECT}
    onConfirm={() => {
      setLoading(true);
      mutate(
        { variables: { id: account.id } }
      ).then(
        () => {
          redirectTo(`/signups`);
        }
      ).catch(
        (e) => {
          throw e;
        }
      ).finally(
        () => setLoading(false)
      );
    }}
  />;
});


const ToPrepaid = freePlanEnhancer(({ account, mutate }) => {
  const [loading, setLoading] = useState(false);
  const redirectTo = useRedirect();
  return <Action
    account={account}
    icon={Money}
    label="To Prepaid"
    title='Bind user to Prepaid "Free" plan'
    color="secondary"
    initialNotify={true}
    loading={loading}
    confirmDialogType={CONFIRM_DIALOG_TYPE.PREPAID}
    onConfirm={(notify) => {
      setLoading(true);
      mutate(
        { variables: { id: account.id, notify } }
      ).then(
          ({
            data: {
              freePlanSignup: {
                account:
                  { id: accountID }, id: signupID, plan, conversion
              }
            }
          }) => {
              redirectTo(`/customers/${accountID}/show`);
          })
        .catch((e) => {throw e;})
        .finally(() => setLoading(false));
    }}  
  />;
});

const DecisionMade = ({ account }) => (
  <Card>
    <CardContent>
      <Typography color="secondary" variant="h5" align="right"
                  style={{ textTransform: "capitalize" }}>{account.disposition}</Typography>
      <Typography color="secondary" variant="subtitle1" align="right">
        at {moment(account.updatedAt).format("ll")}
      </Typography>
    </CardContent>
  </Card>
);

const DecisionPending = ({ account }) => (
  <Card>
    <CardHeader subheader="Signup aproving"/>
  <CardContent>
  <Grid container spacing={2} alignItems="stretch" justifyContent="center">
    <Grid item>
      <Approve account={account} />
    </Grid>
    <Grid item>
      <ToPrepaid account={account} />
    </Grid>
    <Grid item>
      <Reject account={account} />
    </Grid>
  </Grid>
  </CardContent>
  </Card>
);

export default ({ record: account }) => {
  if (!account) {
    return null;
  }
  return (account && account.disposition === "pending") ?
    <DecisionPending account={account} /> :
    <DecisionMade account={account} />;
}
