import React, { useState } from "react"
import gql from "graphql-tag"
import { useAuthState, Pagination } from "react-admin"
import { Query } from "@apollo/client/react/components"
import CustomersList from "./components/CustomersList"
import EnsureClosedSidebar from "./components/EnsureClosedSidebar"
import { CircularProgress } from "@material-ui/core"

const CUSTOMERS_PER_PAGE_DEFAULT = 100
const CUSTOMERS_PER_PAGE_OPTIONS = [25, 50, CUSTOMERS_PER_PAGE_DEFAULT]

const DEFAULT_FILTER = { subscriptionStatuses: ["free", "active", "trial"] }

const ORDER = {
  ASC: "ASC",
  DESC: "DESC",
}

const mapSortToSorter = (sort) => {
  const sortArr = sort.field.split(".")
  const sorterField = sortArr[sortArr.length - 1]
  const sorterDescField = `${sorterField}Desc`

  return { [sorterField]: true, [sorterDescField]: sort.order !== ORDER.ASC }
}

const ActiveCustomers = ({
  rowsPerPage = CUSTOMERS_PER_PAGE_DEFAULT,
  paginate = true,
  title,
}) => {
  const {loading } = useAuthState()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(rowsPerPage)
  const [sort, setSort] = useState({
    field: "customerDetails.bandwidth24Hours",
    order: ORDER.DESC,
  })

  if (!!loading) {
    return <CircularProgress />
  }

  const onSort = (e) =>
    setSort({
      field: e.currentTarget.dataset.sort,
      order:
        sort.field === e.currentTarget.dataset.sort && sort.order === ORDER.DESC
          ? ORDER.ASC
          : ORDER.DESC,
    })

  return (
    <Query
      query={QUERY}
      variables={{
        limit: perPage,
        offset: (page - 1) * CUSTOMERS_PER_PAGE_DEFAULT,
        filter: DEFAULT_FILTER,
        sorter: mapSortToSorter(sort),
        invoiceSorter: { createdAt: true, createdAtDesc: true },
      }}
    >
      {(props) => (
        <React.Fragment>
          <CustomersList
            {...props}
            currentSort={sort}
            setSort={onSort}
            title={title}
          />
          {paginate &&
            props.data &&
            props.data.customerSlice &&
            !props.loading && (
              <Pagination
                page={page}
                rowsPerPageOptions={CUSTOMERS_PER_PAGE_OPTIONS}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                total={props.data.customerSlice.totalCount}
              />
            )}
        </React.Fragment>
      )}
    </Query>
  )
}

const QUERY = gql`
  query ActiveCustomers(
    $limit: Int!
    $offset: Int!
    $filter: CustomerSliceFilter
    $sorter: CustomerSliceSorter
    $invoiceFilter: InvoiceSliceFilter
    $invoiceSorter: InvoiceSliceSorter
  ) {
    customerSlice(
      limit: $limit
      offset: $offset
      filter: $filter
      sort: $sorter
    ) {
      items {
        id
        handle
        account {
          id
          email
          displayName
          lastLogin
          createdAt
        }
        customerDetails {
          subscriptionStatus
          lastProxyUsage
          bandwidth1Year
          bandwidth30Days
          bandwidth7Days
          bandwidth24Hours
          serpRequests24Hours
          serpRequests7Days
          serpRequests30Days
          serpRequests1Year
          requests24Hours
          requests7Days
          requests30Days
          requests1Year
        }
        notes: customerNoteSlice(limit: 1, offset: 0) {
          items {
            id
            body
            externalLink
            createdAt
          }
        }
        noteWithExternalLink: customerNoteSlice(
          limit: 1
          offset: 0
          filter: { externalLinkNEQ: "" }
        ) {
          items {
            id
            body
            externalLink
            createdAt
          }
        }
        invoices: invoiceSlice(
          limit: 1
          offset: 0
          filter: $invoiceFilter
          sort: $invoiceSorter
        ) {
          items {
            id
            totalAmount
          }
        }
      }
      totalCount
    }
  }
`

export default ActiveCustomers

export const ActiveCustomersPage = (props) => (
  <EnsureClosedSidebar>
    <ActiveCustomers {...props} />
  </EnsureClosedSidebar>
)
