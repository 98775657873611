import { createTheme } from "@material-ui/core/styles"

const primary = {
  main: "#627C85",
}
const secondary = {
  main: "#08605F",
}
const error = {
  main: "#FE5E41",
}
const warning = {
  main: "#F7B267",
}

export default createTheme({
  palette: {
    primary,
    secondary,
    error,
    warning,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    body: {},
    RaLogin: {
      main: {
        backgroundImage: "none",
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: "white",
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: 100 + "%",
      },
      root: {
        padding: "16px",
      }
    },
    MuiCardContent: {
      root: {
        minWidth: 400 + "px",
      },
    },
    MuiMenuItem: {
      root: {
        paddingBottom: "12px",
        paddingTop: "12px",
      }
    },
    MuiTableCell: {
      root: {
        textAlign: null,
        paddingRight: 16 + "px",
      },
      head: {
        textAlign: null,
      },
      body: {
        textAlign: null,
      },
      footer: {
        textAlign: null,
      },
    },

  },
})
