import React from "react"
import ContractFeatures from "./ContractFeatures"
import get from 'lodash/get'

const ContractEdit = ({record}) => {
  const {contract} = record
  const plan = get(contract, "plan.slug", "custom")

  return <ContractFeatures
    defaultPlan={plan}
    defaultFeatures={contract.features}/>
}

export default ContractEdit
