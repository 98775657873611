import get from "lodash/get"
import React from "react"
import gql from "graphql-tag"
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from "@material-ui/core"
import { Link } from "react-admin"
import { withStyles } from "@material-ui/core"
import { stringify } from "query-string"
import { useQuery } from "@apollo/client";

const SIGNUPS_BY_IP = gql`
  query SignupSlice($limit: Int!, $offset: Int!, $filter: SignupSliceFilter) {
    signupSlice(limit: $limit, offset: $offset, filter: $filter) {
      totalCount
    }
  }
`

const SimilarAccounts = ({
  data: { signupSlice = {} } = {},
  loading,
  error,
  classes,
}) => {
  if (loading || error) {
    return <Typography align="right">Loading...</Typography>
  }

  return (
    <Typography
      align="right"
      className={signupSlice.totalCount > 0 ? classes.bold : ""}
      color={signupSlice.totalCount > 0 ? "error" : "textPrimary"}
    >
      {signupSlice.totalCount}
    </Typography>
  )
}

const MinfraudIPInfo = ({ id, ip, classes }) => {
  const { loading, error, data } = useQuery(SIGNUPS_BY_IP,
    { variables: { limit: 10, offset: 0, filter: { idNEQ: id, ip }} }
  )
  return <React.Fragment>
    <Grid item xs={12}>
      <SimilarAccounts
        data={data}
        error={error}
        loading={loading}
        classes={classes}
      />
    </Grid>
    <Grid item xs={12}>
      <Typography color="textSecondary" align="right">
        Other signups from this IP
      </Typography>
    </Grid>
  </React.Fragment>
}

export const MinfraudScoreRaw = ({ record: { id, minfraud }, classes }) => {
  const ip = get(minfraud, "response.ipAddress.traits.ipAddress")
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  {!minfraud && "No risk info available"}
                  {minfraud &&
                    minfraud.retry !== 0 &&
                    "Risk assessment pending"}
                  {minfraud &&
                    minfraud.retry === 0 &&
                    minfraud.response.riskScore}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Overall Risk Score
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Link
              className={classes.link}
              to={{
                pathname: `/signups`,
                search: stringify({
                  page: 1,
                  perPage: 25,
                  filter: JSON.stringify({
                    disposition: "all",
                    ip,
                  }),
                }),
              }}
            >
              <Grid container>
                {ip && (
                  <MinfraudIPInfo ip={ip} id={id} classes={classes}/>
                )}
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const style = () => ({
  link: {
    textDecoration: "none",
  },
  bold: {
    fontWeight: 600,
  },
})

export const MinfraudScore = withStyles(style)(MinfraudScoreRaw)

export const MinfraudBillingAddress = ({ record: { minfraud } }) => {
  if (!minfraud) {
    return null
  }
  const {
    response: { billingAddress },
  } = minfraud
  return (
    <Card>
      <CardHeader subheader="Billing Address" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Lat Lon</TableCell>
              <TableCell align="right">
                <Typography>
                  <a
                    href={`https://www.google.com/maps/@?api=1&map_action=map&center=${get(
                      billingAddress,
                      "latitude",
                    )},${get(billingAddress, "longitude")}&zoom=9`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {get(billingAddress, "latitude")},{" "}
                    {get(billingAddress, "longitude")}
                  </a>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Distance to IP location</TableCell>
              <TableCell align="right">
                {get(billingAddress, "distanceToIPLocation")} km
              </TableCell>
            </TableRow>
            {!get(billingAddress, "isPostalInCity") ||
            !get(billingAddress, "isInIPCountry") ? (
              <TableRow>
                <TableCell colSpan={2} align="right">
                  {!get(billingAddress, "isPostalInCity") ? (
                    <Typography color="primary">
                      Postal code not in the city
                    </Typography>
                  ) : null}
                  {!get(billingAddress, "isInIPCountry") ? (
                    <Typography color="primary">Not in IP country</Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export const MinfraudCreditCard = ({
  record: {
    minfraud: { request, response },
  },
}) => {
  if (!request || !response) {
    return null
  }
  const { creditCard: ccRequest } = request
  const { creditCard: ccResponse } = response
  return (
    <Card>
      <CardHeader subheader="Credit Card" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="right" colSpan={2}>
                {get(ccResponse, "brand")}{" "}
                {get(ccRequest, "issuerIDNumber").slice(0, 4)}{" "}
                {get(ccRequest, "issuerIDNumber").slice(4, 6)}** ****{" "}
                {get(ccRequest, "last4Digits")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell align="right">{get(ccResponse, "type")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issuer</TableCell>
              <TableCell align="right">
                {get(ccResponse, "issuer.name")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell align="right">{get(ccResponse, "country")}</TableCell>
            </TableRow>
            {!get(ccResponse, "isIssuedInBillingAddressCountry") ||
            get(ccResponse, "isPrepaid") ||
            get(ccResponse, "isVirtual") ? (
              <TableRow>
                <TableCell colSpan={2} align="right">
                  {!get(ccResponse, "isIssuedInBillingAddressCountry") ? (
                    <Typography color="primary">
                      Is Not Issued In Billing Address Country
                    </Typography>
                  ) : null}
                  {get(ccResponse, "isPrepaid") ? (
                    <Typography color="primary">Prepaid</Typography>
                  ) : null}
                  {get(ccResponse, "isVirtual") ? (
                    <Typography color="primary">Virtual</Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export const MinfraudEmail = ({ record: { minfraud } }) => {
  if (!minfraud) {
    return null
  }
  const {
    response: { email },
  } = minfraud
  return (
    <Card>
      <CardHeader subheader="Email" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>First seen</TableCell>
              <TableCell align="right">{get(email, "firstSeen")}</TableCell>
            </TableRow>
            {get(email, "isHighRisk") || get(email, "isFree") ? (
              <TableRow>
                <TableCell colSpan={2} align="right">
                  {get(email, "isHighRisk") ? (
                    <Typography color="primary">High Risk</Typography>
                  ) : null}
                  {get(email, "isFree") ? (
                    <Typography color="primary">Free</Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export const MinfraudIPAddress = ({ record: { minfraud } }) => {
  if (!minfraud) {
    return null
  }
  const {
    response: { ipAddress },
  } = minfraud
  return (
    <Card>
      <CardHeader subheader="IP Address" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="right">
                <Typography variant="body1" color="textSecondary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ipinfo.io/${get(
                      ipAddress,
                      "traits.ipAddress",
                    )}`}
                  >
                    {get(ipAddress, "traits.ipAddress")}
                  </a>
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Autonomous System</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1">
                  {get(ipAddress, "traits.autonomousSystemNumber")}
                  {get(ipAddress, "traits.autonomousSystemOrganization")}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ISP</TableCell>
              <TableCell align="right">
                {get(ipAddress, "traits.isp")}
              </TableCell>
            </TableRow>
            {get(ipAddress, "traits.isAnonymous") ||
            get(ipAddress, "traits.isPublicProxy") ||
            get(ipAddress, "traits.isTorExitNode") ||
            get(ipAddress, "traits.isAnonymousVPN") ||
            get(ipAddress, "traits.isHostingProvider") ? (
              <TableRow>
                <TableCell align="right" colSpan={2}>
                  {get(ipAddress, "traits.isAnonymous") ? (
                    <Typography color="primary">Anonymous</Typography>
                  ) : null}
                  {get(ipAddress, "traits.isPublicProxy") ? (
                    <Typography color="primary">Public Proxy</Typography>
                  ) : null}
                  {get(ipAddress, "traits.isTorExitNode") ? (
                    <Typography color="primary">Tor Exit Node</Typography>
                  ) : null}
                  {get(ipAddress, "traits.isAnonymousVPN") ? (
                    <Typography color="primary">Anonymous VPN</Typography>
                  ) : null}
                  {get(ipAddress, "traits.isHostingProvider") ? (
                    <Typography color="primary">Hosting Provider</Typography>
                  ) : null}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>Risk</TableCell>
              <TableCell align="right">
                <Typography style={{ fontSize: 120 + "%" }}>
                  {get(ipAddress, "risk")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export const MinfraudIPLocation = ({ record: { minfraud } }) => {
  const {
    response: { ipAddress },
  } = minfraud
  return (
    <Card>
      <CardHeader subheader="IP Location" />
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Lat Lon</Typography>
                <Typography color="textSecondary">(accuracy)</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>
                  <a
                    href={`https://www.google.com/maps/@?api=1&map_action=map&center=${get(
                      ipAddress,
                      "location.latitude",
                    )},${get(ipAddress, "location.longitude")}&zoom=9`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {get(ipAddress, "location.latitude")},{" "}
                    {get(ipAddress, "location.longitude")}
                  </a>
                </Typography>
                <Typography color="textSecondary">
                  {get(ipAddress, "location.accuracyRadius")} km
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Local time</Typography>
              </TableCell>
              <TableCell align="right">
                {get(ipAddress, "location.localTime")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Country</Typography>
                <Typography color="textSecondary">(confidence)</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{get(ipAddress, "country.names.en")}</Typography>
                <Typography color="textSecondary">
                  ({get(ipAddress, "country.confidence")})
                </Typography>
                {get(ipAddress, "country.isHighRisk") ? (
                  <Typography color="primary">High Risk</Typography>
                ) : null}
              </TableCell>
            </TableRow>
            {get(ipAddress, "subdivisions.0") ? (
              <TableRow>
                <TableCell>
                  <Typography>Subdivision</Typography>
                  <Typography color="textSecondary">(confidence)</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {get(ipAddress, "subdivisions.0.names.en")}
                  </Typography>
                  <Typography color="textSecondary">
                    ({get(ipAddress, "subdivisions.0.confidence")})
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
            {get(ipAddress, "city") ? (
              <TableRow>
                <TableCell>
                  <Typography>City</Typography>
                  <Typography color="textSecondary">(confidence)</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{get(ipAddress, "city.names.en")}</Typography>
                  <Typography color="textSecondary">
                    ({get(ipAddress, "city.confidence")})
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
            {get(ipAddress, "postal") ? (
              <TableRow>
                <TableCell>
                  <Typography>Postal Code</Typography>
                  <Typography color="textSecondary">(confidence)</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>{get(ipAddress, "postal.code")}</Typography>
                  <Typography color="textSecondary">
                    ({get(ipAddress, "postal.confidence")})
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
