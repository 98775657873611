import React from "react"
import { Create, SimpleForm, TextInput, NumberInput, ReferenceInput, AutocompleteInput } from "react-admin"

export default ({...props}) => (
  <Create {...props}>
    <SimpleForm redirect="show" variant="standard" margin="normal">
      <ReferenceInput label="Partner" source="partner" reference="partners" sort={{ field: "createdAt", order: "DESC" }} perPage={1000}>
        <AutocompleteInput allowEmpty fullWidth resettable optionText="account.displayName" optionValue="id"/>
      </ReferenceInput>
      <TextInput label="App Name" source="name"/>
      <TextInput label="Playstore URL" source="playstoreURL"/>
      <NumberInput source="pricePer1KHad"/>
    </SimpleForm>
  </Create>
)