import React from "react"
import pure from "recompose/pure"
import get from "lodash/get"

const BooleanYesNoField = ({ record, source }) => (
  record && <div>
    {get(record, source) && "Yes"}
    {get(record, source) === false && "No"}
    {get(record, source) === undefined && ""}
  </div>
)

const PureBooleanYesNoField = pure(BooleanYesNoField)

PureBooleanYesNoField.defaultProps = {
  sortable: true,
}

export default PureBooleanYesNoField
