import React from "react"
import { Grid } from "@material-ui/core"
import { TextInput } from "react-admin"

const CustomerNoteForm = ({ type, disabled }) => (
  <Grid container direction="column" alignItems="center">
    <TextInput
      source="body"
      type="text"
      label="Content"
      disabled={disabled}
      fullWidth
      multiline
    />
    <TextInput
      source="externalLink"
      type="text"
      label="External link"
      disabled={disabled}
      fullWidth
    />
  </Grid>
)

export default CustomerNoteForm
