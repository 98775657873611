import React from "react"
import { EditButton } from "react-admin"

import {
  CardActions,
  withStyles,
} from "@material-ui/core"
import { ImpersonateButton } from "../../../../components"
import IssueApiToken from "../../../../components/IssueAPIToken"
import ChangeAccountPassword from "../../../../components/ChangeAccountPassword";

const styles = () => ({
  actions: {
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
})

export default withStyles(styles)(({ classes, basePath, data }) => (
  data ? <CardActions className={classes.actions}>
    <IssueApiToken account={data.account}/>
    <ImpersonateButton record={data} source="account" type="account"/>
    <ChangeAccountPassword basePath={basePath} record={data.account} />
    <EditButton basePath={basePath} record={data}/>
  </CardActions> : null
))