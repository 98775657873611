import React from "react"
import StackGrid from "react-stack-grid"
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  UrlField,
} from "react-admin"
import { Grid } from "@material-ui/core"
import Actions from "./Actions"
import Developer from "./Developer"
import Stats from "./Stats"
import Decision from "./Decision"

import CurrencyField from "../../CurrencyField"

const Aside = ({ record }) => {
  if (!record) return null
  return <div style={{ marginLeft: 1 + "rem", marginRight: 1 + "rem", minWidth: 60 + "rem" }}>
    <Grid container spacing={2} alignItems="baseline" justifyContent="flex-start" direction="row">
      <Grid item xs={12}>
        <StackGrid columnWidth={`${100}%`} duration={0} gutterHeight={16} gutterWidth={16}>
          <Decision record={record}/>
          <Stats record={record}/>
          <Developer record={record}/>
        </StackGrid>
      </Grid>
    </Grid>
  </div>
}

export default (props) => (
  <Show aside={<Aside/>} actions={<Actions/>} {...props}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <TextField source="packageName"/>
      <UrlField label="Playstore URL" source="playstoreURL"/>
      <TextField label="Status" source="integrationStatus"/>
      <TextField source="region"/>
      <DateField source="appUpdated"/>
      <NumberField label="Average DAU" source="averageDAU"/>
      <CurrencyField label="$ / 1k Hourly Active Devices" source="pricePer1KHad" options={{ maximumFractionDigits: 4 }} addLabel/>
      <TextField label="SDK Version" source="SDKVersion"/>
    </SimpleShowLayout>
  </Show>
)
