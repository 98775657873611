import React, { useState } from "react"
import {
  Datagrid,
  ReferenceManyField,
  DateField,
  TextField,
} from "react-admin"
import { Grid, Button, withStyles } from "@material-ui/core"
import IDField from "../../IDField"
import NoteActionButtonsField from "../components/NoteActionButtonsField"
import ExternalLinkField from "../components/ExternalLinkField";
import FullNameField from "../../FullNameField"
import CreateIcon from "@material-ui/icons/Add"

import Pagination from "../../Pagination";
import EditNoteDialog, { TYPE as DIALOG_ACTION_TYPE } from '../../customerNotes/components/EditDialog'

const Notes = (props) => {
  const [isCreateNoteDialogOpen, setCreateNoteDialogOpen] = useState(false)
  const [selectedNoteToEdit, setSelectedNoteToEdit] = useState(null)
  const [selectedNoteToDelete, setSelectedNoteToDelete] = useState(null)

  const toggleCreateNoteDialog = () => setCreateNoteDialogOpen(!isCreateNoteDialogOpen);
  const toggleEditNoteDialog = () => setSelectedNoteToEdit(null);
  const toggleDeleteNoteDialog = () => setSelectedNoteToDelete(null);

  const { record, recordCallback } = props
  if (!!recordCallback) {
    recordCallback(record)
  }

  return (
      <React.Fragment>
        <Grid container justifyContent="flex-end">
          <Button color="primary" onClick={toggleCreateNoteDialog}><CreateIcon />Create</Button>
        </Grid>
        <ReferenceManyField
            reference="customerNotes"
            target="customer"
            pagination={<Pagination/>}
            perPage={25}
            {...props}
        >
          <Datagrid>
            <IDField source="id" />
            <FullNameField source="author" label="Author" />
            <TextField source="body" label="Content" className={props.classes.bodyField} />
            <ExternalLinkField source="externalLink" label="External link" />
            <DateField source="updatedAt" label="Updated at" showTime />
            <DateField source="createdAt" label="Created at" showTime />
            <NoteActionButtonsField onEdit={setSelectedNoteToEdit} onDelete={setSelectedNoteToDelete} label="Actions" />
          </Datagrid>
        </ReferenceManyField>

        {isCreateNoteDialogOpen && (
            <EditNoteDialog
              type={DIALOG_ACTION_TYPE.CREATE}
              record={{}}
              customerID={record.id}
              onClose={toggleCreateNoteDialog}
            />
        )}

        {selectedNoteToEdit && (
            <EditNoteDialog
                type={DIALOG_ACTION_TYPE.EDIT}
                record={selectedNoteToEdit}
                customerID={record.id}
                onClose={toggleEditNoteDialog}
            />
        )}

        {selectedNoteToDelete && (
            <EditNoteDialog
                type={DIALOG_ACTION_TYPE.DELETE}
                record={selectedNoteToDelete}
                customerID={record.id}
                onClose={toggleDeleteNoteDialog}
            />
        )}
      </React.Fragment>)
}

const styles = {
  bodyField: {
    whiteSpace: 'break-spaces',
  },
};

export default withStyles(styles)(Notes)
