import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { get } from "lodash";
import {
  maxValue,
  minValue,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  FormDataConsumer
} from "react-admin";
import MonthlyCell from "./MonthlyCell";
import HintCell from "./RechargeHint";
import { withStyles } from "@material-ui/styles";
import { getFeatureDescription, getFeatureFromDescription } from "./utils";
import { useForm } from "react-final-form";
import isFunction from "lodash/isFunction";
import UnitCell from "./UnitCell";
import { BANDWIDTH_FEATURES, RECHARGE_FEATURES } from "../../constants";

const FeatureRow = ({ classes, source, billable, countable, hint, onValueChange, ...record }) => {
  const form = useForm();

  const onChangeValueWrapper = (feature, name) => {
    return (e) => {
      const value = get(e, "target.value", e);
      form.change(name, value);
      if (isFunction(onValueChange)) {
        onValueChange(feature, name, value);
      }
    };
  };

  return (
    <TableRow>
      <TableCell padding="none" size="small" alight="right">
        <BooleanInput
          defaultValue={get(record, "active")}
          source={`${source}.active`}
          onChange={onChangeValueWrapper(get(record, "feature"), `${source}.active`)}
          label=""
        />
      </TableCell>
      <TableCell padding="none" size="small" alight="right">
        <TextInput
          defaultValue={get(record, "feature")}
          source={`${source}.feature`}
          label=""
          format={getFeatureDescription}
          parse={getFeatureFromDescription}
          variant="standard"
          margin="none"
          size="small"
          disabled
        />
      </TableCell>
      <TableCell padding="none" size="small" alight="right">{billable &&
        <FormDataConsumer>
          {({ formData }) =>
            <NumberInput
              defaultValue={get(record, "unitPrice")}
              source={`${source}.unitPrice`}
              variant="standard"
              margin="none"
              size="small"
              label=""
              disabled={!get(formData, `${source}.active`)}
              onChange={onChangeValueWrapper(get(record, "feature"), `${source}.unitPrice`)}
              validate={[required(), minValue(0), maxValue(15000)]} />}
        </FormDataConsumer>
      }</TableCell>
      <TableCell padding="none" size="small" alight="right">{(countable || billable) &&
        <FormDataConsumer>
          {({ formData }) =>
            <NumberInput
              defaultValue={get(record, "quantity")}
              source={`${source}.quantity`}
              variant="standard"
              margin="none"
              size="small"
              label=""
              disabled={!get(formData, `${source}.active`) || !countable}
              onChange={onChangeValueWrapper(get(record, "feature"), `${source}.quantity`)}
              validate={[required(), minValue(0), maxValue(25000)]} />}
        </FormDataConsumer>
      }</TableCell>
      <TableCell padding="none" size="small">
        {[...BANDWIDTH_FEATURES, ...RECHARGE_FEATURES].indexOf(get(record, `feature`)) === -1 &&
          <span>{get(record, `unit`)}</span>
        }
        {
          [...BANDWIDTH_FEATURES, ...RECHARGE_FEATURES].indexOf(get(record, `feature`)) !== -1 &&
          <FormDataConsumer>
            {({ formData }) =>
              <UnitCell
                record={record}
                source={`${source}.unit`}
                defaultValue={get(record, `unit`, 'gb').toLowerCase()}
                active={get(formData, `${source}.active`)}
                onChange={onChangeValueWrapper(get(record, "feature"), `${source}.unit`)}
              />
            }
          </FormDataConsumer>
        }
      </TableCell>
      {!!hint && <TableCell padding="none" size="small" alight="right">
        <HintCell hint={hint} />
      </TableCell>}
      {!hint && <TableCell padding="none" size="small" alight="right">
        <FormDataConsumer>
          {({ formData }) => <MonthlyCell source={source} formData={formData} />}
        </FormDataConsumer>
      </TableCell>}
    </TableRow>
  );
};

const styles = (theme) => ({
  cell: {
    padding: theme.spacing(0),
    borderBottom: "none"
  }
});

export default withStyles(styles)(FeatureRow);
