import Tooltip from '@material-ui/core/Tooltip';
import get from "lodash/get"
import pure from "recompose/pure"
import React from "react"

const TextWithTooltipField = ({ record = {}, source}) => {
  const text = get(record, source, "")

  return (
    record ? <Tooltip title={text}>
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {`${text.substring(0, 25)}${text.length > 25 ? "..." : ""}`}
      </div>
    </Tooltip> : null
  )
}

const PureTextWithTooltipField = pure(TextWithTooltipField)

PureTextWithTooltipField.defaultProps = {
  sortable: false,
}

export default PureTextWithTooltipField