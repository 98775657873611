import {
  CREATE,
  GET_ONE,
  GET_LIST,
  UPDATE,
  GET_MANY_REFERENCE,
} from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case CREATE:
      return create({ ...params })
    case UPDATE:
      return update({ params, resource })
    case GET_ONE:
      return getOne({ ...params })
    case GET_LIST:
      return getList({ ...params })
    case GET_MANY_REFERENCE:
      return getManyReference({...params})
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}

export const create = ({ data }) => {
  const input = { ...data}
  return {
    query: gql`
        mutation CreatePartnerApp ($input: CreatePartnerAppInput!) {
            createPartnerApp(input: $input) {
                id
            }
        }
    `,
    variables: { input: input },
    parseResponse: (response) => ({
      data: response.data.createPartnerApp,
    }),
  }
}

const getOne = ({ id }) => ({
  query: gql`
      query PartnerApp($id: Int!) {
          partnerApp(id: $id) {
              id
              updatedAt
              name
              packageName
              developer
              developerEmail
              developerWebsite
              developerInfo
              reviews
              starRating
              playstoreURL
              installs
              size
              appUpdated
              permissions
              averageDAU
              integrationStatus
              SDKVersion
              pricePer1KHad
          }
      }`,
  variables: { id: id },
  parseResponse: ({ data: { partnerApp } }) => ({ data: partnerApp })
})

const getList = ({ pagination: { page, perPage }, filter: { integrationStatus }, sort }) => {
  const variables = {
    limit: perPage,
    offset: (page - 1) * perPage,
    filter: {
      integrationStatus,
    },
    sort: querySortHelper(sort),
  }
  return {
    query: gql`
        query ($limit: Int!, $offset: Int!, $filter: PartnerAppSliceFilter, $sort: PartnerAppSliceSorter) {
            partnerAppSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                totalCount
                items {
                    id
                    updatedAt
                    name
                    packageName
                    developer
                    developerEmail
                    developerWebsite
                    developerInfo
                    reviews
                    starRating
                    playstoreURL
                    installs
                    size
                    appUpdated
                    permissions
                    averageDAU
                    integrationStatus
                    SDKVersion
                    pricePer1KHad
                }
            }
        }
    `,
    variables,
    parseResponse: (response) => {
      const {
        data: {
          partnerAppSlice: { items, totalCount },
        },
      } = response
      return {
        data: items,
        total: totalCount,
      }
    },
  }
}

const update = ({ params: { data } }) => {
  const {
    id,
    name,
    packageName,
    region,
    playstoreURL,
    pricePer1KHad,
    SDKVersion,
    developer,
    developerEmail,
    developerWebsite,
    developerInfo,
  } = data
  return {
    query: gql`
        mutation ($id: Int!, $input: UpdatePartnerAppInput!) {
            updatePartnerApp(id: $id, input: $input) {
                id
            }
        }
    `,
    variables: {
      id, input: {
        name,
        packageName,
        region,
        playstoreURL,
        pricePer1KHad,
        SDKVersion,
        developer,
        developerEmail,
        developerWebsite,
        developerInfo,
      },
    },
    parseResponse: (response) => ({
      data: response.data.updatePartnerApp,
    }),
  }
}

const getManyReference = ({ target, id, pagination: {page, perPage}, sort }) => {
  const variables = {
    id: id,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: { createdAt: true, createdAtDesc: true, ...querySortHelper(sort)},
    filter: {}
  }
  switch (target) {
    case "partner":
      return getManyReferenceForPartner({ ...variables })
    default:
      throw new Error(`not implemented: GET_MANY_REFERENCE for ${target}`)
  }
}

const getManyReferenceForPartner = (variables) => {
  return {
    query: gql`
        query ($id: Int!, $limit: Int!, $offset: Int!, $filter: PartnerAppSliceFilter, $sort: PartnerAppSliceSorter) {
            partner(id: $id) {
                partnerAppSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
                    totalCount
                    items {
                        id
                        updatedAt
                        name
                        packageName
                        developer
                        developerEmail
                        developerWebsite
                        developerInfo
                        reviews
                        starRating
                        playstoreURL
                        installs
                        size
                        appUpdated
                        permissions
                        averageDAU
                        integrationStatus
                        SDKVersion
                        pricePer1KHad
                    }
                }
            }
        }
    `,
    variables: variables,
    parseResponse: ({ data: { partner: { partnerAppSlice: { items: data, totalCount: total } } } }) => ({
      data,
      total,
    }),
  }
}
