import React from 'react'
import gql from 'graphql-tag'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import get from "lodash/get"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "recompose"
import { Link } from "react-admin"
import { stringify } from 'query-string';

const QUERY = gql`
    query CountMany {
        active: customerSlice(limit: 0, filter: { subscriptionStatus: "active" }) {
            totalCount
        }
        onTrial: customerSlice(limit: 0, filter: { subscriptionStatus: "trial" }) {
            totalCount
        }
        free: customerSlice(limit: 0, filter: { subscriptionStatus: "free" }) {
            totalCount
        }
        suspended: customerSlice(limit: 0, filter: { subscriptionStatus: "suspended" }) {
            totalCount
        }
    }
`

const CardSubscription = ({ data: { loading, active, onTrial, free, suspended } }) => {
  if (loading) {
    return <CircularProgress/>
  }

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Active Subscriptions</TableCell>
          <TableCell align="right">
            <Link to={{
              pathname: `/customers`,
              search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({
                  "customerDetails": {"subscriptionStatus": "active"}
                }),
              }),
            }}>{get(active, 'totalCount')}</Link><br/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Trial Subscriptions</TableCell>
          <TableCell align="right">
            <Link to={{
              pathname: `/customers`,
              search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({
                  "customerDetails": {"subscriptionStatus": "trial"}
                }),
              }),
            }}>{get(onTrial, 'totalCount')}</Link><br/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Free Subscriptions</TableCell>
          <TableCell align="right">
            <Link to={{
              pathname: `/customers`,
              search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({
                  "customerDetails": {"subscriptionStatus": "free"}
                }),
              }),
            }}>{get(free, 'totalCount')}</Link><br/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Suspended Subscriptions</TableCell>
          <TableCell align="right">
            <Link to={{
              pathname: `/customers`,
              search: stringify({
                page: 1,
                perPage: 25,
                filter: JSON.stringify({
                  "customerDetails": {"subscriptionStatus": "suspended"}
                }),
              }),
            }}>{get(suspended, 'totalCount')}</Link><br/>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const enhance = compose(
  graphql(QUERY),
)

export default enhance(CardSubscription)
