import { PREPAID_FEATURES, ADD_FEATURES } from "./constants"

export const totalFeaturesPrice = (features) => {
  return features
    .filter(
      (f) => [...PREPAID_FEATURES, ...ADD_FEATURES].indexOf(f.feature) !== -1,
    )
    .reduce((acc, f) => {
      return acc + f.unitPrice * (f.quantity || 1)
    }, 0)
}
