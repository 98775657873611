import React, { useState } from "react"
import PostAddIcon from "@material-ui/icons/PostAdd"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { Grid, Typography } from "@material-ui/core"
import { required, SaveButton, SelectInput, NumberInput, showNotification, TextInput } from "react-admin"
import { Form } from "react-final-form"
import DialogActions from "@material-ui/core/DialogActions"
import { unitsToBytes } from "../../../../utils"
import { compose } from "recompose"
import { refreshView } from "ra-core"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import { connect } from "react-redux"

const AddBalanceDialog = ({ closeDialog, customerID, mutate, refreshView, showNotification }) => {
  const [loading, setLoading] = useState(false)
  const [units, setUnits] = useState("mb")

  const handleSubmit = ({ quantity, link, comment }) => {
    if (loading) {
      return
    }

    setLoading(true)
    mutate({
      variables: {
        input: {
          customerID,
          quantity: unitsToBytes(quantity, units),
          link,
          comment,
        }
      },
    }).catch((e) => {
      throw e
    }).finally(() => {
      closeDialog()
      setLoading(false)
      showNotification(`${quantity}${units.toUpperCase()} have been added to account balance, refreshing..`)
      refreshView()
    })
  }

  return (
    <Dialog
      onClose={closeDialog}
      maxWidth="xs"
      fullWidth
      open
      aria-label="Add bonus balance"
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Add bonus balance</DialogTitle>
            <DialogContent>
              <Grid container direction="column" alignItems="center">
                <SelectInput
                  source="link"
                  defaultValue="residential"
                  label="Link"
                  choices={[
                    { id: "residential", name: "Residential" },
                    { id: "datacenter", name: "Datacenter" },
                    { id: "mobile", name: "Mobile" },
                  ]}
                  validate={[required()]}
                  fullWidth
                />
                <NumberInput
                  source="quantity"
                  type="number"
                  label="Quantity"
                  defaultValue="100"
                  step="0.01"
                  validate={[required()]}
                  fullWidth
                />
                <SelectInput
                  source="units"
                  defaultValue="mb"
                  label="unit"
                  choices={[
                    { id: "mb", name: "MB" },
                    { id: "gb", name: "GB" },
                  ]}
                  validate={[required()]}
                  onChange={e => setUnits(e.target.value)}
                  fullWidth
                />
                <TextInput
                  label="Leave a comment to transaction"
                  source="comment"
                  initialValue="Manual balance refill"
                  fullWidth
                />
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
              <Button onClick={closeDialog}>
                <Typography>Cancel</Typography>
              </Button>
              <SaveButton
                saving={loading}
                handleSubmitWithRedirect={handleSubmit}
              />
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

const AddBalance = ({ record, loading, ...props }) => {
  const [isDialogOpen, setToggled] = useState(false)

  if (!record && !loading) {
    return null
  }

  const toggleDialog = () => setToggled(!isDialogOpen)

  return (
    <React.Fragment>
      <Tooltip title={"Add balance"}>
        <span>
          <Button
            color="primary"
            onClick={toggleDialog}
            disabled={loading}
          >
            {loading && <CircularProgress />}
            {!loading && <PostAddIcon />}
          </Button>
        </span>
      </Tooltip>
      {isDialogOpen && !loading && (
        <AddBalanceDialog closeDialog={toggleDialog} customerID={record.id} {...props} />
      )}
    </React.Fragment>
  )
}

const enhance = compose(
  connect(null, { refreshView, showNotification }),
  graphql(gql`
      mutation AddBonusBalance ($input: BalanceTransactionInput!) {
          addBonusTransaction(input: $input) {
              id
              createdAt
              link
              quantity
              balance
              comment
          }
      }
  `),
)

export default enhance(AddBalance)
