import BraintreeLinkField from "../../BraintreeLinkField"
import ContractStatus from "../components/ContractStatus"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import React from "react"
import BillingPeriodField from "../../BillingPeriodField"
import get from "lodash/get"

const ContractInfo = ({classes, braintreeCustomer, contract}) => {
  const billingPeriod = get(contract, "billingPeriods.items[0]")

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHeader} colSpan={2}>
            Contract Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {braintreeCustomer && <TableRow>
          <TableCell>
            Braintree Customer
          </TableCell>
          <TableCell className={classes.right}>
            <BraintreeLinkField record={braintreeCustomer}>{braintreeCustomer.id}</BraintreeLinkField>
          </TableCell>
        </TableRow>}
        {contract && <TableRow>
          <TableCell>
            Contract status
          </TableCell>
          <TableCell className={classes.right}>
            <ContractStatus className={classes.capitalize} contract={contract} />
          </TableCell>
        </TableRow>}
        <TableRow>
          <TableCell>Billing period</TableCell>
          <TableCell className={classes.right}>
            <BillingPeriodField billingPeriod={billingPeriod}/>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

const style = {
  tableHeader: {
    fontWeight: "bold",
    fontSize: 80 + "%",
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  capitalize: {
    textTransform: "capitalize",
  },
}

export default withStyles(style)(ContractInfo)