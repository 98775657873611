import {
  GET_LIST,
  GET_ONE,
  UPDATE,
  CREATE, GET_MANY,
} from "react-admin"
import gql from "graphql-tag"
import querySortHelper from "../querySortHelper"
import { create, updateAccount } from "../accountQueries"

const getOneQuery = gql`
  query Partner($id: Int!) {
    partner(id: $id) {
      id
      totalDAU
      totalApps
      createdAt
      paymentMethod
      paypalAccount {
        email
        holderName
        createdAt
      }
      account {
          id
          createdAt
          firstName
          lastName
          company
          displayName
          email
          phone
          country
          region
          postalCode
          locality
          streetAddress
          extendedAddress
          lastLogin
          isBlocked
      }
    }
  }
`

const getOne = ({ params: { id } }) => ({
  query: getOneQuery,
  variables: { id },
  parseResponse: ({ data: { partner } }) => ({ data: partner }),
})

const getListQuery = gql`
  query PartnerSlice($limit: Int!, $offset: Int!, $filter: PartnerSliceFilter, $sort: PartnerSliceSorter) {
    slice: partnerSlice(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      items {
        id
        createdAt
        totalDAU
        totalApps
          account {
              id
              role
              createdAt
              updatedAt
              lastLogin
              lastActivity
              email
              firstName
              lastName
              company
              displayName
              isBlocked
          }
      }
      totalCount
    }
  }
`

const getList = ({ params }) => {
  const { pagination: { page, perPage }, filter: { q: query, account }, sort } = params
  let filter = { query, ...(account || {}) }
  let variables = {
    filter,
    limit: perPage,
    offset: (page - 1) * perPage,
    sort: sort ? querySortHelper(sort) : undefined,
  }

  return {
    query: getListQuery,
    variables,
    parseResponse: (response) => ({
      data: response.data.slice.items,
      total: response.data.slice.totalCount,
    }),
  }
}

const getMany = ({ params: { ids } }) => {
  const variables = {
    ids: [...ids],
    limit: ids.length,
  }
  return {
    variables,
    query: gql`
      query ($ids: [Int!], $limit: Int!) {
        partnerSlice(limit: $limit, filter: { ids: $ids  }) {
          items {
            id
            createdAt
            account {
                id
                email
                displayName
            }
          }
        }
      }
    `,
    parseResponse: ({ data: { partnerSlice: { items } } }) => ({
      data: items,
    }),
  }
}

const update = ({ params: { data } }) => {
  const {
    account: {
      id,
      firstName,
      lastName,
      company,
      email,
      phone,
      country,
      postalCode,
      region,
      locality,
      streetAddress,
      extendedAddress,
    }
  } = data
  return updateAccount(id, {
    firstName,
    lastName,
    company,
    email,
    phone,
    country,
    postalCode,
    region,
    locality,
    streetAddress,
    extendedAddress,
  })
}

export default ({ queryType, params, resource }) => {
  switch (queryType) {
    case GET_MANY:
      return getMany({ params, resource })
    case GET_LIST:
      return getList({ params, resource })
    case GET_ONE:
      return getOne({ params, resource })
    case UPDATE:
      return update({ params, resource })
    case CREATE:
      return create({ params, resource })
    default:
      throw new Error(`not implemented: ${queryType} ${resource.name}`)
  }
}