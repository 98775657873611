import React from 'react'
import { List, Datagrid, Filter, SelectInput, SearchInput, Pagination, DateField, NumberField, TextField } from "react-admin"

import IDField from "../IDField"
import { ImpersonateButton } from "../../components"
import DisplayNameField from "../DisplayNameField"
import BooleanYesNoField from "../BooleanYesNoField"

export const PartnersFilter = props => (
  <Filter {...props} variant="standard">
    <SearchInput source="q" alwaysOn/>
    <SelectInput source="account.isBlocked" label="Is Blocked" choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ]}/>
  </Filter>
)

const PartnersPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

export default ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    filters={<PartnersFilter/>}
    pagination={<PartnersPagination/>}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <IDField sortable={true}/>
      <DisplayNameField source="account"/>
      <TextField source="account.email" label="Email" addLabel sortBy="email"/>
      <BooleanYesNoField source="account.isBlocked" label="Blocked" sortBy="isBlocked"/>
      <NumberField label="Number of Apps" source="totalApps" sortable={false}/>
      <NumberField label="Total DAU" source="totalDAU" sortable={false}/>
      <DateField label="Last Login" source="account.lastLogin" sortBy="lastLogin"/>
      <DateField label="Created" source="createdAt"/>
      <ImpersonateButton source="account" type="account"/>
    </Datagrid>
  </List>
)