import React from "react"
import { SelectInput } from "react-admin";

const UnitCell = ({source, record, defaultValue, active, ...rest}) => {
  return <SelectInput
    source={source}
    defaultValue={defaultValue}
    label={false}
    disabled={!active}
    choices={[
      { id: "gb", name: "GB" },
      { id: "mb", name: "MB" },
    ]}
    {...rest}
  />
}

export default UnitCell
