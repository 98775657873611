import React, { useState } from "react"
import { compose } from "recompose"
import { graphql } from "@apollo/client/react/hoc"
import gql from "graphql-tag"
import MarkAsFreeContractIcon from '@material-ui/icons/MoneyOff';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Typography } from "@material-ui/core"
import {connect} from "react-redux"
import { refreshView } from "ra-core"
import { showNotification } from 'react-admin'
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"

const MarkAsFreeContract = ({customer, mutate, refreshView, showNotification}) => {
  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen)
  }

  if (!customer || !customer.contract) {
    return null;
  }

  const {contract: {status}} = customer;
  if (status.toLowerCase() === "free" || status.toLowerCase() === "new") {
    return null
  }

  const toggleCancel = () => {
    setLoading(true)
    mutate({
      variables: {
        id: customer.id,
      },
    }).catch((e) => {
      throw e
    }).finally(() => {
      setDialogOpen(false)
      setLoading(false)
      showNotification("Contract has been marked as free. Refreshing..")
      setTimeout(() => {
        refreshView()
      }, 1000)
    })
  }

  return <>
    <Button
      color="primary"
      onClick={toggleDialog}
    >
      <Typography color="primary" style={{ marginRight: 0.5 + "rem" }}>Mark as Free</Typography>
      <MarkAsFreeContractIcon/>
    </Button>
    <Dialog open={dialogOpen} onClose={toggleDialog} disableBackdropClick={loading}>
      <DialogTitle>
        {loading ? <CircularProgress size={16}/> : "Are you sure you want to mark this contract as free?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Start new billing period for customer from today. Invoices and charges are not applied to this customer no matter of plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={toggleCancel} disabled={loading}>Confirm</Button>
        <Button onClick={toggleDialog} disabled={loading}>Cancel</Button>
      </DialogActions>
    </Dialog>
  </>
}

const enhance = compose(
  connect(null, {refreshView, showNotification}),
  graphql(gql`
      mutation MarkAsFreeContract ($id: Int!) {
          noBillingCustomerContract(id: $id){
              id
          }
      }
  `),
)

export default enhance(MarkAsFreeContract)
