import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { Link } from 'react-admin'

import CardSignups from './CardSignups'
import CardBandwidth from './CardBandwidth'
import CardSubscriptions from './CardSubscriptions'
import CardCustomerSearch from './CardCustomerSearch'
import ActiveCustomers from "../../resources/reports/activeCustomers"
import CardWorkersByCountrySeries from './CardWorkersByCountry'
import { withStyles } from "@material-ui/core";

const Dashboard = ({ classes }) => (
  <Grid container spacing={2}>
      <Grid item md={12} sm={12}>
          <CardCustomerSearch />
      </Grid>
    <Grid item md={6} sm={12}>
      <Card>
        <CardHeader title="Signup metrics" />
        <CardContent>
          <CardSignups />
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={6} sm={12}>
      <Card>
        <CardHeader title="Bandwidth metrics" />
        <CardContent>
          <CardBandwidth />
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={12} sm={12}>
      <Card>
        <CardContent>
          <ActiveCustomers title={(
              <Link to="/active-customers">
                <CardHeader className={classes.cardHeader} title="Most Active Accounts" />
              </Link>
          )} rowsPerPage={5} paginate={false} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={6} sm={12}>
      <Card>
        <CardHeader title="Subscription Metrics" />
        <CardContent>
          <CardSubscriptions/>
        </CardContent>
      </Card>
    </Grid>
    <Grid item md={6} sm={12}>
      <Card>
        <CardHeader title="Top countries with workers" />
        <CardContent>
          <CardWorkersByCountrySeries />
        </CardContent>
      </Card>
    </Grid>
  </Grid>
)

const styles = () => ({
  cardHeader: {
    padding: "0px",
  },
});

export default withStyles(styles)(Dashboard)
