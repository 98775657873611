import React from "react"
import { List, Datagrid, DateField, TextField } from "react-admin"

import Pagination from "../Pagination"
import IDField from "../IDField"
import BooleanYesNoField from "../BooleanYesNoField"
import FilesizeField from "../FilesizeField"


export default ({ ...props }) => {
  return <List
    bulkActionButtons={false}
    pagination={<Pagination/>}
    perPage={25}
    sort={{ field: "updatedAt", order: "DESC" }}
    {...props}
  >
    <Datagrid {...props} >
      <IDField source="id"/>
      <TextField source="name"/>
      <TextField source="password"/>
      <DateField source="updatedAt" showTime/>
      <BooleanYesNoField source="isActive" label="Is Active"/>
      <FilesizeField source="quotaDatacenter" label="Datacenter Quota"/>
      <FilesizeField source="quotaMobile" label="Mobile Quota"/>
      <FilesizeField source="quotaResidential" label="Residential Quota"/>
    </Datagrid>
  </List>
}