import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import React from "react"
import FeatureInfoRow from "./FeatureInfoRow"
import classnames from "classnames"
import { totalFeaturesPrice } from "../pricing"
import { get } from "lodash"
import { ALL_FEATURES } from "../constants"

const ServiceDetails = ({ classes, features, plan }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell className={classnames(classes.left, classes.noBorder)}>
          {get(plan, "displayName", "custom").toUpperCase()}
        </TableCell>
        <TableCell className={classes.left}>Price, $</TableCell>
        <TableCell className={classes.left}>Quantity</TableCell>
        <TableCell className={classes.left}>Unit</TableCell>
        <TableCell className={classes.left}>Amount</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {[...features]
        .sort((a, b) => ALL_FEATURES.indexOf(a.feature) - ALL_FEATURES.indexOf(b.feature))
        .map((f, idx) => <FeatureInfoRow key={idx} feature={f}/>)}
      <TableRow>
        <TableCell colSpan={4}>Total</TableCell>
        <TableCell>${totalFeaturesPrice(features)}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
)

const style = {
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  noBorder: {
    border: "none",
    borderBottom: "none !important",
  },
}

export default withStyles(style)(ServiceDetails)
