import React from "react"
import { TextField, Datagrid, DateField, ReferenceManyField } from "react-admin"
import DownloadInvoiceField from "../../DownloadInvoiceField"
import Pagination from "../../Pagination"
import CurrencyField from "../../CurrencyField"
import BooleanYesNoField from "../../BooleanYesNoField"
import TextUrlField from "../../TextUrlField"

const Table = record => (
  <Datagrid style={{textAlign: "left"}}>
    <TextUrlField label="#" source="previewURL" displaySource="ref"/>
    <TextField style={{textTransform: "capitalize"}} source="type"/>
    <DateField source="createdAt" label="Date created"/>
    <DateField label="Billing period start date" source="billingPeriod.startDate"/>
    <DateField label="Billing period end date" source="billingPeriod.endDate"/>
    <CurrencyField source="totalAmount"/>
    <BooleanYesNoField source="isPaid" label="Paid"/>
    <DownloadInvoiceField label="Download"/>
  </Datagrid>
)

export default ({ record, recordCallback, ...props }) => {
  if (!!recordCallback) {
    recordCallback(record)
  }
  return <ReferenceManyField
    addLabel={false}
    reference="invoices"
    target="customer"
    pagination={<Pagination/>}
    perPage={25}
    record={record}
    {...props}
  >
    {Table(record)}
  </ReferenceManyField>
}
