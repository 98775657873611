import { apolloClientAuth } from "./apolloClient"
import gql from "graphql-tag"
import moment from "moment"

const LOGIN = gql`
    mutation Login($input: LoginPasswordInput!) {
        login: loginPassword(input: $input) {
            me {
                accountID,
                firstName
                lastName
                email
                role
            }
            auth {
                token
                expiresAt
                notBefore
            }
            refresh {
                token
                expiresAt
                notBefore
            }
        }
    }
`


export default {
  login: ({ username, password }) => {
    return apolloClientAuth().mutate({
      mutation: LOGIN,
      variables: {
        input: {
          email: username,
          password: password,
        },
      },
    }).then(response => {
      const {
        data: {
          login: { me, auth, refresh },
        },
      } = response
      if (me.role !== "staff") {
        throw new Error("Staff only")
      }
      localStorage.setItem("me", JSON.stringify(me))
      localStorage.setItem("auth-token", JSON.stringify(auth))
      localStorage.setItem("refresh-token", JSON.stringify(refresh))
      return Promise.resolve()
    }).catch(error => {
      return Promise.reject(error)
    })
  },
  logout: () => {
    localStorage.removeItem("me")
    localStorage.removeItem("auth-token")
    localStorage.removeItem("refresh-token")
    return Promise.resolve()
  },
  checkError: error => {
    const { status } = error
    if (status === 401 || status === 403) {
      localStorage.removeItem("me")
      localStorage.removeItem("auth-token")
      localStorage.removeItem("refresh-token")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  checkAuth: () => {
    const authToken = JSON.parse(localStorage.getItem("auth-token"))
    if (!authToken) {
      return Promise.reject()
    }
    const expiresAt = moment(authToken.expiresAt)
    if (expiresAt.isBefore(moment())) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: () => {
    return Promise.resolve()
  }
};