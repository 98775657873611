import React from "react"
import { Tooltip, Button, Grid } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"

export const NoteActionButtonsField = ({ onEdit, onDelete, record }) => {
    const editHandler = () => onEdit(record);
    const deleteHandler = () => onDelete(record);

    return (
        <Grid container wrap="nowrap" justifyContent="center">
          <Tooltip title="Edit">
            <span>
              <Button
                  color="primary"
                  onClick={editHandler}
              >
                <EditIcon />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <Button
                  color="primary"
                  onClick={deleteHandler}
              >
                <DeleteIcon />
              </Button>
            </span>
          </Tooltip>
        </Grid>
    )
}

export default NoteActionButtonsField
