import React from "react"
import { pure } from "recompose"
import { get } from "lodash"
import IDFiled from "./IDField"

const Field = ({ record, source, ...props }) => {
  const refunds = get(record, source)
  if (!refunds) {
    return null
  }
  return <IDFiled record={refunds} source="id" {...props}/>
}

const PureField = pure(Field)

PureField.defaultProps = {
  label: "Refunds",
  source: "refunds",
  sortable: false,
}

export default PureField
