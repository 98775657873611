import React from "react"
import { Layout, Notification, Sidebar } from "react-admin"
import Menu from "./Menu"
import AppBar from "./AppBar"

export default ({ children, ...props }) => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
    sidebar={Sidebar}
    notification={Notification}
  >
    {children}
  </Layout>
)
