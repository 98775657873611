import React from "react"
import {
  Datagrid,
  DateField,
  ReferenceManyField, TextField,
} from "react-admin"

import IDField from "../../IDField"
import BooleanYesNoField from "../../BooleanYesNoField"
import FilesizeField from "../../FilesizeField"

export default ({...props}) => {
  const { record, recordCallback } = props
  if (!!recordCallback) {
    recordCallback(record)
  }
  return <ReferenceManyField
    reference="zones"
    target="customer"
    {...props}
  >
    <Datagrid>
      <IDField source="id"/>
      <TextField source="name"/>
      <TextField source="password"/>
      <DateField source="updatedAt" showTime/>
      <BooleanYesNoField source="isActive" label="Is Active"/>
      <FilesizeField source="quotaDatacenter" label="Datacenter Quota"/>
      <FilesizeField source="quotaMobile" label="Mobile Quota"/>
      <FilesizeField source="quotaResidential" label="Residential Quota"/>
    </Datagrid>
  </ReferenceManyField>
}