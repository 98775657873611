import React from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import { crudUpdateMany } from "react-admin"
import { Button } from "@material-ui/core"
import RejectIcon from "@material-ui/icons/Clear"
import ApproveIcon from "@material-ui/icons/Check"

const enhance = compose(connect(null, { crudUpdateMany }))

const Action = enhance(
  ({ resource, basePath, selectedIds, crudUpdateMany, allow, children }) => (
    <Button
      variant="text"
      size="small"
      onClick={() => {
        crudUpdateMany(
          resource,
          selectedIds,
          { disposition: "acknowledged", allow: !!allow },
          basePath,
        )
      }}
    >
      {children}
    </Button>
  ),
)

export const ApproveButton = ({ ...props }) => {
  return (
    <Action disposition="approved" allow {...props}>
      <ApproveIcon /> Allow access
    </Action>
  )
}

export const RejectButton = ({ ...props }) => {
  return (
    <Action disposition="rejected" {...props}>
      <RejectIcon /> Deny access
    </Action>
  )
}

export default (props) => (
  <>
    <ApproveButton {...props} />
    <RejectButton {...props} />
  </>
)
