import React from "react"
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  EditButton,
} from "react-admin"
import Pagination from "../Pagination"
import IDField from "../IDField"
import {
  PREMIUM_DOMAIN_CATEGORY,
  PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME,
} from "./constants"
import PremiumDomainCategoryField from "./components/PremiumDomainCategoryField"
import IsPremiumDomainActiveField from "./components/IsPremiumDomainActiveField"

const F = ({ ...props }) => (
  <Filter {...props} variant="standard">
    <TextInput alwaysOn resettable source="query" label="Search" />
    <SelectInput
      alwaysOn
      allowEmpty
      resettable
      source="category"
      label="Category"
      choices={Object.values(PREMIUM_DOMAIN_CATEGORY).map((category) => ({
        id: category,
        name: PREMIUM_DOMAIN_CATEGORY_DISPLAY_NAME[category],
      }))}
    />
  </Filter>
)

export default (props) => {
  return (
    <List
      title="Premium Domains"
      pagination={<Pagination />}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      filters={<F />}
      {...props}
    >
      <Datagrid>
        <IDField source="id" sortable />
        <TextField source="name" label="Domain" />
        <PremiumDomainCategoryField
          source="category"
          label="Category"
          align="center"
        />
        <IsPremiumDomainActiveField source="isActive" label="isActive" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
