import React from "react"
import { BooleanInput, TextInput } from "react-admin"
import { get } from "lodash"
import { getFeatureDescription, getFeatureFromDescription } from "./utils"
import FormControl from "@material-ui/core/FormControl"
import { FEATURE_HINTS } from "../../constants"

const FeatureToggle = ({ source, ...record }) => (
  <FormControl component="fieldset">
    <BooleanInput
      defaultValue={get(record, "active")}
      source={`${source}.active`}
      label={getFeatureDescription(get(record, "feature"))}
      helperText={get(FEATURE_HINTS, get(record, "feature"))}
    />
    <TextInput
      defaultValue={get(record, "feature")}
      source={`${source}.feature`}
      label=""
      format={getFeatureDescription}
      parse={getFeatureFromDescription}
      variant="standard"
      margin="none"
      size="small"
      style={{display: "none"}}
    />
  </FormControl>
)

export default FeatureToggle
