import React from "react"

const ChangeUserPasswordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="MuiSvgIcon-root"
    viewBox="-1 -1 26 26"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M23.621 9.012c.247.959.379 1.964.379 3 0 6.623-5.377 11.988-12 11.988s-12-5.365-12-11.988c0-6.623 5.377-12 12-12 2.581 0 4.969.822 6.927 2.211l1.718-2.223 1.935 6.012h-6.58l1.703-2.204c-1.62-1.128-3.582-1.796-5.703-1.796-5.52 0-10 4.481-10 10 0 5.52 4.48 10 10 10 5.519 0 10-4.48 10-10 0-1.045-.161-2.053-.458-3h2.079zm-7.621 7.988h-8v-6h1v-2c0-1.656 1.344-3 3-3s3 1.344 3 3v2h1v6zm-5-8v2h2v-2c0-.552-.448-1-1-1s-1 .448-1 1z" />
  </svg>
)

export default ChangeUserPasswordIcon
