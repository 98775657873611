import React, { useState } from "react"
import gql from "graphql-tag"
import { connect } from "react-redux"
import { refreshView } from "ra-core"
import { graphql } from "@apollo/client/react/hoc"
import { compose } from "recompose"

import { CircularProgress, Button, Tooltip } from "@material-ui/core"
import Icon from "@material-ui/icons/Refresh"

export const QUERY = gql`
    mutation RefreshAccount ($id: Int!) {
        refreshAccount(id: $id) {
            id
        }
    }
`

const Refresh = ({ customer, mutate, refreshView }) => {
  const [loading, setLoading] = useState(false)
  return <Tooltip title={loading ? "Account is being refreshed" : "Refresh account"}>
    <Button color="primary" onClick={() => {
      setLoading(true)
      mutate({ variables: { id: customer.account.id } }).finally(() => {
        setLoading(false)
        refreshView()
      })
    }}>
      {
        loading
          ? <CircularProgress variant="indeterminate" size={16}/>
          : <Icon/>
      }
    </Button>
  </Tooltip>
}

const enhance = compose(
  graphql(QUERY),
  connect(null, { refreshView }),
)

export default enhance(Refresh)
